import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getQueryKey } from "@trpc/react-query";
import { useAtom } from "jotai";

import type { WorkerInstallType, WorkerWithInstances } from "@kuzco/models";
import {
  ConsoleLogManager,
  WorkerInstallTypeSchema,
  workerStatus,
} from "@kuzco/models";

import type { RouterOutput } from "~/lib/trpc";
import { api, trpc } from "~/lib/trpc";
import { WorkerState } from "../jotai/worker.jotai";
import { useAppConfig } from "./useAppConfig.hook";
import useIsAuthenticated from "./useIsAuthenticated.hook";

const logger = new ConsoleLogManager({
  name: "useWorker.hook",
});

export default function useWorker(workerId?: string) {
  const { isTauri, runtimeType, workerManager } = useAppConfig();
  const { isAuthenticated } = useIsAuthenticated();
  const queryClient = useQueryClient();

  const [workerListQuery] = api.worker.list.useSuspenseQuery(undefined, {
    refetchInterval: isAuthenticated ? 1000 * 5 : false,
    /**
     * todo: figure out how to better handle
     * suspense in react query
     * https://github.com/TanStack/query/discussions/6361
     * stupid hack
     */
    staleTime: isAuthenticated ? 0 : Infinity,
  });

  // Cannot read properties of undefined (reading 'workers') (sentry)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function hasWorkers(query: any): query is { workers: WorkerWithInstances[] } {
    return query && Array.isArray(query.workers);
  }

  // Cannot read properties of undefined (reading 'workers') (sentry)
  const workerList = hasWorkers(workerListQuery)
    ? workerListQuery.workers.filter((w) => !w.isArchived && !w.isDisabled)
    : [];

  const setWorkerList = React.useCallback(
    (fn: (current: WorkerWithInstances[]) => WorkerWithInstances[]) => {
      const queryKey = getQueryKey(api.worker.list, undefined, "query");
      const current =
        queryClient.getQueryData<RouterOutput["worker"]["list"]>(queryKey);
      queryClient.setQueryData(queryKey, {
        ...current,
        workers: fn(current?.workers ?? []),
      });
    },
    [queryClient],
  );

  const pageSize = 50;
  const totalPages = Math.ceil(workerList.length / pageSize);
  const [currentPage, setCurrentPage] = React.useState(1);

  const paginatedWorkerList = workerList.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize,
  );

  const refreshWorkerList = useMutation(
    async () => {
      return await trpc.worker.list.query();
    },
    {
      onSuccess: ({ workers }) => {
        if (!workers) return;

        setWorkerList(() => workers);
      },
    },
  );

  const worker = workerId
    ? workerList.find((w) => w._id === workerId) ?? null
    : null;

  const startWorker = useMutation(
    async () => {
      if (!isTauri) {
        throw Error("startWorker cannot be called outside of tauri");
      }

      if (!workerManager) {
        logger.error("startWorker called but workerManager is not set");
        return;
      }

      if (!worker) {
        logger.error("startWorker called but worker is not set");
        return;
      }

      const workerClient = await workerManager.runFromWorkerParams(worker);

      if (!workerClient) return null;

      await new Promise((resolve) => setTimeout(resolve, 10000));

      await trpc.worker.setAutoStart.mutate({
        workerId: worker._id,
        autoStart: true,
      });

      return workerClient;
    },
    {
      onSuccess: (workerClient) => {
        if (!workerClient || !worker) return;

        refreshWorkerList.mutate();
      },
    },
  );

  const stopWorker = useMutation(
    async () => {
      if (!isTauri) {
        throw Error("stopWorker cannot be called outside of tauri");
      }

      if (!workerManager) {
        logger.error("startWorker called but workerManager is not set");
        return;
      }

      if (!worker) {
        logger.error("startWorker called but worker is not set");
        return;
      }

      await trpc.worker.setAutoStart.mutate({
        workerId: worker._id,
        autoStart: false,
      });

      return await workerManager.stop();
    },
    {
      onSuccess: (stopResponse) => {
        if (!stopResponse) return;

        const { workerId, instanceId } = stopResponse;

        setWorkerList((current) => {
          return current.map((c) => {
            if (c._id === workerId) {
              return {
                ...c,
                instances: c.instances.filter((i) => i._id !== instanceId),
              };
            }
            return c;
          });
        });
      },
    },
  );

  const createWorker = api.worker.create.useMutation({
    onSuccess: ({ worker, error }) => {
      if (!worker) {
        throw new Error(error?.message ?? "Failed to create worker");
      }

      setWorkerList((current) => [worker, ...current]);
    },
  });

  const runtimeTypeValue = runtimeType.data ?? "web";

  const workerType: WorkerInstallType = (() => {
    if (!isTauri) return WorkerInstallTypeSchema.Enum.Docker;

    switch (runtimeTypeValue) {
      case "windows":
        return WorkerInstallTypeSchema.Enum["Desktop Windows"];
      case "mac":
        return WorkerInstallTypeSchema.Enum["Desktop App"];
      default:
        return WorkerInstallTypeSchema.Enum.Docker;
    }
  })();

  const [createWorkerParams, setCreateWorkerParams] = useAtom(
    WorkerState.createWorkerParams(workerType),
  );

  const updateWorker = api.worker.update.useMutation({
    onSuccess: ({ worker }) => {
      if (!worker) return;

      setWorkerList((current) => {
        return current.map((c) => {
          if (c._id === worker._id) {
            return worker;
          }
          return c;
        });
      });
    },
  });

  const [updateWorkerParams, setUpdateWorkerParams] = useAtom(
    WorkerState.updateWorkerParams(workerId ?? ""),
  );

  const archiveWorker = api.worker.archive.useMutation({
    onSuccess: ({ worker }) => {
      if (!worker) return;

      setWorkerList((current) => {
        return current.map((c) => {
          if (c._id === worker._id) {
            return worker;
          }
          return c;
        });
      });
    },
  });

  return {
    workerList,
    refreshWorkerList,
    worker,
    startWorker,
    stopWorker,
    createWorker,
    createWorkerParams,
    setCreateWorkerParams,
    updateWorker,
    updateWorkerParams,
    setUpdateWorkerParams,
    paginatedWorkerList,
    totalPages,
    currentPage,
    setCurrentPage,
    archiveWorker,
    workerStatus: worker ? workerStatus(worker) : null,
  };
}
