import type { BoxProps } from "@chakra-ui/react";
import React from "react";
import { Flex, Text } from "@chakra-ui/react";

import Card from "~/ui/elements/Card";

type DisabledChartProps = {
  title?: string;
  isDisabeld?: boolean;
} & BoxProps;

const DisabledChart: React.FC<DisabledChartProps> = ({ title, ...rest }) => {
  return (
    <Card minHeight="386px" {...rest}>
      <Text variant="18-semi" color="gray.400">
        {title ?? "Average Tok/s"}
      </Text>
      <Flex alignItems="center" justifyContent="center">
        <Text>High Volume. Analytics are temporarily disabled.</Text>
      </Flex>
    </Card>
  );
};

export default DisabledChart;
