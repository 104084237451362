import {
  Button,
  Circle,
  Flex,
  Heading,
  Text,
  theme,
  VStack,
} from "@chakra-ui/react";
import { FiAlertCircle, FiRotateCw } from "react-icons/fi";

export default function ErrorPageContent() {
  return (
    <Flex
      maxWidth="600px"
      flexDir="column"
      alignItems="center"
      gridGap={"32px"}
    >
      <VStack>
        <Circle backgroundColor="gray.800" size="50px">
          <FiAlertCircle size="22px" color={theme.colors.red[400]} />
        </Circle>
        <Heading size="md">Something went wrong</Heading>
        <Text textAlign={"center"}>
          If this issue persists, please contact support at{" "}
          <a href="mailto:support@kuzco.xyz" target="_blank">
            support@kuzco.xyz
          </a>
        </Text>
      </VStack>
      <VStack>
        <Button
          variant="primary"
          onClick={() => window.location.reload()}
          size="lg"
          width="200px"
        >
          <FiRotateCw size="16px" color={theme.colors.white} />
          <Text variant="14-med" color="white" marginLeft="8px">
            Refresh
          </Text>
        </Button>
        <Text maxW="30ch">Refreshing may resolve the issue.</Text>
      </VStack>
    </Flex>
  );
}
