import { getEnvStringAllowEmptyFn, getEnvStringFn } from "@kuzco/utils";

const env = import.meta.env;

const getEnvString = getEnvStringFn(env);
const getEnvStringAllowEmpty = getEnvStringAllowEmptyFn(env);

export const NODE_ENV = getEnvString("VITE_NODE_ENV");
export const API_URL = getEnvString("VITE_API_URL");
export const NATS_URL = getEnvString("VITE_NATS_URL");
export const NATS_LOGS_SERVER_URL = getEnvStringAllowEmpty(
  "VITE_NATS_LOGS_SERVER_URL",
);
export const VITE_APP_BASE_URL =
  "https://" +
  getEnvString("VITE_APP_BASE_URL", "kuzco.xyz").replace("https://", "");
// export const VITE_APP_BASE_URL = "https://kuzco.xyz"; // Hardcoded for prod fix
export const POSTHOG_API_KEY = getEnvString("VITE_POSTHOG_API_KEY");
export const CLOUDFLARE_TURNSTILE_SITE_KEY = getEnvString(
  "VITE_CLOUDFLARE_TURNSTILE_SITE_KEY",
);
export const MAPBOX_TOKEN = getEnvString("VITE_MAPBOX_TOKEN");

export const DISCORD_REDIRECT_URI = getEnvString("VITE_DISCORD_REDIRECT_URI");

export const DISCORD_INVITE_LINK = "https://discord.gg/cErZtmMGpf";
export const TWITTER_LINK = "https://twitter.com/kuzco_xyz";
export const TELEGRAM_LINK = "https://t.me/samhogan";
export const GITHUB_LINK = "https://github.com/context-labs";
export const MEETING_LINK = "https://cal.com/samhogan/15min";
export const DOCUMENTATION_LINK = "https://docs.kuzco.xyz";
export const DOWNLOAD_LINK = "https://kuzco.xyz/download";
export const CAREERS_LINK = "https://apply.workable.com/kuzco/";

export const LEARN_MORE_LINK = TWITTER_LINK;

export const MAX_USERNAME_LENGTH = 20;

// TODO: when releasing systemd service, update this to the new version
// export const CLI_INSTALL_SCRIPT = `sh -c "$(curl -sSL https://kuzco.cool/setup-kuzco.sh)"`;
export const CLI_INTERACTIVE_SETUP_SCRIPT = `sh -c "$(curl -sSL [KUZCO_BASE_URL]/setup-kuzco.sh)"`;
export const CLI_INSTALL_SCRIPT = `curl -fsSL [KUZCO_BASE_URL]/install.sh | sh`;
export const CLI_INIT_COMMAND = `kuzco init`;
export const CLI_LOGIN_COMMAND = `kuzco login`;

export const DISCORD_CLIENT_ID = "1272604848109654057";

export const CONSOLE_VERSION = "0.0.5";

export const CONSOLE_VERSION_HASH = "dev";

export const CONSOLE_FULL_VERSION = `${CONSOLE_VERSION}-${CONSOLE_VERSION_HASH}`;
