import { useParams } from "react-router-dom";

import EditWorker from "~/ui/components/worker/create/EditWorker";

type WorkerSettingsPageProps = unknown;

const WorkerSettingsPage: React.FC<WorkerSettingsPageProps> = () => {
  const { workerId } = useParams();

  return <EditWorker workerId={workerId ?? ""} />;
};

export default WorkerSettingsPage;
