import { Box, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import CreateWorker from "~/ui/components/worker/create/CreateWorker";
import { Content, Header, Title } from "~/ui/layouts/Page.layout";

const CreateWorkerPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Header>
        <Title>Create Worker</Title>
        <Button
          variant="secondary"
          size="sm"
          onClick={() => {
            navigate("/dashboard/workers");
          }}
        >
          Cancel
        </Button>
      </Header>
      <Content>
        <Box display="flex" paddingTop="24px" justifyContent="center">
          <Box width="600px">
            <CreateWorker />
          </Box>
        </Box>
      </Content>
    </>
  );
};

export default CreateWorkerPage;
