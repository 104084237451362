import React from "react";
import { useToast } from "@chakra-ui/react";
import { useAtom } from "jotai";
import posthog from "posthog-js";

import type { AuthenticationResponse } from "@kuzco/models";
import { isSuperUser, utils } from "@kuzco/utils";

import PosthogEvent from "~/constants/PosthogEvent.constants";
import AuthUtil from "~/lib/auth";
import useTracking from "~/ui/hooks/useTracking.hook";
import { api, trpc } from "../../lib/trpc";
import UserState from "../jotai/user.jotai";
import useIsAuthenticated from "./useIsAuthenticated.hook";

export default function useUser() {
  const toast = useToast();
  const [user, setUser] = useAtom(UserState.user);
  const { isAuthenticated } = useIsAuthenticated();
  const isSuper = isSuperUser(user?.email);
  const { track } = useTracking();

  /**
   * Register
   */
  const registerUser = api.user.register.useMutation();

  const [registerUserParams, setRegisterUserParams] = useAtom(
    UserState.registerUserParams,
  );

  /**
   * Login
   */
  const loginUser = api.user.login.useMutation();

  const [loginUserParams, setLoginUserParams] = useAtom(
    UserState.loginUserParams,
  );

  const refreshUser = React.useCallback(async () => {
    try {
      const { user } = await trpc.user.get.query();
      if (user != null) {
        void setUser(user);
      }
      return user;
    } catch (err: unknown) {
      console.error(err);
      return null;
    }
  }, [setUser]);

  /**
   * Authenticate
   */
  const authenticate = React.useCallback(
    ({ user, token }: Pick<AuthenticationResponse, "user" | "token">) => {
      if (!user || !token) {
        toast({
          title: "Authentication Error",
          description:
            "There was an error signing you in. Please notify support.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      /**
       * Set the auth token in both the app state
       * and cookies
       */
      AuthUtil.setToken(token);
      void setUser(user);

      /** PostHog */
      const properties = utils.getPosthogUserProperties(user);
      posthog.identify(user._id, properties);
    },
    [setUser, toast],
  );

  /**
   * Logout
   */
  const logout = React.useCallback(() => {
    track({
      event: PosthogEvent.AuthLogout,
    });
    posthog.reset();
    AuthUtil.clearToken();
    window.location.href = "/";
  }, [track]);

  /**
   * Refresh
   */
  const refresh = React.useCallback(() => {
    window.location.reload();
  }, []);

  /**
   * Super Impersonate
   */
  const superImpersonate = api.user.register.useMutation({
    onSuccess: (data) => {
      authenticate(data);
      window.location.href = "/dashboard/reports";
    },
  });

  return {
    user,
    isAuthenticated,
    isSuper,
    register: registerUser,
    registerUserParams,
    setRegisterUserParams,
    login: loginUser,
    loginUserParams,
    setLoginUserParams,
    authenticate,
    logout,
    refresh,
    superImpersonate,
    refreshUser,
  };
}
