import { Suspense } from "react";
import { Box, Grid, GridItem } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { api } from "~/lib/trpc";
import CardsChart from "~/ui/components/metrics/Cards.chart";
import GenerationCountChart from "~/ui/components/metrics/GenerationCount.chart";
import TokenEarningsChart from "~/ui/components/metrics/TokenEarnings.chart";
import WorkerInfo from "~/ui/components/worker/WorkerInfo";
import useIsMobile from "~/ui/hooks/useIsMobile.hook";
import useWorker from "~/ui/hooks/useWorker.hook";
import WorkersDetailContentSuspense from "../../../ui/suspense/WorkersDetailContent.suspense";

const WorkerDetailsPageComponent: React.FC = () => {
  const isMobile = useIsMobile();
  const { workerId = "" } = useParams();
  const { worker } = useWorker(workerId);
  const [data] = api.metrics.worker.useSuspenseQuery({
    workerId,
  });

  return (
    <>
      <Box height="24px" />
      {/* <Flex justifyContent="center">
          <RegisterWorker worker={worker} />
        </Flex> */}
      {data == null ? null : (
        <>
          {worker != null && (
            <WorkerInfo worker={worker} columns={isMobile ? 1 : 6} cards />
          )}
          <Box height="24px" />
          <CardsChart
            values={[
              {
                title: "24H Inference Requests",
                value: data.generationsLast24Hours,
                subtitle: "Updated every 5 minutes",
              },
              {
                title: "24H Network Payout",
                value: data.tokensLast24Hours,
                subtitle: "Updated every 5 minutes",
                isKuzcoValue: true,
              },
              {
                title: "All Time Network Payout",
                value: data.tokensAllTime,
                subtitle: "Updated every 5 minutes",
                isKuzcoValue: true,
              },
            ]}
          />
          <Box height="24px" />
          <TokenEarningsChart
            title="Earning Statistics"
            metric={data.tokensLast30DaysBarChart}
          />
          <Box height="24px" />
          <Grid templateColumns="repeat(1, 1fr)" gap={6}>
            {/* <GridItem>
              <AverageTPSChart metric={data.averageTPS} />
            </GridItem> */}
            <GridItem>
              <GenerationCountChart
                // @ts-expect-error - fix later.
                metric={data.generationsLast24HoursLineChart}
              />
            </GridItem>
          </Grid>
        </>
      )}
    </>
  );
};

export default function WorkerDetailsPage() {
  return (
    <Suspense
      fallback={
        <div>
          <Box height="20px" />
          <WorkersDetailContentSuspense />
        </div>
      }
    >
      <WorkerDetailsPageComponent />
    </Suspense>
  );
}
