import { useAtom, useSetAtom } from "jotai";

import { api } from "~/lib/trpc";
import { KeyState } from "../jotai/key.jotai";

export default function useKey() {
  const setKeyList = useSetAtom(KeyState.keyList);
  const createKey = api.key.create.useMutation({
    onSuccess: async ({ key }) => {
      if (!key) {
        return;
      }

      await setKeyList((list) => [key, ...list]);
    },
  });

  const [createKeyParams, setCreateKeyParams] = useAtom(
    KeyState.createKeyParams,
  );
  const archiveKey = api.key.archive.useMutation({
    onSuccess: async ({ key }) => {
      if (!key) {
        return;
      }

      await setKeyList((list) => {
        const index = list.findIndex((s) => s._id === key._id);
        if (index === -1) {
          return list;
        }

        const newList = list.filter((s) => s._id !== key._id);
        return newList;
      });
    },
  });

  return {
    createKey,
    createKeyParams,
    setCreateKeyParams,
    archiveKey,
  };
}
