import type { Duration } from "luxon";

import type { Generation } from "@kuzco/models";

import { time } from "./time";

type GenerationTimestamp = Date | null;

function getUnixEpochTime(timestamp: GenerationTimestamp): number | null {
  return timestamp == null ? null : timestamp.getTime();
}

// We're using dispatchedAt instead of createdAt:
// Linear Ref: https://linear.app/kuzco/issue/KUZ-191/fix-the-toks-calculation-used-on-the-generation-tables-to-use-start
function getGenerationDuration(generation: Generation): Duration | null {
  const { finishedAt, dispatchedAt } = generation;
  const finishedAtUnix = getUnixEpochTime(finishedAt);
  const dispatchedAtUnix = getUnixEpochTime(dispatchedAt);

  if (finishedAtUnix == null || dispatchedAtUnix == null) {
    return null;
  }

  return time.Duration.fromMillis(finishedAtUnix - dispatchedAtUnix);
}

// We're using dispatchedAt instead of createdAt:
// Linear Ref: https://linear.app/kuzco/issue/KUZ-191/fix-the-toks-calculation-used-on-the-generation-tables-to-use-start
function getGenerationTokensPerSecond(generation: Generation): number | null {
  const { state, dispatchedAt, usage, finishedAt } = generation;
  const isFinished = ["Success", "Failed"].includes(state);
  const finishedAtUnix = getUnixEpochTime(finishedAt) ?? Date.now();
  const dispatchedAtUnix = getUnixEpochTime(dispatchedAt);
  if (!isFinished || usage.total_tokens == null || dispatchedAtUnix == null) {
    return null;
  }

  const tokensPerSecond =
    (usage.total_tokens / (finishedAtUnix - dispatchedAtUnix)) * 1000;
  return tokensPerSecond;
}

export const toks = {
  getGenerationDuration,
  getGenerationTokensPerSecond,
};
