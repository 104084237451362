import type { Theme } from "@chakra-ui/react";
import { Box, Flex, Link, Text, useTheme } from "@chakra-ui/react";
import { FiAlertOctagon, FiAlertTriangle, FiTool } from "react-icons/fi";

import type { SystemMode, SystemStatus } from "@kuzco/models";

import { TWITTER_LINK } from "~/lib/const";

const status: Record<SystemMode, (theme: Theme) => React.ReactNode> = {
  Operational: (_theme: Theme) => (
    <Flex alignItems="center" justifyContent="center">
      <Box
        backgroundColor="green.300"
        height="8px"
        width="8px"
        borderRadius="50%"
      />
      <Box width="8px" />
      <Text>All Systems Operational</Text>
      <Box display={{ base: "block", md: "none" }}>
        All Systems Operational.
      </Box>
    </Flex>
  ),
  Degraded: (theme: Theme) => (
    <Flex alignItems="center" justifyContent="center">
      <FiAlertTriangle color={theme.colors.yellow[500]} fontSize="20px" />
      <Box width="8px" />
      <Box display={{ base: "none", md: "block" }}>
        High Volume.&nbsp;
        <Link
          href={TWITTER_LINK}
          style={{
            textDecoration: "underline",
            display: "inline",
          }}
          target="_blank"
        >
          Follow for updates.
        </Link>
      </Box>
      <Box display={{ base: "block", md: "none" }}>
        High Volume. Follow for updates.
      </Box>
    </Flex>
  ),
  Outage: (theme: Theme) => (
    <Flex alignItems="center" justifyContent="center">
      <FiAlertOctagon color={theme.colors.red[500]} fontSize="20px" />
      <Box width="8px" />
      <Box display={{ base: "none", md: "block" }}>
        System Outage.&nbsp;
        <Link
          href={TWITTER_LINK}
          style={{
            textDecoration: "underline",
            display: "inline",
          }}
          target="_blank"
        >
          Follow for updates.
        </Link>
      </Box>
      <Box display={{ base: "block", md: "none" }}>
        System Outage. Follow for updates.
      </Box>
    </Flex>
  ),
  Maintenance: (theme: Theme) => (
    <Flex alignItems="center" justifyContent="center">
      <FiTool color={theme.colors.orange[500]} fontSize="20px" />
      <Box width="8px" />
      <Box display={{ base: "none", md: "block" }}>
        Paused for maintenance.&nbsp;
        <Link
          href={TWITTER_LINK}
          style={{
            textDecoration: "underline",
            display: "inline",
          }}
          target="_blank"
        >
          Follow for updates.
        </Link>
      </Box>
      <Box display={{ base: "block", md: "none" }}>
        Paused for maintenance. Follow for updates.
      </Box>
    </Flex>
  ),
};

type SystemStatusProps = {
  systemStatus?: SystemStatus | null;
};

const SystemStatusView: React.FC<SystemStatusProps> = ({ systemStatus }) => {
  const theme = useTheme();
  return (
    status[systemStatus?.mode ?? "Operational"](theme as unknown as Theme) ??
    null
  );
};

export default SystemStatusView;
