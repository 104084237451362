import React from "react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { Box, Flex, Text } from "@chakra-ui/react";

import type {
  CreateWorkerParams,
  UpdateWorkerParams,
  WorkerInstallType,
} from "@kuzco/models";

import type useWorker from "~/ui/hooks/useWorker.hook";
import Card from "~/ui/elements/Card";
import Label from "~/ui/elements/Label";
import { useAppConfig } from "~/ui/hooks/useAppConfig.hook";

type InstallTypeInfo = {
  name: string;
  type: WorkerInstallType;
  description: string;
};

type InstallTypeRowParams = {
  type: InstallTypeInfo;
  selected: boolean;
  onClick: () => void;
};
const InstallTypeRow: React.FC<InstallTypeRowParams> = ({
  type,
  selected,
  onClick,
}) => {
  return (
    <Card padding="12px" onClick={onClick} isSelected={selected}>
      <Flex
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Flex direction="row" alignContent="center">
          <Box marginLeft="8px">
            <Text variant="16-reg" color="white" noOfLines={1}>
              {type.name}
            </Text>
            <Flex direction="row" alignContent="center">
              <Text variant="14-reg" color="gray.400">
                {type.description}
              </Text>
            </Flex>
          </Box>
        </Flex>
        <Box
          height="100%"
          display="flex"
          alignItems="center"
          marginRight="16px"
          width="24px"
        >
          {selected ? (
            <CheckCircleIcon color="blue.500" fontSize="24px" />
          ) : null}
        </Box>
      </Flex>
    </Card>
  );
};

type Params = (CreateWorkerParams | UpdateWorkerParams) & {
  installType?: WorkerInstallType;
};

type WorkerInstallTypeSelectProps = {
  params: Params;
  setParams:
    | ReturnType<typeof useWorker>["setCreateWorkerParams"]
    | ReturnType<typeof useWorker>["setUpdateWorkerParams"]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-redundant-type-constituents
    | any;
};

const WorkerInstallTypeSelect: React.FC<WorkerInstallTypeSelectProps> = ({
  params,
  setParams,
}) => {
  const { isTauri } = useAppConfig();

  const tauri: InstallTypeInfo[] = [
    {
      name: "MacOS Desktop App",
      type: "Desktop App",
      description: "Recommended for non-techical users.",
    },
    {
      name: "Windows Desktop",
      type: "Desktop Windows",
      description: "Recommended for non-technical users.",
    },
  ];

  const types: InstallTypeInfo[] = [
    {
      name: "Docker",
      type: "Docker",
      description:
        "Run Kuzco as a Docker container. Requires Docker to be installed.",
    },
    {
      name: "CLI",
      type: "CLI",
      description:
        "Linux or macOS only. Additional command-line setup required after creation.",
    },
  ];

  if (isTauri) {
    types.unshift(...tauri);
  }

  return (
    <Flex direction="column">
      <Label>Install Type</Label>
      {types.map((type, index) => (
        <>
          <InstallTypeRow
            key={type.name}
            type={type}
            selected={(params.installType ?? "") === type.type}
            onClick={() => {
              setParams({ ...params, installType: type.type });
            }}
          />
          {index === types.length - 1 ? null : <Box height="8px" />}
        </>
      ))}
      <Box height="16px" />
      <Text variant="12-reg" color="gray.400">
        {/* Note: We do not currently support CLI workers on MacOS or Windows. */}
      </Text>
    </Flex>
  );
};

export default WorkerInstallTypeSelect;
