import React from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Link,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { FiCheck, FiLock, FiMail, FiUser } from "react-icons/fi";
import { RxDiscordLogo } from "react-icons/rx";

import { DISCORD_CLIENT_ID, DISCORD_REDIRECT_URI } from "~/lib/const";
import { api } from "~/lib/trpc";
import useUser from "~/ui/hooks/useUser.hook";
import SettingsLayout from "~/ui/layouts/Settings.layout";
import Disable2FAConfirmationModal from "./Disable2FAConfirmationModal";
import TwoFactorAuthModal from "./TwoFactorAuthModal";

const AccountSettingsPage: React.FC = () => {
  const {
    isOpen: isEnable2FAOpen,
    onOpen: onEnable2FAOpen,
    onClose: onEnable2FAClose,
  } = useDisclosure();
  const {
    isOpen: isDisable2FAOpen,
    onOpen: onDisable2FAOpen,
    onClose: onDisable2FAClose,
  } = useDisclosure();
  const { user, refreshUser } = useUser();
  const toast = useToast();

  const disable2FAMutation = api.user.disableOTP.useMutation();

  const handleEnable2FA = () => {
    onEnable2FAOpen();
  };

  const handleDisable2FA = () => {
    onDisable2FAOpen();
  };

  const confirmDisable2FA = async () => {
    try {
      await disable2FAMutation.mutateAsync();
      await refreshUser();
      onDisable2FAClose();
      toast({
        title: "2FA Disabled",
        description:
          "Two-factor authentication has been disabled for your account.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error disabling 2FA:", error);
      toast({
        title: "Error",
        description:
          "Failed to disable two-factor authentication. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const unlinkDiscordMutation = api.user.unlinkDiscordAccount.useMutation();

  const handleLinkDiscord = () => {
    const state = user?._id;
    const url = `https://discord.com/api/oauth2/authorize?client_id=${DISCORD_CLIENT_ID}&redirect_uri=${encodeURIComponent(DISCORD_REDIRECT_URI)}&response_type=code&scope=identify+email&state=${state}`;
    window.location.href = url;
  };

  const handleUnlinkDiscord = async () => {
    try {
      await unlinkDiscordMutation.mutateAsync();
      await refreshUser();
      toast({
        title: "Success",
        description: "Discord account unlinked successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error unlinking Discord account:", error);
      toast({
        title: "Error",
        description: "Failed to unlink Discord account. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <SettingsLayout
      title="Account Settings"
      description="Manage your Kuzco account settings"
    >
      <TwoFactorAuthModal isOpen={isEnable2FAOpen} onClose={onEnable2FAClose} />
      <Disable2FAConfirmationModal
        isOpen={isDisable2FAOpen}
        onClose={onDisable2FAClose}
        onConfirm={confirmDisable2FA}
      />
      <VStack spacing={6} align="stretch">
        <Divider />
        <Box>
          <Heading as="h2" size="md" mb={2}>
            <Flex alignItems="center">
              <FiUser />
              <Text ml={2}>Account Information</Text>
            </Flex>
          </Heading>
          <Text>Name: {user?.firstName ?? "Not set"}</Text>
          <Text>Username: {user?.username ?? "Not set"}</Text>
          <Text>Email: {user?.email}</Text>
        </Box>

        <Divider />

        <Box>
          <Flex justifyContent="space-between" alignItems="center">
            <Flex alignItems="center">
              <FiMail />
              <Text fontSize="xl" ml={2}>
                Email Verification
              </Text>
            </Flex>
            {user?.emailVerifiedAt ? (
              <Flex alignItems="center" color="green.500">
                <FiCheck />
                <Text ml={2}>Verified</Text>
              </Flex>
            ) : (
              <Link href={"/verify-email"} target="_blank">
                <Button variant="secondary">Verify Email</Button>
              </Link>
            )}
          </Flex>
        </Box>

        <Divider />

        <Box>
          <Flex justifyContent="space-between" alignItems="center">
            <Flex alignItems="center">
              <RxDiscordLogo />
              <Text fontSize="xl" ml={2}>
                Discord Connection
              </Text>
            </Flex>
            {user?.discordId ? (
              <Flex flexDirection="column" alignItems="flex-end">
                <Flex alignItems="center" color="green.500">
                  <FiCheck />
                  <Text ml={2}>Connected</Text>
                  <Text ml={2} fontSize="sm">
                    "{user.discordUsername}"
                  </Text>
                </Flex>
                <Button
                  mt={2}
                  onClick={handleUnlinkDiscord}
                  variant="outline"
                  size="sm"
                >
                  Unlink Discord
                </Button>
              </Flex>
            ) : (
              <Button onClick={handleLinkDiscord} variant="secondary">
                Link Discord
              </Button>
            )}
          </Flex>
        </Box>
        <Divider />

        <Box>
          <Flex justifyContent="space-between" alignItems="center">
            <Flex alignItems="center">
              <FiLock />
              <Text fontSize="xl" ml={2}>
                Two-Factor Authentication
              </Text>
            </Flex>
            {user?.otpSecret !== null ? (
              <Flex alignItems="center" color="green.500">
                <FiCheck />
                <Text ml={2}>Enabled</Text>
                <Button
                  onClick={handleDisable2FA}
                  ml={2}
                  variant="outline"
                  colorScheme="red"
                  size="sm"
                >
                  Disable 2FA
                </Button>
              </Flex>
            ) : (
              <Button onClick={handleEnable2FA} variant="secondary">
                Enable 2FA
              </Button>
            )}
          </Flex>
        </Box>
      </VStack>
    </SettingsLayout>
  );
};

export default AccountSettingsPage;
