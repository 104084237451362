import { Box, Flex, Image, LinkBox, LinkOverlay, Text } from "@chakra-ui/react";

import DiscordLogo from "~/assets/discord.svg";
import GitHubLogo from "~/assets/github.svg";
import TwitterLogo from "~/assets/twitter.svg";
import { DISCORD_INVITE_LINK, GITHUB_LINK, TWITTER_LINK } from "~/lib/const";
import Card from "~/ui/elements/Card";
import useIsMobile from "~/ui/hooks/useIsMobile.hook";

const JoinSocials = () => {
  const isMobile = useIsMobile();

  return (
    <Card
      width={isMobile ? "100%" : "600px"}
      padding={isMobile ? "16px" : "24px"}
    >
      <LinkBox>
        <Flex alignItems="center" marginBottom="4px">
          <Image src={DiscordLogo} width="24px" height="24px" />
          <Box width="12px" />
          <Text variant="18-med">Join Discord</Text>
        </Flex>
        <Text variant="16-reg" color="gray.400" textAlign="left">
          Join the Kuzco&nbsp;
          <LinkOverlay
            href={DISCORD_INVITE_LINK}
            display="inline"
            textDecoration="underline"
            target="_blank"
          >
            Discord
          </LinkOverlay>{" "}
          to win prizes, connect with other network participants, get support,
          and more.
        </Text>
      </LinkBox>
      <Box height="32px" />
      <LinkBox>
        <Flex alignItems="center" marginBottom="4px">
          <Image src={TwitterLogo} width="24px" height="24px" />
          <Box width="12px" />
          <Text variant="18-med">Connect on Twitter</Text>
        </Flex>
        <Text variant="16-reg" color="gray.400" textAlign="left">
          Follow us on{" "}
          <LinkOverlay
            href={TWITTER_LINK}
            display="inline"
            textDecoration="underline"
            target="_blank"
          >
            Twitter
          </LinkOverlay>{" "}
          for the latest announcements and updates.
        </Text>
      </LinkBox>
      <Box height="32px" />
      <LinkBox>
        <Flex alignItems="center" marginBottom="4px">
          <Image src={GitHubLogo} width="24px" height="24px" />
          <Box width="12px" />
          <Text variant="18-med">Follow us on GitHub</Text>
        </Flex>
        <Text variant="16-reg" color="gray.400" textAlign="left">
          Kuzco will be open source soon™. Follow us on{" "}
          <LinkOverlay
            href={GITHUB_LINK}
            display="inline"
            textDecoration="underline"
            target="_blank"
          >
            GitHub
          </LinkOverlay>{" "}
          for updates.
        </Text>
      </LinkBox>
    </Card>
  );
};

export default JoinSocials;
