import React from "react";
import { Badge, Box, Button, Flex, Image, Text } from "@chakra-ui/react";

import type { Instance } from "@kuzco/models";

import Card from "~/ui/elements/Card";
import { useInstanceGpuTypeLogo } from "../../hooks/useGpuTypeLogo.hook";
import useWorkerLogs from "../../hooks/useWorkerLogs.hook";
import InstanceInfo from "./InstanceInfo";

type InstanceCardProps = {
  instance: Instance;
};

const Logs = ({ instance }: { instance: Instance }) => {
  const logContainerRef = React.useRef<HTMLDivElement>(null);
  const { logs } = useWorkerLogs(instance.workerId, instance._id);

  return (
    <Box
      borderTop="1px solid"
      borderColor="gray.800"
      backgroundColor="gray.1000"
      ref={logContainerRef}
      padding="16px"
      height="400px"
      overflow="auto"
      style={{
        overscrollBehaviorY: "contain",
        scrollSnapType: "y proximity",
      }}
    >
      {(() => {
        if (logs.length === 0) {
          return (
            <Text
              as="span"
              fontFamily="monospace"
              fontSize="14px"
              color="green.600"
            >
              Starting...
            </Text>
          );
        }

        return logs.map((log, i) => {
          return (
            <Text
              fontFamily="monospace"
              variant="14-reg"
              key={i}
              style={i === logs.length - 1 ? { scrollSnapAlign: "end" } : {}}
            >
              <Text as="span" fontSize="inherit" color="green.600">
                {log.header}
              </Text>
              &nbsp;
              <Text as="span" fontSize="inherit" color="gray.400">
                {log.timestamp}
              </Text>
              &nbsp;
              <Text as="span" fontSize="inherit" color="gray.400">
                {log.level}
              </Text>
              &nbsp;
              <Text as="span" fontSize="inherit" color="gray.400">
                {log.message.join(" ")}
              </Text>
            </Text>
          );
        });
      })()}
    </Box>
  );
};

const InstanceCard: React.FC<InstanceCardProps> = ({ instance }) => {
  const [showLogs, setShowLogs] = React.useState(false);
  const logo = useInstanceGpuTypeLogo(instance);

  const poolAssignment = instance.poolAssignments[1]?.model;

  return (
    <Card padding="0px">
      <Card border="0px">
        <Flex alignItems="center" justifyContent="space-between">
          <Flex>
            {logo && (
              <>
                <Image
                  alt="Logo"
                  src={logo}
                  style={{
                    maxWidth: "24px",
                    height: "24px",
                    borderRadius: "6px",
                  }}
                />
                <Box width="8px" />
              </>
            )}
            <Text variant="18-bold">
              {instance.name}{" "}
              {poolAssignment && <Badge>{poolAssignment}</Badge>}
            </Text>
          </Flex>
          <Flex>
            <Button
              variant="secondary"
              size="sm"
              onClick={() => setShowLogs((show) => !show)}
            >
              {showLogs ? "Hide Logs" : "View Logs"}
            </Button>
          </Flex>
        </Flex>
        <Box height="16px" />
        <InstanceInfo key={instance._id} instance={instance} columns={6} />
      </Card>
      {showLogs && <Logs instance={instance} />}
    </Card>
  );
};

export default InstanceCard;
