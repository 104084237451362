import React, { useMemo } from "react";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
// import { UnsafeBurnerWalletAdapter } from "@solana/wallet-adapter-wallets";
import { clusterApiUrl } from "@solana/web3.js";

import "@solana/wallet-adapter-react-ui/styles.css";

import { NODE_ENV } from "../../lib/const";
import getSolanaClusterFromNodeEnv from "../../utils/getSolanaClusterFromNodeEnv";
import { useAppConfig } from "../hooks/useAppConfig.hook";

type SolanaContextProviderProps = {
  children: React.ReactNode;
};

// Bootstrapped from https://github.com/anza-xyz/wallet-adapter/blob/master/APP.md.
export default function SolanaContextProvider({
  children,
}: SolanaContextProviderProps) {
  const { isTauri } = useAppConfig();
  const network = getSolanaClusterFromNodeEnv(NODE_ENV);
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  const wallets = useMemo(
    () => [
      /**
       * Wallets that implement either of these standards will be available automatically.
       *
       *   - Solana Mobile Stack Mobile Wallet Adapter Protocol
       *     (https://github.com/solana-mobile/mobile-wallet-adapter)
       *   - Solana Wallet Standard
       *     (https://github.com/anza-xyz/wallet-standard)
       *
       * If you wish to support a wallet that supports neither of those standards,
       * instantiate its legacy wallet adapter here. Common legacy adapters can be found
       * in the npm package `@solana/wallet-adapter-wallets`.
       */
      // new UnsafeBurnerWalletAdapter(),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [network],
  );

  // Wallet is unavailable on Tauri builds.
  if (isTauri) {
    return children;
  }

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>{children}</WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}
