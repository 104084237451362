import { Box } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import GenerationTable from "~/ui/components/generation/GenerationTable";
import WorkerInfo from "~/ui/components/worker/WorkerInfo";
import useIsMobile from "~/ui/hooks/useIsMobile.hook";
import useWorker from "~/ui/hooks/useWorker.hook";

type WorkerGenerationsPageProps = unknown;

const WorkerGenerationsPage: React.FC<WorkerGenerationsPageProps> = () => {
  const { workerId = "" } = useParams();
  const { worker } = useWorker(workerId);
  const isMobile = useIsMobile();

  return (
    <>
      <Box height="24px" />
      {worker != null && (
        <WorkerInfo worker={worker} columns={isMobile ? 1 : 6} cards />
      )}
      <Box height="24px" />
      <GenerationTable workerId={workerId} />
    </>
  );
};

export default WorkerGenerationsPage;
