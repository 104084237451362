import "./index.css";

import React, { useEffect } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { createStore } from "jotai";
import { Provider } from "jotai/react";
import posthog from "posthog-js";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import ReactTooltip from "react-tooltip";

import type { AppConfig } from "./lib/types";
import { POSTHOG_API_KEY } from "~/lib/const";
import { TRPCReactProvider } from "./lib/trpc";
import AccountSettingsPage from "./pages/dashboard/AccountSettings.page";
import APIKeysPage from "./pages/dashboard/APIKeys.page";
import MyStatsPage from "./pages/dashboard/MyStats.page";
import OverviewPage from "./pages/dashboard/Overview.page";
import SuperUsersPage from "./pages/dashboard/super/SuperUsers.page";
import CreateWorkerPage from "./pages/dashboard/workers/CreateWorker.page";
import WorkerBenchmarksPage from "./pages/dashboard/workers/WorkerBenchmarks.page";
import WorkerDetailsPage from "./pages/dashboard/workers/WorkerDetails.page";
import WorkerGenerationsPage from "./pages/dashboard/workers/WorkerGenerations.page";
import WorkerInstancesPage from "./pages/dashboard/workers/WorkerInstances.page";
import WorkersListPage from "./pages/dashboard/workers/WorkerList.page";
import WorkerLogsPage from "./pages/dashboard/workers/WorkerLogs.page";
import WorkerSettingsPage from "./pages/dashboard/workers/WorkerSetings.page";
import DisabledUserPage from "./pages/DisabledUser.page";
import DownloadPage from "./pages/Download.page";
import ErrorPage from "./pages/Error.page";
import ForgotPasswordPage from "./pages/ForgotPassword.page";
import HardwarePage from "./pages/Hardware.page";
import IndexPage from "./pages/Index.page";
import PrivacyPolicyPage from "./pages/legal/PrivacyPolicy.page";
import TermsPage from "./pages/legal/Terms.page";
import LoginPage from "./pages/Login.page";
import MapPage from "./pages/Map.page";
import RegisterPage from "./pages/Register.page";
import ResetPasswordPage from "./pages/ResetPassword.page";
import SetUsernamePage from "./pages/SetUsernamePage.page";
import VerifyEmailConfirmationPage from "./pages/VerifyEmailConfirmation.page";
import VerifyEmailPage from "./pages/VerifyEmailPage.page";
import VerifyInstallation from "./pages/VerifyInstallation.page";
import WalletPage from "./pages/Wallet.page";
import WelcomePage from "./pages/Welcome.page";
import GlobalErrorBoundary from "./ui/components/GlobalErrorBoundary";
import { ModalProvider } from "./ui/components/modal/Modal";
import { AppConfigContext } from "./ui/contexts/AppConfig.context";
import DashboardLayout from "./ui/layouts/Dashboard.layout";
import SuperLayout from "./ui/layouts/Super.layout";
import WorkerLayout from "./ui/layouts/Worker.layout";
import SolanaContextProvider from "./ui/providers/SolanaContextProvider";
import { theme } from "./ui/style/theme";

posthog.init(POSTHOG_API_KEY, {
  api_host: "https://a.kuzco.xyz",
  session_recording: {
    maskAllInputs: false,
  },
  disable_session_recording: true,
  autocapture: false,
  enable_recording_console_log: false,
});

const AppContainer = () => {
  const location = useLocation();
  useEffect(() => {
    posthog.capture("$pageview");
  }, [location]);

  return <Outlet />;
};

const getRouter = (config: AppConfig) => {
  return createBrowserRouter([
    {
      path: "/",
      element: <AppContainer />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/",
          element: config.isTauri ? <LoginPage /> : <IndexPage />,
          index: true,
        },
        {
          path: "/map",
          element: <MapPage />,
        },
        {
          path: "/gpus",
          element: <HardwarePage />,
        },
        {
          path: "/download",
          element: <DownloadPage />,
        },
        {
          path: "/privacy-policy",
          element: <PrivacyPolicyPage />,
        },
        {
          path: "/terms",
          element: <TermsPage />,
        },
        {
          path: "/login",
          element: <LoginPage />,
        },
        {
          path: "/register",
          element: <RegisterPage />,
        },
        {
          path: "/set-username",
          element: <SetUsernamePage />,
        },
        {
          path: "/forgot-password",
          element: <ForgotPasswordPage />,
        },
        {
          path: "/reset-password",
          element: <ResetPasswordPage />,
        },
        {
          path: "/verify-installation",
          element: <VerifyInstallation />,
        },
        {
          path: "/verify-email",
          element: <VerifyEmailPage />,
        },
        {
          path: "/verify-email-confirmation/:emailVerificationToken",
          element: <VerifyEmailConfirmationPage />,
        },
        {
          path: "/welcome",
          element: <WelcomePage />,
        },
        {
          path: "/paused",
          element: <DisabledUserPage />,
        },
        {
          path: "/dashboard",
          element: <DashboardLayout />,
          children: [
            {
              index: true,
              path: "/dashboard/overview",
              element: <OverviewPage />,
            },
            {
              index: true,
              path: "/dashboard/my-stats",
              element: <MyStatsPage />,
            },
            {
              path: "/dashboard/account",
              element: <AccountSettingsPage />,
            },
            {
              path: "/dashboard/workers",
              element: <WorkersListPage />,
            },
            {
              path: "/dashboard/workers/create",
              element: <CreateWorkerPage />,
            },
            {
              path: "/dashboard/workers",
              element: <WorkerLayout />,
              children: [
                {
                  path: "/dashboard/workers/details/:workerId",
                  element: <WorkerDetailsPage />,
                },
                {
                  path: "/dashboard/workers/instances/:workerId",
                  element: <WorkerInstancesPage />,
                },
                {
                  path: "/dashboard/workers/logs/:workerId",
                  element: <WorkerLogsPage />,
                },
                {
                  path: "/dashboard/workers/generations/:workerId",
                  element: <WorkerGenerationsPage />,
                },
                {
                  path: "/dashboard/workers/benchmarks/:workerId",
                  element: <WorkerBenchmarksPage />,
                },
                {
                  path: "/dashboard/workers/settings/:workerId",
                  element: <WorkerSettingsPage />,
                },
              ],
            },
            {
              path: "/dashboard/keys",
              element: <APIKeysPage />,
            },
            {
              path: "/dashboard/wallet",
              element: <WalletPage />,
            },
            {
              path: "/dashboard/super",
              element: <SuperLayout />,
              children: [
                {
                  path: "/dashboard/super/users",
                  element: <SuperUsersPage />,
                },
                // {
                //   path: "/dashboard/workers/settings/:workerId",
                //   element: <SuperWorkersPage />,
                // },
              ],
            },
          ],
        },
        {
          path: "*",
          element: <Navigate to="/" replace />,
        },
      ],
    },
  ]);
};

/**
 * Export these because we need to use them in the Tauri app
 * to create a custom logger that passes errors as a Modal from the
 * worker to the main UI thread.
 */

export type { AppConfig };

export * from "./ui/components/modal/Confirm.modal";
export * from "./ui/components/modal/Modal";
export * from "./ui/jotai/app.jotai";

export { trpc } from "./lib/trpc";

export {
  CONSOLE_VERSION,
  CONSOLE_VERSION_HASH,
  CONSOLE_FULL_VERSION,
} from "./lib/const";

export const jotaiStore = createStore();

export const App: React.FC<{ appConfig: AppConfig }> = ({ appConfig }) => {
  return (
    <TRPCReactProvider>
      <AppConfigContext.Provider value={appConfig}>
        <SolanaContextProvider>
          <Provider store={jotaiStore}>
            <ChakraProvider theme={theme}>
              <GlobalErrorBoundary>
                <ModalProvider />
                <ReactTooltip />
                <RouterProvider router={getRouter(appConfig)} />
              </GlobalErrorBoundary>
            </ChakraProvider>
          </Provider>
        </SolanaContextProvider>
      </AppConfigContext.Provider>
    </TRPCReactProvider>
  );
};
