import type { inferRouterInputs, inferRouterOutputs } from "@trpc/server";
import { useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  createTRPCProxyClient,
  createTRPCReact,
  httpBatchLink,
  loggerLink,
} from "@trpc/react-query";
import superjson from "superjson";

import type { ServerRouter } from "@kuzco/api";

import { clearToken, getToken, isAuthenticated } from "./auth";
import { API_URL } from "./const";

const trpcOps = {
  transformer: superjson,
  links: [
    loggerLink({
      enabled: (op) =>
        process.env.NODE_ENV === "development" ||
        (op.direction === "down" && op.result instanceof Error),
    }),
    httpBatchLink({
      url: `${API_URL}/api/trpc/`,
      headers() {
        const headers: Record<string, string> = {};
        if (isAuthenticated()) {
          headers.Authorization = `Bearer ${getToken()}`;
        }

        return headers;
      },
    }),
  ],
};

export const api = createTRPCReact<ServerRouter>();
export const trpc = createTRPCProxyClient<ServerRouter>(trpcOps);

export function TRPCReactProvider(props: { children: React.ReactNode }) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            onError: (error) => {
              try {
                const isTokenInvalidError =
                  (error as Error).message === "Invalid token.";
                if (isTokenInvalidError) {
                  console.warn(
                    "Invalid user authentication — user will be logged out.",
                  );
                  clearToken();
                  return;
                }
              } catch {
                // No action.
              }
              console.error(error);
            },
          },
        },
      }),
  );

  const [trpcClient] = useState(() => api.createClient(trpcOps));

  return (
    <api.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        {props.children}
      </QueryClientProvider>
    </api.Provider>
  );
}

export type RouterInput = inferRouterInputs<ServerRouter>;
export type RouterOutput = inferRouterOutputs<ServerRouter>;
