import { Box } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import BenchmarkTable from "~/ui/components/benchmark/BenchmarkTable";
import WorkerInfo from "~/ui/components/worker/WorkerInfo";
import useIsMobile from "~/ui/hooks/useIsMobile.hook";
import useWorker from "~/ui/hooks/useWorker.hook";

type WorkerBenchmarksPageProps = unknown;

const WorkerBenchmarksPage: React.FC<WorkerBenchmarksPageProps> = () => {
  const { workerId = "" } = useParams();
  const { worker } = useWorker(workerId);
  const isMobile = useIsMobile();

  return (
    <>
      <Box height="24px" />
      {worker != null && (
        <WorkerInfo worker={worker} columns={isMobile ? 1 : 6} cards />
      )}
      <Box height="24px" />
      <BenchmarkTable workerId={workerId} />
    </>
  );
};

export default WorkerBenchmarksPage;
