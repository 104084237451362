import { Suspense } from "react";
import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import SolanaLogo from "~/assets/solana.svg";
import { api } from "~/lib/trpc";
import CardsChart from "~/ui/components/metrics/Cards.chart";
import GenerationCountChart from "~/ui/components/metrics/GenerationCount.chart";
// import OnlineInstancesMap from "~/ui/components/metrics/OnlineInstancesMap";
import TokenEarningsChart from "~/ui/components/metrics/TokenEarnings.chart";
import { withSuspense } from "~/ui/hoc/withSuspense";
import MarketingLayout from "~/ui/layouts/Marketing.layout";
import NetworkSummarySuspense from "~/ui/suspense/NetworkSummary.suspense";

function NetworkSummary() {
  const [rpm] = api.metrics.rpm.useSuspenseQuery();
  const [globalMetrics] = api.metrics.global.useSuspenseQuery();
  const [countOnline] = api.instance.countOnline.useSuspenseQuery(undefined, {
    refetchInterval: 5000,
  });

  if (globalMetrics == null) {
    return <NetworkSummarySuspense />;
  }

  return (
    <Box padding={{ base: "24px", md: "0" }}>
      <CardsChart
        values={[
          {
            title: "Online Workers",
            value: countOnline.count ?? 0,
            subtitle: "Updated in real-time",
          },
          {
            title: "Current RPM",
            value: rpm,
            subtitle: "Updated every 5 minutes",
            isKuzcoValue: true,
          },
          {
            title: "24H Network Payout",
            value: globalMetrics.tokensLast24Hours,
            subtitle: "Updated every 5 minutes",
            isKuzcoValue: true,
          },
        ]}
      />
      <Box height="24px" />
      <TokenEarningsChart
        title="$KZO Points Payout"
        metric={globalMetrics.tokensLast30DaysBarChart}
      />
      <Box height="24px" />
      <Box height="24px" />
      <GenerationCountChart
        title="Aggregate Network Requests"
        // @ts-expect-error - need better types here.
        metric={globalMetrics.generationsLast24HoursLineChart}
      />
    </Box>
  );
}

const IndexPage = () => {
  return (
    <MarketingLayout>
      <Flex
        height="100%"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Flex width="100%">
          <Flex
            flex="1"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Flex
              flex="1"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              width={{ base: "100%", md: "640px" }}
              padding={{ base: "0 24px", md: "0" }}
            >
              <Text
                fontSize={{ base: "32px", md: "48px" }}
                fontWeight="500"
                lineHeight={{ base: "36px", md: "56px" }}
                color="white"
                align="center"
              >
                The distributed GPU cluster for LLM inference on&nbsp;
                <Image
                  alt="Solana Logo"
                  src={SolanaLogo}
                  width={{ base: "30px", md: "40px" }}
                  height={{ base: "30px", md: "40px" }}
                  display="inline-block"
                  position="relative"
                  margin="0 2px"
                  top="-3px"
                />
                &nbsp;Solana
              </Text>
              <Box height="16px" />
              <Text
                variant={{ base: "16-reg", md: "18-reg" }}
                color="gray.400"
                lineHeight="1.5"
                align="center"
                width={{ base: "90%", md: "520px" }}
              >
                Get paid to contribute your idle compute power to the Kuzco
                network. Use the network to inference popular models like
                Llama3.1 and others using an OpenAI compatible API.
              </Text>

              <Box height="24px" />
              <Flex>
                <Link to="/download">
                  <Button
                    width="254px"
                    variant="primary"
                    backgroundColor="green.500"
                    color="white"
                    size="lg"
                    _hover={{ bg: "green.400" }}
                    _focus={{ bg: "green.600" }}
                  >
                    Download &#8595;
                  </Button>
                </Link>
              </Flex>
              <Box height="16px" />
              <Text variant="12-reg" color="gray.500">
                Available today for macOS, Windows and Linux
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Box height={{ base: "32px", md: "64px" }} />
      <Suspense
        fallback={
          <Box padding={{ base: "24px", md: "0" }}>
            <NetworkSummarySuspense />
          </Box>
        }
      >
        <NetworkSummary />
      </Suspense>
    </MarketingLayout>
  );
};

export default withSuspense(IndexPage);
