import { atom } from "jotai";
import { atomFamily, atomWithDefault } from "jotai/utils";

import type {
  CreateWorkerParams,
  UpdateWorkerParams,
  WorkerInstallType,
} from "@kuzco/models";

import { trpc } from "~/lib/trpc";

const createWorkerParams = atomFamily((installType: WorkerInstallType) =>
  atomWithDefault<CreateWorkerParams>((get) => {
    return get(
      atom<CreateWorkerParams>({
        name: "",
        installType,
      }),
    );
  }),
);

const updateWorkerParams = atomFamily((workerId: string) => {
  const workerState = {
    workerId,
    name: "",
  };

  return atomWithDefault<UpdateWorkerParams>(async () => {
    try {
      const { workers } = await trpc.worker.list.query();
      const worker = workers?.find((w) => w._id === workerId);

      if (!worker) {
        return workerState;
      }

      return {
        workerId: worker._id,
        name: worker.name,
      };
    } catch (e) {
      return workerState;
    }
  });
});

export const WorkerState = {
  createWorkerParams,
  updateWorkerParams,
};
