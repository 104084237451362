import type { NatsConnection, Subscription } from "nats.ws";
import React from "react";
import { connect } from "nats.ws";

import type { LogMessage } from "@kuzco/models";
import { LogLevel, LogMessageCodec, workerLogTopic } from "@kuzco/models";

import { NATS_LOGS_SERVER_URL } from "~/lib/const";

const MAX_LOG_LINES = 2500;

export default function useWorkerLogs(workerId: string, instanceId?: string) {
  const [logs, setLogs] = React.useState<LogMessage[]>([]);
  const initialMountRef = React.useRef(false);

  React.useEffect(() => {
    if (initialMountRef.current === true) {
      return;
    }

    let nats: NatsConnection | null = null;
    let sub: Subscription | null = null;

    const go = async () => {
      nats = await connect({
        servers: [NATS_LOGS_SERVER_URL],
        name: `Console`,
        reconnect: true,
        maxReconnectAttempts: -1,
      });
      sub = nats.subscribe(workerLogTopic(workerId, instanceId));

      for await (const msg of sub) {
        const log = LogMessageCodec.decode(msg.data);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        if ((log.level >= LogLevel.Info) as unknown as number) {
          // Check if the log has a source property and if it's not "kuzco-runtime" (filter out ollama logs)
          if (!log.properties || log.properties.source !== "kuzco-runtime") {
            setLogs((prev) => {
              if (prev.length >= MAX_LOG_LINES) {
                return [...prev.slice(1), log];
              } else {
                return [...prev, log];
              }
            });
          }
        }
      }
    };
    go().catch((e) => {
      console.error(e);
    });

    return () => {
      initialMountRef.current = true;
      nats?.close().catch((e) => {
        console.error(e);
      });
      sub?.unsubscribe();
    };
  }, [workerId, instanceId]);

  return {
    logs,
  };
}
