import type { As, ButtonProps } from "@chakra-ui/react";
import {
  Button,
  Link as ChakraLink,
  HStack,
  Icon,
  Image,
  Tag,
  Text,
} from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";

type NavButtonProps = {
  icon: As;
  image?: string;
  label: string;
  link?: string;
  isActiveLink?: (path: string) => boolean;
  isExternal?: boolean;
  isCollapsed?: boolean;
  tag?: string;
} & ButtonProps;

export const NavButton = (props: NavButtonProps) => {
  const location = useLocation();
  const { icon, image, label, link, isActiveLink, isExternal, ...buttonProps } =
    props;

  const active = link
    ? isActiveLink?.(location.pathname) ?? location.pathname.includes(link)
    : false;

  const button = (
    <Button
      width="100%"
      justifyContent="start"
      backgroundColor={active ? "gray.900" : "transparent"}
      size="sm"
      _hover={{ cursor: "pointer", backgroundColor: "gray.900" }}
      transition="none"
      margin="0 0 4px"
      {...buttonProps}
    >
      <HStack spacing="3">
        {image ? (
          <Image src={image} width="16px" height="16px" />
        ) : (
          <Icon as={icon} boxSize="4" color="white" />
        )}
        <Text
          color="white"
          variant="14-semi"
          display={{ base: "none", md: "inline-flex" }}
        >
          {label}
        </Text>
        {props.tag ? (
          <Tag
            size="sm"
            bg="gray.800"
            color="gray.500"
            display={{ base: "none", md: "inline-flex" }}
          >
            {props.tag}
          </Tag>
        ) : null}
      </HStack>
    </Button>
  );

  if (!link) return button;

  if (isExternal) {
    return (
      <ChakraLink href={link} style={{ width: "100%" }} target="_blank">
        {button}
      </ChakraLink>
    );
  }

  return (
    <Link to={link} style={{ width: "100%" }}>
      {button}
    </Link>
  );
};
