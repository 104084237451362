import React from "react";
import { Text } from "@chakra-ui/react";

type LabelProps = {
  children?: React.ReactNode;
  optional?: boolean;
};

const Label: React.FC<LabelProps> = ({ children, optional = false }) => {
  return (
    <Text color="white" marginBottom="6px" variant="14-med">
      {children}
      {optional ? (
        <>
          &nbsp;
          <Text color="gray.400" variant="14-med" as="span">
            (Optional)
          </Text>
        </>
      ) : null}
    </Text>
  );
};

export default Label;
