import { useTheme } from "@chakra-ui/react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";

export default function ConnectWalletButton() {
  const theme = useTheme();
  return (
    <WalletMultiButton
      style={{
        backgroundColor: theme.colors.blue[600],
        borderRadius: 8,
        height: 40,
      }}
    />
  );
}
