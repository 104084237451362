import { api } from "~/lib/trpc";

const PAGE_SIZE = 25;

export default function useBenchmarks(workerId: string) {
  const listByWorker = api.benchmark.listByWorker.useInfiniteQuery(
    {
      workerId,
    },
    {
      getNextPageParam: (_lastPage, allPages) => {
        return {
          pageSize: PAGE_SIZE,
          offset: allPages.length * PAGE_SIZE,
        };
      },
    },
  );

  return {
    listByWorker,
  };
}
