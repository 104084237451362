import { Box, Flex } from "@chakra-ui/react";

import OnlineInstancesMap from "~/ui/components/metrics/OnlineInstancesMap";
import { withSuspense } from "~/ui/hoc/withSuspense";
import MarketingLayout from "~/ui/layouts/Marketing.layout";

const MapPage = () => {
  return (
    <MarketingLayout>
      <Flex
        height="100%"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <OnlineInstancesMap />
      </Flex>
      <Box height={{ base: "32px", md: "64px" }} />
    </MarketingLayout>
  );
};

export default withSuspense(MapPage);
