import React, { useState } from "react";
import {
  Button,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";

import { StatusCodes } from "@kuzco/models";

import { api } from "~/lib/trpc";
import useUser from "~/ui/hooks/useUser.hook";

type TwoFactorAuthModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const TwoFactorAuthModal: React.FC<TwoFactorAuthModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [step, setStep] = useState<"generate" | "verify">("generate");
  const [secret, setSecret] = useState("");
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const { refreshUser } = useUser();
  const enable2FAMutation = api.user.enableOTP.useMutation();
  const verify2FAMutation = api.user.verifyOTP.useMutation();
  const toast = useToast();

  const handleGenerateSecret = async () => {
    try {
      const response = await enable2FAMutation.mutateAsync();

      if (response.secret && response.qrCodeUrl) {
        setSecret(response.secret);
        setQrCodeUrl(response.qrCodeUrl);
        setStep("verify");
      }
    } catch (error) {
      console.error("Error generating 2FA secret:", error);
      toast({
        title: "Error",
        description: "Failed to generate 2FA secret",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleVerify = async () => {
    try {
      const response = await verify2FAMutation.mutateAsync({
        code: verificationCode,
      });
      if (response.status === StatusCodes.OK) {
        await refreshUser();
        onClose();
        console.log("2FA enabled successfully");
        toast({
          title: "Success",
          description: "2FA enabled successfully",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: "Failed to verify 2FA code",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error verifying 2FA:", error);
      toast({
        title: "Error",
        description: "Failed to verify 2FA code",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent backgroundColor="gray.1000">
        <ModalHeader>Set up Two-Factor Authentication</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {step === "generate" ? (
            <VStack spacing={4}>
              <Text>Click the button below to generate your 2FA secret.</Text>
              <Button onClick={handleGenerateSecret}>Generate Secret</Button>
            </VStack>
          ) : (
            <VStack spacing={4}>
              <Text>Scan this QR code with your authenticator app:</Text>
              {qrCodeUrl && <Image src={qrCodeUrl} alt="QR Code" />}
              <Text>
                Or enter this secret manually:{" "}
                <Text as="span" fontFamily="mono">
                  {secret}
                </Text>
              </Text>
              <Text>
                Enter the verification code from your authenticator app:
              </Text>
              <Input
                placeholder="Enter verification code"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
              />
            </VStack>
          )}
        </ModalBody>
        <ModalFooter>
          {step === "verify" && (
            <Button variant="primary" mr={3} onClick={handleVerify}>
              Verify
            </Button>
          )}
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TwoFactorAuthModal;
