import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";

export default function getSolanaClusterFromNodeEnv(
  env: string,
): WalletAdapterNetwork {
  switch (env) {
    case "test":
      return WalletAdapterNetwork.Testnet;
    case "local":
    case "development":
      return WalletAdapterNetwork.Devnet;
    case "production":
      return WalletAdapterNetwork.Mainnet;
    default:
      // I'm throwing an error here rather than making an assumption and
      // potentially connecting to the wrong network.
      throw new Error(`Invalid NODE_ENV provided: ${env}.`);
  }
}
