import { atom } from "jotai";
import { atomFamily, atomWithDefault } from "jotai/utils";

import type { CreateKeyParams } from "@kuzco/models";

import { trpc } from "~/lib/trpc";

const createKeyParams = atomWithDefault<CreateKeyParams>((get) =>
  get(
    atom<CreateKeyParams>({
      name: "",
    }),
  ),
);

const keyList = atomWithDefault((get) =>
  get(
    atom(async () => {
      const response = await trpc.key.list.query();
      return response.keys ?? [];
    }),
  ),
);

const key = atomFamily((keyId: string) => {
  return atom(async (get) => {
    const keys = await get(keyList);
    const key = keys.find((key) => key._id === keyId);
    return key ?? null;
  });
});

export const KeyState = {
  createKeyParams,
  keyList,
  key,
};
