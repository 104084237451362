import { Box } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import WorkerInfo from "~/ui/components/worker/WorkerInfo";
import WorkerLogs from "~/ui/components/worker/WorkerLogs";
import useIsMobile from "~/ui/hooks/useIsMobile.hook";
import useWorker from "~/ui/hooks/useWorker.hook";

type WorkerLogsPageProps = unknown;

const WorkerLogsPage: React.FC<WorkerLogsPageProps> = () => {
  const { workerId = "" } = useParams();
  const { worker } = useWorker(workerId);
  const isMobile = useIsMobile();
  if (worker == null) {
    return null;
  }
  return (
    <>
      <Box height="24px" />
      <WorkerInfo worker={worker} columns={isMobile ? 1 : 6} cards />
      <Box height="24px" />
      <WorkerLogs worker={worker} />
    </>
  );
};

export default WorkerLogsPage;
