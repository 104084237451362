import z from "zod";

import { serviceRequest } from "./BaseService";
import { authenticationResponse } from "./user.models";

/** ******************************************************************************
 *  Constants
 ******************************************************************************* */

// Teams are provided an initial balance of 1 million tokens for free on registration.
export const INITIAL_TEAM_REGISTRATION_TOKEN_ALLOWANCE = 1_000_000;

export const INITIAL_SYSTEM_TOKEN_ALLOWANCE = 1_000_000_000_000;

/** ******************************************************************************
 *  Types
 ******************************************************************************* */

export enum UserTeamRole {
  OWNER = "OWNER",
}

export const TeamSchema = z.object({
  _id: z.string(),
  name: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type Team = z.infer<typeof TeamSchema>;

/** ******************************************************************************
 *  Service Interface
 ******************************************************************************* */

export const registerUserParams = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  username: z.string(),
  password: z.string(),
  turnstileToken: z.string().optional(),
});

export const registerUserRequest = serviceRequest.merge(
  z.object({
    params: registerUserParams,
  }),
);

export const registerUserResponse = authenticationResponse;

export type RegisterUserParams = z.infer<typeof registerUserParams>;
export type RegisterUserRequest = z.infer<typeof registerUserRequest>;
export type RegisterUserResponse = z.infer<typeof registerUserResponse>;

export const registerTeamUserParams = z.object({
  _id: z.string(),
  email: z.string(),
  username: z.string(),
  passwordHash: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  emailVerificationRequired: z.boolean(),
  stripeCustomerId: z.string(),
});

export type RegisterUserTeamParams = z.infer<typeof registerTeamUserParams>;

export type TeamService = {
  /**
   * Check if a user is in a team
   *
   * @param userId - The id of the user
   * @param teamId - The id of the team
   * @returns - True if the user is in the team, false otherwise
   */
  isUserInTeam: (userId: string, teamId: string) => Promise<boolean>;

  /**
   * Get a team by id
   *
   * @param teamId - The id of the team
   * @returns The team or null if it doesn't exist
   */
  getTeam: (teamId: string) => Promise<Team | null>;

  /**
   * Create a team.
   *
   * @param name - The name of the team
   * @param _id - The id of the team
   * @returns The created team
   */
  createTeam: (name: string, _id: string) => Promise<Team>;

  /**
   * Registering users must go through the team service
   * to ensure that the user is associated with a team.
   *
   * @param registerUserParams - The parameters to register a user
   * @returns The registered user
   */
  registerUser: (
    registerUserParams: RegisterUserRequest,
  ) => Promise<RegisterUserResponse>;

  /**
   * Grant a user a role in a team
   *
   * @param userId - The id of the user
   * @param teamId - The id of the team
   * @param role - The role of the user in the team
   */
  grantUserRole: (
    userId: string,
    teamId: string,
    role: UserTeamRole,
  ) => Promise<void>;
};

/** ******************************************************************************
 *  Storage Interface
 ******************************************************************************* */

export type TeamStorageInterface = {
  /**
   * @param userId - The id of the user
   * @param teamId - The id of the team
   * @returns - Whether the user is in the team
   */
  isUserInTeam: (userId: string, teamId: string) => Promise<boolean>;

  /**
   * @param teamId - The id of the team
   * @returns - The team or null if it doesn't exist
   */
  getTeam: (teamId: string) => Promise<Team | null>;

  /**
   * @param name - The name of the team
   * @param _id - The id of the team
   * @returns The created team
   */
  createTeam: (name: string, _id: string) => Promise<Team>;

  /**
   * @param userId - The id of the user
   * @param teamId - The id of the team
   * @param role - The role of the user in the team
   * @returns - The role of the user in the team
   */
  grantUserRole: (
    userId: string,
    teamId: string,
    role: UserTeamRole,
  ) => Promise<void>;

  /**
   * Registering users must go through the team service
   * to ensure that the user is associated with a team and
   * that the team has a valid role for the user (Owner)
   *
   * @param registerUserParams - The parameters to register a user
   * @returns - The registered user
   */
  registerUser: (
    registerUserParams: RegisterUserTeamParams,
  ) => Promise<RegisterUserResponse>;
};
