import React from "react";

import type { GpuType, Instance } from "@kuzco/models";
import { GpuTypeSchema } from "@kuzco/models";

import AmdLogo from "~/assets/amd.svg";
import AppleLogo from "~/assets/apple.svg";
import NvidiaLogo from "~/assets/nvidia.svg";

const instanceToGpuType = (instance: Instance) => {
  if (instance.info.macsmi !== null) {
    return GpuTypeSchema.Enum.Apple;
  }

  if (instance.info.nvidiasmi !== null) {
    return GpuTypeSchema.Enum.NVIDIA;
  }

  if (instance.info.amdsmi !== null) {
    return GpuTypeSchema.Enum.AMD;
  }

  return null;
};

const gpuTypeLogo = (type: GpuType) => {
  if (type === GpuTypeSchema.Enum.Apple) {
    return AppleLogo;
  }

  if (type === GpuTypeSchema.Enum.NVIDIA) {
    return NvidiaLogo;
  }

  if (type === GpuTypeSchema.Enum.AMD) {
    return AmdLogo;
  }

  return null;
};

export const useInstanceGpuTypeLogo = (instance: Instance) => {
  const logo = React.useMemo(() => {
    const type = instanceToGpuType(instance);
    return type ? gpuTypeLogo(type) : null;
  }, [instance]);

  return logo;
};

export const useGpuTypeLogo = (type: GpuType) => {
  const logo = React.useMemo(() => {
    return gpuTypeLogo(type);
  }, [type]);

  return logo;
};
