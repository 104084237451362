import * as React from "react";
import {
  Box,
  Button,
  Flex,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";

import type { CreateKeyParams } from "@kuzco/models";

import Input from "~/ui/elements/Input";
import useKey from "~/ui/hooks/useKey.hook";
import useModal from "~/ui/hooks/useModal.hook";

// eslint-disable-next-line @typescript-eslint/ban-types
export type CreateAPIKeyModalProps = {};

const CreateAPIKeyModal: React.FC<CreateAPIKeyModalProps> = () => {
  const { pop } = useModal();
  const { createKeyParams, setCreateKeyParams, createKey } = useKey();

  React.useEffect(() => {
    return () => {
      setCreateKeyParams({ name: "" });
    };
  }, [setCreateKeyParams]);

  const handleCreateKey = async () => {
    await createKey.mutateAsync(createKeyParams);
    pop();
  };

  return (
    <ModalContent backgroundColor="gray.1000">
      <ModalHeader>
        <Flex align="center" justify="space-between">
          <Text>Create API Key</Text>
          <ModalCloseButton />
        </Flex>
      </ModalHeader>
      <ModalBody>
        <Stack spacing="8">
          <Box
            py={{ base: "0", sm: "4" }}
            px={{ base: "4", sm: "0" }}
            bg={useBreakpointValue({ base: "transparent", sm: "bg-surface" })}
            borderRadius={{ base: "none", sm: "xl" }}
          >
            <Stack spacing="6">
              <form
                style={{ width: "100%" }}
                onSubmit={(e) => {
                  e.preventDefault();
                  void handleCreateKey();
                }}
              >
                <Input
                  type="text"
                  label="Name"
                  value={String(createKeyParams.name)}
                  placeholder="Key Name"
                  autoFocus
                  onChange={(e) =>
                    setCreateKeyParams((params: CreateKeyParams) => {
                      return {
                        ...params,
                        name: e.currentTarget.value,
                      };
                    })
                  }
                />
              </form>
              {createKey.error ? (
                <Text color="red.500" align="center">
                  {createKey.error.message}
                </Text>
              ) : null}
              <Stack spacing="2" direction="row" justifyContent="flex-end">
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={() => {
                    pop();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  size="sm"
                  isLoading={createKey.isLoading}
                  onClick={() => {
                    void handleCreateKey();
                  }}
                >
                  Create Key
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </ModalBody>
    </ModalContent>
  );
};

export default CreateAPIKeyModal;
