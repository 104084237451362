import * as React from "react";
import {
  Box,
  Button,
  Flex,
  Link,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Text,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import { FiAperture } from "react-icons/fi";

import type { WorkerWithInstances } from "@kuzco/models";
import { WorkerInstallTypeSchema } from "@kuzco/models";

import { CLI_INSTALL_SCRIPT, VITE_APP_BASE_URL } from "../../../lib/const";
import useModal from "../../hooks/useModal.hook";
import CodeSnippet from "../CLICommand";

export type RegisterWorkerProps = {
  worker: WorkerWithInstances;
};

export const RegisterWorkerModal: React.FC<RegisterWorkerProps> = ({
  worker,
}) => {
  const theme = useTheme();
  const { pop } = useModal();

  const cmd = `kuzco worker start --worker ${worker._id} --code ${worker.registrationCode}`;

  return (
    <ModalContent backgroundColor="gray.1000" maxWidth="640px">
      <ModalHeader>
        <Flex align="center" justify="space-between">
          <Flex alignItems="center">
            <FiAperture
              style={{ top: 1 }}
              color={theme.colors.green[500]}
              fontSize="18px"
            />
            <Text variant="16-bold" color="white" marginLeft="8px">
              {worker.installType === WorkerInstallTypeSchema.Enum.CLI
                ? "CLI Launch Commands"
                : "Docker Launch Commands"}
            </Text>
          </Flex>
          <ModalCloseButton />
        </Flex>
      </ModalHeader>
      <ModalBody>
        <Flex alignItems="flex-start" justifyContent="space-between">
          {worker.installType === WorkerInstallTypeSchema.Enum.CLI ? (
            <Flex flexDir="column" gridGap={"30px"}>
              <VStack alignItems={"start"}>
                <Text variant="14-reg" color="white">
                  <strong>Step 1:</strong> Install the CLI
                </Text>
                <CodeSnippet
                  cmd={CLI_INSTALL_SCRIPT.replace(
                    "[KUZCO_BASE_URL]",
                    VITE_APP_BASE_URL,
                  )}
                  width="100%"
                />
                <Text variant="12-reg" color="gray.500">
                  Supported on macOS and Linux. Compatible with ARM64 (Apple
                  Silicon) and AMD64/ARM64 (Linux).
                </Text>
              </VStack>

              <VStack alignItems={"start"}>
                <Text variant="14-reg" color="white" marginBottom="4px">
                  <strong>Step 2:</strong> Run an instance of this worker
                </Text>
                <CodeSnippet cmd={cmd} width="100%" />
              </VStack>
            </Flex>
          ) : (
            <Box alignItems="center" width="100%">
              <Text variant="14-reg" color="gray.300">
                Run the commands below to download and run your worker as a
                Docker container. If you have any issues, join Discord for help.
              </Text>
              <Box height="24px" />
              <Text variant="12-med" color="white" marginBottom="4px">
                1. Install Docker
              </Text>
              <Text variant="14-reg" color="gray.300">
                Visit the{" "}
                <Link
                  href="https://docs.docker.com/get-docker/"
                  textDecoration="underline"
                  target="_blank"
                >
                  Docker documentation
                </Link>{" "}
                to install Docker for your OS.
              </Text>
              <Box height="24px" />
              <Text variant="12-med" color="white" marginBottom="4px">
                2. For NVIDIA: Install nvidia-toolkit
              </Text>
              <Text variant="14-reg" color="gray.300">
                Visit the{" "}
                <Link
                  href="https://docs.nvidia.com/datacenter/cloud-native/container-toolkit/latest/install-guide.html"
                  textDecoration="underline"
                  target="_blank"
                >
                  NVIDIA Container Toolkit documentation
                </Link>{" "}
                to get NVIDIA runtime setup for Docker.
              </Text>
              <Box height="24px" />
              <Text variant="12-med" color="white" marginBottom="4px">
                3. Pull Docker Image
              </Text>
              <CodeSnippet
                cmd={"docker pull kuzcoxyz/worker:latest"}
                width="100%"
              />
              <Box height="4px" />
              <Text variant="12-reg" color="gray.500">
                Docker image download may take a few minutes to complete. (Model
                Weights included)
              </Text>
              <Box height="24px" />
              <Text variant="12-med" color="white" marginBottom="4px">
                4. Run NVIDIA Worker
              </Text>
              <CodeSnippet
                cmd={`docker run --rm --runtime=nvidia --gpus all -d kuzcoxyz/worker:latest --worker ${worker._id} --code ${worker.registrationCode}`}
                width="100%"
              />
              <Box height="24px" />
              <Text variant="12-reg" color="gray.500">
                OR
              </Text>
              <Box height="24px" />
              <Text variant="12-med" color="white" marginBottom="4px">
                4. Run AMD Worker
              </Text>
              <CodeSnippet
                cmd={`docker run --rm --device=/dev/kfd --device=/dev/dri --group-add video --group-add 110 --security-opt seccomp=unconfined -d kuzcoxyz/workeramd:latest --worker ${worker._id} --code ${worker.registrationCode}`}
                width="100%"
              />
            </Box>
          )}
        </Flex>
        <Flex justifyContent="flex-end">
          <Button
            variant="primary"
            size="sm"
            marginTop="16px"
            marginBottom="8px"
            onClick={() => pop()}
          >
            Close
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  );
};
