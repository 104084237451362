import { Box, Flex } from "@chakra-ui/react";

import { withSuspense } from "~/ui/hoc/withSuspense";
import MarketingLayout from "~/ui/layouts/Marketing.layout";
import HardwareTable from "../ui/components/generation/HardwareTable";

const HardwarePage = () => {
  return (
    <MarketingLayout showEpoch={false}>
      <Flex
        height="100%"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <HardwareTable />
      </Flex>
      <Box height={{ base: "32px", md: "64px" }} />
    </MarketingLayout>
  );
};

export default withSuspense(HardwarePage);
