import React from "react";
import { useQuery } from "@tanstack/react-query";

import { AppConfigContext } from "../contexts/AppConfig.context";

export const useAppConfig = () => {
  const context = React.useContext(AppConfigContext);

  const runtimeTypeQuery = useQuery(["runtimeType"], () =>
    context.runtimeType(),
  );

  return {
    ...context,
    runtimeType: runtimeTypeQuery,
  };
};
