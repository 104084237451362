import React, { Suspense } from "react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Spinner, Text, useToast } from "@chakra-ui/react";
import { FiCopy, FiTerminal } from "react-icons/fi";
import { Link, Outlet, useParams } from "react-router-dom";
import { useCopyToClipboard } from "usehooks-ts";

import { CONSOLE_FULL_VERSION } from "~/lib/const";
import SubNavigationButton from "~/ui/elements/SubNavigationButton";
import useIsMobile from "~/ui/hooks/useIsMobile.hook";
import { Content, Header, Title } from "~/ui/layouts/Page.layout";
import { LaunchInstanceButton } from "../components/instance/LaunchInstanceButton";
import useUser from "../hooks/useUser.hook";
import useWorker from "../hooks/useWorker.hook";
import WorkerDetailsPageSuspense from "../suspense/WorkerDetailsPage.suspense";

const WorkerActionButtons = ({ workerId }: { workerId: string }) => {
  const { isSuper } = useUser();
  const { worker } = useWorker(workerId);
  const toast = useToast();
  const [_, copy] = useCopyToClipboard();

  if (!worker) return null;

  const buttons = [];

  if (isSuper) {
    buttons.push(
      <Button
        variant="ghost"
        size="sm"
        onClick={() => {
          window.open(
            `https://logs.betterstack.com/team/268008/tail?q=${worker._id}`,
            "_blank",
          );
        }}
      >
        <FiTerminal />
        <Box width="8px" />
        View Betterstack
      </Button>,
    );
  }

  buttons.push(
    <Button
      variant="ghost"
      size="sm"
      onClick={async () => {
        await copy(
          JSON.stringify(
            {
              userId: worker.userId,
              workerId: worker._id,
              workerName: worker.name,
              workerType: worker.installType,
              consoleVersion: CONSOLE_FULL_VERSION,
            },
            null,
            2,
          ),
        );

        toast({
          title: "Debug info copied.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }}
    >
      <FiCopy />
      <Box width="8px" />
      Debug Worker
    </Button>,
  );

  buttons.push(<LaunchInstanceButton workerId={worker._id} />);

  return <Flex gap={1}>{buttons}</Flex>;
};

const WorkerLayoutComponent: React.FC = () => {
  const isMobile = useIsMobile();
  const { workerId } = useParams();
  const { worker } = useWorker(workerId);

  if (!worker) {
    return (
      <Flex
        height="100%"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Spinner size="xl" color="blue.500" />
      </Flex>
    );
  }

  return (
    <>
      <Header>
        <Box display="flex" alignItems="center">
          <Link to="/dashboard/workers">
            <Text variant="16-med" color="gray.300">
              Workers
            </Text>
          </Link>
          <Box width="8px" />
          <ChevronRightIcon fontSize="18px" color="gray.300" />
          <Box width="8px" />
          <Text variant="16-med">{worker.name}</Text>
        </Box>
        <WorkerActionButtons workerId={worker._id} />
      </Header>
      <Content>
        {/* {!isCurrentVersion ? (
          <Box display="flex" alignItems="center" marginTop="32px">
            <Card
              width="100%"
              backgroundColor="gray.1000"
              padding="24px"
              borderColor="gray.800"
              borderWidth="1px"
            >
              <Flex alignItems="flex-start" justifyContent="space-between">
                <Box alignItems="center">
                  <Flex alignItems="center">
                    <FiAlertTriangle
                      color={theme.colors.yellow[500]}
                      fontSize="20px"
                    />
                    <Text variant="16-med" color="white" marginLeft="8px">
                      Upgrade Required
                    </Text>
                  </Flex>
                  <Text variant="14-reg" color="gray.300" marginTop="4px">
                    This worker is running an old version of the Kuzco client.
                    Please download and install the newest version to continue
                    participating on the Kuzco network. While running an out of
                    date worker may work, it is safest to upgrade as soon as
                    possible.
                  </Text>
                  <Box height="16px" />
                  <Link to={DOWNLOAD_LINK} target="_blank">
                    <Button variant="primary" size="sm">
                      <FiArrowUpRight fontSize="18px" />
                      <Box width="8px" />
                      View Downloads
                    </Button>
                  </Link>
                </Box>
              </Flex>
            </Card>
          </Box>
        ) : null} */}
        <Box display="flex" alignItems="center" marginTop="32px">
          {/* <Image
            alt="Bot Logo"
            src={"/images/context-mark.svg"}
            width="48"
            height="48"
            style={{
              maxWidth: "48px",
              height: "48px",
              borderRadius: "6px",
            }}
          />
          <Box width="12px" /> */}
          <Box>
            <Title>{worker.name}</Title>
            <Text variant="14-reg" color="gray.400">
              {/* {0}&nbsp;documents */}
            </Text>
          </Box>
        </Box>
        <Box height="24px" />
        <Box
          display="flex"
          width="100%"
          borderBottom="1px solid"
          borderColor="gray.800"
          paddingBottom="8px"
        >
          {isMobile ? (
            <>
              <Box display="flex" width="min-content">
                <SubNavigationButton
                  label="Main"
                  link={`/dashboard/workers/details/${worker._id}`}
                />
                <SubNavigationButton
                  label="Instances"
                  link={`/dashboard/workers/instances/${worker._id}`}
                />
                <SubNavigationButton
                  label="Logs"
                  link={`/dashboard/workers/logs/${worker._id}`}
                />
                <SubNavigationButton
                  label="Gens"
                  link={`/dashboard/workers/generations/${worker._id}`}
                />
                <SubNavigationButton
                  label="Benchmarks"
                  link={`/dashboard/workers/benchmarks/${worker._id}`}
                />
                <SubNavigationButton
                  label="Settings"
                  link={`/dashboard/workers/settings/${worker._id}`}
                />
              </Box>
            </>
          ) : (
            <>
              <Box display="flex" width="min-content">
                <SubNavigationButton
                  label="Overview"
                  link={`/dashboard/workers/details/${worker._id}`}
                />
                <SubNavigationButton
                  label="Instances"
                  link={`/dashboard/workers/instances/${worker._id}`}
                />
                <SubNavigationButton
                  label="Logs"
                  link={`/dashboard/workers/logs/${worker._id}`}
                />
                <SubNavigationButton
                  label="Generations"
                  link={`/dashboard/workers/generations/${worker._id}`}
                />
                <SubNavigationButton
                  label="Benchmarks"
                  link={`/dashboard/workers/benchmarks/${worker._id}`}
                />
                <SubNavigationButton
                  label="Settings"
                  link={`/dashboard/workers/settings/${worker._id}`}
                />
              </Box>
            </>
          )}
        </Box>
      </Content>
      <Content>
        <Outlet />
        <Box height="200px" />
      </Content>
    </>
  );
};

export default function WorkerLayout() {
  return (
    <Suspense fallback={<WorkerDetailsPageSuspense />}>
      <WorkerLayoutComponent />
    </Suspense>
  );
}
