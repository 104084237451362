import React, { useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

import Linux from "~/assets/linux.svg";
import MacOS from "~/assets/mac-os.svg";
import Windows from "~/assets/windows.svg";
import { withSuspense } from "~/ui/hoc/withSuspense";
import MarketingLayout from "~/ui/layouts/Marketing.layout";
import InstallCliCodeSnippet from "../components/snippets/InstallCliCodeSnippet";

const RELAY_URL = import.meta.env.VITE_API_URL ?? "https://relay.kuzco.xyz";

type VersionData = {
  cli: string;
  desktop: string;
};

const detectOS = () => {
  const userAgent = window.navigator.userAgent;
  if (userAgent.indexOf("Win") !== -1) return "Windows";
  if (userAgent.indexOf("Mac") !== -1) return "macOS";
  if (userAgent.indexOf("Linux") !== -1) return "Linux";
  return "macOS"; // Default to macOS if OS is not detected
};

const DownloadPage = () => {
  const [selectedName, setSelectedName] = React.useState("macOS");
  const [versions, setVersions] = React.useState<VersionData | null>(null);

  useEffect(() => {
    const os = detectOS();
    setSelectedName(os);

    fetch(`${RELAY_URL}/public/versions`)
      .then((response) => response.json())
      .then((data: VersionData) => {
        setVersions(data);
      })
      .catch((error) => console.error("Error fetching versions:", error));
  }, []);

  const downloads = [
    {
      name: "macOS",
      image: MacOS,
      Download: () => {
        return (
          <>
            <Flex flexDir={"column"} alignItems={"center"} gridGap={"24px"}>
              <VStack alignItems={"center"}>
                <Link to={`${RELAY_URL}/download-console/mac`} target="_blank">
                  <Button
                    width="254px"
                    variant="primary"
                    backgroundColor="green.500"
                    color="white"
                    size="lg"
                    _hover={{ bg: "green.400" }}
                    _focus={{ bg: "green.600" }}
                  >
                    Download for macOS &#8595;
                  </Button>
                </Link>
                <Text variant="12-reg" color="gray.500" textAlign="center">
                  Requires Apple M Series Chip
                  {versions && (
                    <>
                      <br />v{versions.desktop}
                    </>
                  )}
                </Text>
              </VStack>

              <Divider />

              <VStack alignItems={"center"}>
                <Box maxW="500px">
                  <InstallCliCodeSnippet />
                </Box>
                <Text variant="12-reg" color="gray.500" textAlign="center">
                  Kuzco CLI for macOS
                  {versions && (
                    <>
                      <br />v{versions.cli}
                    </>
                  )}
                </Text>
              </VStack>
            </Flex>
          </>
        );
      },
    },
    {
      name: "Linux",
      image: Linux,
      Download: () => {
        return (
          <>
            <Box maxW="500px">
              <InstallCliCodeSnippet />
            </Box>
            <Box height="16px" />
            <Text variant="12-reg" color="gray.500" textAlign="center">
              Linux Only. Most architectures supported
              {versions && (
                <>
                  <br />v{versions.cli}
                </>
              )}
            </Text>
          </>
        );
      },
    },
    {
      name: "Windows",
      image: Windows,
      Download: () => {
        return (
          <>
            <Link to={`${RELAY_URL}/download-console/windows`} target="_blank">
              <Button
                width="254px"
                variant="primary"
                backgroundColor="green.500"
                color="white"
                size="lg"
                _hover={{ bg: "green.400" }}
                _focus={{ bg: "green.600" }}
              >
                Download for Windows &#8595;
              </Button>
            </Link>
            <Box height="16px" />
            <Text variant="12-reg" color="gray.500" textAlign="center">
              Requires Windows 10 or later
              {versions && (
                <>
                  <br />v{versions.desktop}
                </>
              )}
            </Text>
          </>
        );
      },
    },
  ];

  const selected = downloads.find((item) => item.name === selectedName);

  return (
    <MarketingLayout>
      <Flex
        height="100%"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Flex width="100%">
          <Flex
            flex="1"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Flex
              flex="1"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              width={{ base: "100%", md: "648px" }}
              padding={{ base: "0 24px", md: "0" }}
            >
              <Text
                fontSize={{ base: "24px", md: "32px" }}
                fontWeight="500"
                lineHeight={{ base: "28px", md: "32px" }}
                color="white"
                align="center"
              >
                Download Kuzco
              </Text>
              <Box height="48px" />
              <Grid
                templateColumns={{
                  base: "repeat(1, 1fr)",
                  md: "repeat(3, 1fr)",
                }}
                gap={4}
                width="360px"
              >
                {downloads.map((item, index) => {
                  return (
                    <GridItem key={index}>
                      <Box
                        bg={
                          selectedName === item.name
                            ? "gray.800"
                            : "transparent"
                        }
                        onClick={() => setSelectedName(item.name)}
                        borderRadius="8px"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        padding="12px 0px"
                        _hover={{
                          cursor: "pointer",
                          bg: "gray.800",
                        }}
                        transition="all 0.1s"
                      >
                        <Image
                          src={item.image}
                          width="32px"
                          height="32px"
                          marginBottom="4px"
                        />
                        <Text variant="14-reg" color="gray.200" align="center">
                          {item.name}
                        </Text>
                      </Box>
                    </GridItem>
                  );
                })}
              </Grid>
              <Box height="48px" />
              {selected ? <selected.Download /> : null}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Box height={{ base: "32px", md: "64px" }} />
    </MarketingLayout>
  );
};

export default withSuspense(DownloadPage);
