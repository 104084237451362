import { Grid, GridItem, Skeleton } from "@chakra-ui/react";

import { arrays } from "@kuzco/utils";

export default function WorkerListSuspense() {
  return (
    <Grid
      templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
      gap={6}
    >
      {arrays.range(3).map((i) => {
        return (
          <GridItem rowSpan={2} key={i}>
            <Skeleton height="247px" width="100%" borderRadius="lg" />
          </GridItem>
        );
      })}
    </Grid>
  );
}
