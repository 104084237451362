import { Box } from "@chakra-ui/react";

import { Content } from "../layouts/Page.layout";
import PageLayoutSuspense from "./PageLayout.suspense";
import WorkerListSuspense from "./WorkerList.suspense";

export default function WorkerLayoutSuspense() {
  return (
    <>
      <PageLayoutSuspense pageTitle="Workers">
        <Content>
          <Box height="24px" />
          <WorkerListSuspense />
        </Content>
      </PageLayoutSuspense>
    </>
  );
}
