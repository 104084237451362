import React from "react";
import { Box, Text } from "@chakra-ui/react";

import type { WorkerWithInstances } from "@kuzco/models";
import { workerStatus } from "@kuzco/models";

import Card from "~/ui/elements/Card";
import useWorkerLogs from "../../hooks/useWorkerLogs.hook";

type WorkerLogsProps = {
  worker: WorkerWithInstances;
};

const WorkerLogs: React.FC<WorkerLogsProps> = ({ worker }) => {
  const { logs } = useWorkerLogs(worker._id, "*");
  const logContainerRef = React.useRef<HTMLDivElement>(null);

  return (
    <Card height="100%" padding="0px" backgroundColor="gray.1000">
      <Card
        padding="16px"
        borderColor="gray.800"
        border="0px"
        borderBottom="1px"
        borderRadius="0px"
        borderBottomColor="gray.800"
      >
        <Text variant="16-semi" color="gray.400">
          Worker Logs
        </Text>
      </Card>

      {/*
       * Pure CSS solution for log scrolling found at
       * https://stackoverflow.com/a/60546366
       */}
      <Box
        ref={logContainerRef}
        padding="16px"
        height="400px"
        overflow="auto"
        style={{
          overscrollBehaviorY: "contain",
          scrollSnapType: "y proximity",
        }}
      >
        {(() => {
          if (workerStatus(worker) === "Offline") {
            return (
              <Text
                as="span"
                fontFamily="monospace"
                fontSize="14px"
                color="red.400"
              >
                This worker is not running.
              </Text>
            );
          }

          if (logs.length === 0) {
            return (
              <Text
                as="span"
                fontFamily="monospace"
                fontSize="14px"
                color="green.600"
              >
                Starting...
              </Text>
            );
          }

          return logs.map((log, i) => {
            return (
              <Text
                fontFamily="monospace"
                variant="14-reg"
                key={i}
                style={i === logs.length - 1 ? { scrollSnapAlign: "end" } : {}}
              >
                <Text as="span" fontSize="inherit" color="green.600">
                  {log.header}
                </Text>
                &nbsp;
                <Text as="span" fontSize="inherit" color="gray.400">
                  {log.timestamp}
                </Text>
                &nbsp;
                <Text as="span" fontSize="inherit" color="gray.400">
                  {log.level}
                </Text>
                &nbsp;
                <Text as="span" fontSize="inherit" color="gray.400">
                  {log.message.join(" ")}
                </Text>
              </Text>
            );
          });
        })()}
      </Box>
    </Card>
  );
};

export default WorkerLogs;
