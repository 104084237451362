import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

type Disable2FAConfirmationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>;
};

const Disable2FAConfirmationModal: React.FC<
  Disable2FAConfirmationModalProps
> = ({ isOpen, onClose, onConfirm }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent backgroundColor="gray.1000">
        <ModalHeader>Disable Two-Factor Authentication</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            Are you sure you want to disable two-factor authentication? This
            will make your account less secure.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button variant="secondary" onClick={onConfirm}>
            Disable 2FA
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Disable2FAConfirmationModal;
