import { z } from "zod";

import { LogLevel } from "../log/LogManager";

export const WorkerConfigSchema = z.object({
  /**
   * The log level a worker should use.
   */
  logLevel: z.nativeEnum(LogLevel).default(LogLevel.Log),

  autoUpdatesEnabled: z.boolean().default(false),

  threadPool: z
    .object({
      /**
       * The number of milliseconds to wait before recreating the thread pool in the event that its destroyed
       */
      waitBeforeRecreatingThreadPoolInMs: z.number().default(5000),

      /**
       * How many messages a worker should fetch at a time from the queue in a single thread.
       *
       * We want to keep this at 1 to avoid a local queue of messages
       */
      fetchSize: z.number().default(1),
    })
    // we do this because zod requires a default value for nested objects
    // (it smells, I know, oh well)
    .default({}),

  heartbeat: z
    .object({
      /**
       * The number of milliseconds to wait between heartbeats.
       */
      intervalInMs: z.number().default(3_000),

      /**
       * The number of milliseconds to wait before considering a worker as dead.
       */
      timeoutInMs: z.number().default(10_000),
    })
    .default({}),

  initialization: z
    .object({
      /**
       * The number of milliseconds to wait for a response to the `instance.initialize` message.
       */
      timeoutInMs: z.number().default(10_000),
    })
    .default({}),

  ready: z
    .object({
      /**
       * The number of milliseconds to wait for a response to the `instance.ready` message.
       */
      timeoutInMs: z.number().default(10_000),
    })
    .default({}),

  shutdown: z
    .object({
      /**
       * The number of milliseconds to wait for inflight inference requests to complete before shutting down.
       */
      inflightInferenceCheckIntervalInMs: z.number().default(500),

      /**
       * The maximum number of milliseconds to wait for the worker to shutdown before forcefully shutting down.
       */
      maximumWaitBeforeShutdownInMs: z.number().default(60_000),
    })
    .default({}),

  inference: z
    .object({
      fast: z
        .object({
          /**
           * The number of milliseconds to wait between messages sent to Relay.
           */
          timeoutBetweenMessagesInMs: z.number().default(10_000),

          /**
           * The number of milliseconds to wait for a response to the `PING` message.
           */
          pingTimeoutInMs: z.number().default(2_000),

          /**
           * How often to send the `PING` message to Relay in milliseconds.
           */
          pingIntervalInMs: z.number().default(1_000),

          pingTimeoutCheckIntervalInMs: z.number().default(1000),

          /**
           * The maximum number of consecutive ping failures before the inference is aborted.
           */
          maxConsecutivePingFailuresBeforeShutdown: z.number().default(3),
        })
        .default({}),

      timeoutInMsDefault: z.number().default(2 * 60 * 1000),
    })
    .default({}),
});

export type WorkerConfig = z.infer<typeof WorkerConfigSchema>;
