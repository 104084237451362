import type { BoxProps } from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";
import { Box, Text, useToast } from "@chakra-ui/react";
import { useCopyToClipboard } from "usehooks-ts";

const CodeSnippet = ({ cmd, ...rest }: { cmd: string } & BoxProps) => {
  const toast = useToast();
  const [_, copy] = useCopyToClipboard();

  return (
    <Box
      position="relative"
      padding="12px 40px 12px 16px"
      borderRadius="8px"
      border="1px solid"
      borderColor="gray.700"
      backgroundColor="gray.900"
      width="100%"
      {...rest}
    >
      <Text variant="12-reg" fontFamily="monospace" color="green.400">
        $&nbsp;{cmd}
      </Text>
      <Box
        position="absolute"
        right="12px"
        top="6px"
        onClick={() => {
          void copy(cmd);
          toast({
            title: "Code Copied.",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }}
        _hover={{ cursor: "pointer" }}
      >
        <CopyIcon fontSize="16px" />
      </Box>
    </Box>
  );
};

export default CodeSnippet;
