import React from "react";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Spinner,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { useCounter, useInterval } from "usehooks-ts";

import type { Instance } from "@kuzco/models";
import { InstanceStatusSchema } from "@kuzco/models";
import { time } from "@kuzco/utils";

import useIsMobile from "~/ui/hooks/useIsMobile.hook";
import { useVersionStatus, VersionStatus } from "../../hooks/useVersion.hook";

type InstanceInfoProps = {
  instance: Instance;
  columns?: number;
  cards?: boolean;
};

const InstanceInfo: React.FC<InstanceInfoProps> = ({
  instance,
  columns = 2,
}) => {
  const versionCheck = useVersionStatus(instance.info.version ?? "");
  const isCurrentVersion = versionCheck === VersionStatus.Current;
  const { increment } = useCounter(0);

  const isMobile = useIsMobile();

  useInterval(() => {
    increment();
  }, 1000);

  const color = (() => {
    switch (instance.status) {
      case InstanceStatusSchema.Enum.Running:
        return "green.500";
      case InstanceStatusSchema.Enum.Initializing:
        return "yellow.500";
      case InstanceStatusSchema.Enum.Paused:
        return "yellow.500";
      case InstanceStatusSchema.Enum.Expired:
        return "red.500";

      default:
        return "gray.500";
    }
  })();

  const {
    chip: GPU,
    cores: _,
    vram: GPUVRAM,
  } = React.useMemo(() => {
    if (instance.info.macsmi) {
      return {
        chip: instance.info.macsmi.chip,
        cores: instance.info.macsmi.gpu_cores,
        vram: instance.info.totalMemoryBytes
          ? `${Math.fround(instance.info.totalMemoryBytes / 1024 ** 3).toFixed(
              0,
            )}GB`
          : "--",
      };
    }

    if (instance.info.nvidiasmi) {
      return {
        chip: instance.info.nvidiasmi.gpu
          .map((gpu) => gpu.product_name)
          .join("\n"),
        cores: "Unknown",
        vram: instance.info.nvidiasmi.gpu
          .map((gpu) => {
            const vramMiB = parseFloat(
              gpu.fb_memory_usage[0]?.total?.[0] ?? "0",
            );
            if (vramMiB === 0) {
              return "Unknown";
            }
            const vramGB = (vramMiB / 1024).toFixed(0);
            return `${vramGB}GB`;
          })
          .join("\n"),
      };
    }

    if (instance.info.amdsmi) {
      return {
        chip: instance.info.amdsmi.gpuModel,
        cores: "Unknown",
        vram: instance.info.amdsmi.gpuVram ?? "Unknown",
      };
    }

    return {
      chip: "Unknown",
      cores: "Unknown",
      vram: "Unknown",
    };
  }, [instance.info]);

  const fields = [
    {
      key: "Status",
      value: (
        <Box display="flex" alignItems="center">
          <Box
            backgroundColor={color}
            height="8px"
            width="8px"
            borderRadius="50%"
          />
          <Box width="8px" />
          {instance.status}
          {instance.status === InstanceStatusSchema.Enum.Initializing ? (
            <Box marginLeft="8px" height="18px">
              <Spinner size="xs" color="yellow.500" />
            </Box>
          ) : null}
        </Box>
      ),
      colSpan: 1,
    },
    {
      key: "Run Duration",
      value: time.Duration.fromMillis(
        time.now() - instance.createdAt.getTime(),
      ).toFormat("hh:mm:ss"),
      colSpan: 1,
    },
    {
      key: "Created",
      value: time.format(
        instance.createdAt.getTime(),
        "f",
        time.currentBrowserTimezone(),
      ),
      colSpan: 1,
    },
    {
      key: "Version",
      value: (() => {
        return (
          <Tooltip
            label={
              isCurrentVersion
                ? "This instance is running the current version."
                : "This instance is running an old version. Please visit the download page to upgrade to the latest version."
            }
          >
            <Box
              color={!isCurrentVersion ? "yellow.500" : "white"}
              whiteSpace="nowrap"
            >
              {instance.info.version ?? "--"}
            </Box>
          </Tooltip>
        );
      })(),
    },

    {
      key: "IP Address",
      value: instance.info.ipAddress.slice(0, 17),
      colSpan: 2,
    },
    {
      key: "GPU",
      value: GPU,
      colSpan: 1,
    },
    {
      key: "GPU VRAM",
      value: `${GPUVRAM}`,
      colSpan: 1,
    },
    {
      key: "CPU Cores",
      value: instance.info.cpus ? `${instance.info.cpus} cores` : "--",
      colSpan: 1,
    },
    {
      key: "Memory GB",
      value: instance.info.totalMemoryBytes
        ? `${Math.fround(instance.info.totalMemoryBytes / 1024 ** 3).toFixed(
            0,
          )}GB`
        : "--",
      colSpan: 1,
    },
    {
      key: "Instance ID",
      value: instance._id,
      colSpan: 2,
    },
  ].filter((field): field is { key: string; value: string; colSpan: number } =>
    Boolean(field),
  );

  return (
    <Grid
      templateColumns={`repeat(${isMobile ? 1 : columns}, 1fr)`}
      gap={4}
      rowGap={4}
    >
      {fields.map(({ key, value, colSpan = 1 }, index: number) => {
        return (
          <GridItem key={index} colSpan={colSpan}>
            <Flex direction="column">
              <Text variant="12-bold" color="gray.500" as="span">
                {key}
              </Text>
              <Flex align="center">
                <Text variant="14-bold" color="white">
                  {value}
                </Text>
              </Flex>
            </Flex>
          </GridItem>
        );
      })}
    </Grid>
  );
};

export default InstanceInfo;
