import React from "react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { Box, Button, Divider, Flex, Image, Text } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { workerStatus, WorkerStatusSchema } from "@kuzco/models";

import KuzcoLogo from "~/assets/kuzco-white.svg";
import { CLI_INIT_COMMAND, CLI_INTERACTIVE_SETUP_SCRIPT } from "~/lib/const";
import { ConfirmModalTypes } from "~/ui/components/modal/Confirm.modal";
import { ModalTypes } from "~/ui/components/modal/Modal";
import { withSuspense } from "~/ui/hoc/withSuspense";
import useModal from "~/ui/hooks/useModal.hook";
import useWorker from "~/ui/hooks/useWorker.hook";
import DashboardLayout from "~/ui/layouts/Dashboard.layout";
import { Content, Header, Title } from "~/ui/layouts/Page.layout";
import CodeSnippet from "../CLICommand";

const InstallCLI = () => {
  const { refreshWorkerList } = useWorker();
  const { push, pop } = useModal();
  const navigate = useNavigate();

  const [isVerified, setIsVerified] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const verify = useMutation({
    mutationKey: ["verifyCLIInstallation"],
    mutationFn: async () => {
      try {
        const { workers } = await refreshWorkerList.mutateAsync();

        if (!workers || workers.length === 0) {
          throw new Error("No workers found. Please try again.");
        }

        const onlineWorker = workers.find(
          (w) => workerStatus(w) === WorkerStatusSchema.Enum.Online,
        );

        if (!onlineWorker) {
          throw new Error(
            "Worker exists but is not online. Please run kuzco worker start",
          );
        }

        setIsVerified(true);
      } catch (e) {
        console.error(e);
        if (e instanceof Error) {
          setError(e.message);
        }
      }
    },
  });

  return (
    <DashboardLayout hideNavigation>
      <>
        <Header>
          <Title>
            <Flex alignItems="center">
              <Flex alignItems="center">
                <Image
                  alt="Kuzco Logo"
                  src={KuzcoLogo}
                  width="24px"
                  height="24px"
                  maxWidth="32px"
                  borderRadius="6px"
                />
                <Box width="12px" />
                <Title>Kuzco Console</Title>
              </Flex>
            </Flex>
          </Title>
          <Button
            variant="secondary"
            size="sm"
            onClick={() => {
              push({
                type: ModalTypes.Confirm,
                props: {
                  title: `Cancel`,
                  type: ConfirmModalTypes.Warning,
                  message: `Kuzco may not work properly if you skip this step. Are you sure you want to continue?`,
                  confirm: () => {
                    navigate("/welcome");
                    pop();
                  },
                  cancel: () => {
                    pop();
                  },
                },
              });
            }}
          >
            Skip
          </Button>
        </Header>
        <Content>
          <Box display="flex" paddingTop="40px" justifyContent="center">
            <Box width="600px">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Text variant="24-reg" color="white">
                    Install Kuzco CLI
                  </Text>
                  <Box height="4px" />
                  <Text variant="16-reg" color="gray.400">
                    The Kuzco CLI is a command line tool that allows you to
                    create and manage worker nodes on the Kuzco network. Get
                    started below.
                  </Text>
                </Box>
              </Box>
              <Box margin="24px 0px 24px">
                <Divider height="1px" backgroundColor="gray.800" />
              </Box>
              <Text variant="18-med" marginBottom="4px">
                1. Run the Installer
              </Text>
              <Box height="4px" />
              <CodeSnippet cmd={CLI_INTERACTIVE_SETUP_SCRIPT} width="100%" />
              <Box height="8px" />
              <Text variant="12-reg" color="gray.500">
                Linux Only. Most architectures supported.
              </Text>
              <Box height="16px" />
              <Text variant="18-med" marginBottom="4px">
                2. Initalize Kuzco
              </Text>
              <Box height="4px" />
              <CodeSnippet cmd={CLI_INIT_COMMAND} width="100%" />
              <Box height="8px" />
              <Text variant="12-reg" color="gray.500">
                May require sudo permissions.
              </Text>
              <Box height="16px" />
              <Text variant="18-med" marginBottom="4px">
                3. Create a Worker
              </Text>
              <Text variant="16-reg" marginBottom="16px" color="gray.400">
                After running the above command, you will be guided through the
                process of creating a worker.{" "}
                <b>
                  At the end of the process, make sure to select 'Yes' when
                  asked if you want to start the worker.
                </b>
              </Text>
              <Box height="16px" />
              <Flex justifyContent="flex-start">
                {(() => {
                  if (isVerified) {
                    return (
                      <Flex alignItems="center">
                        <Flex justifyContent="flex-end">
                          <Button
                            variant="primary"
                            size="lg"
                            onClick={() => navigate("/welcome")}
                            margin="0"
                          >
                            Join Network
                          </Button>
                        </Flex>
                        <Box width="16px" />
                        <Text variant="18-med">Kuzco is running!</Text>
                        <Box width="8px" />
                        <CheckCircleIcon color="green.500" fontSize="20px" />
                      </Flex>
                    );
                  }
                  if (error) {
                    return (
                      <Flex alignItems="center">
                        <Flex justifyContent="flex-end">
                          <Button
                            variant="primary"
                            size="lg"
                            isLoading={verify.isLoading}
                            onClick={async () => {
                              setError(null);
                              setIsVerified(false);
                              await verify.mutateAsync();
                            }}
                          >
                            Try Again
                          </Button>
                        </Flex>
                        <Box width="16px" />
                        <Text variant="18-med" color="red.500">
                          {error}
                        </Text>
                      </Flex>
                    );
                  }

                  return (
                    <Flex alignItems="center">
                      <Flex justifyContent="flex-end">
                        <Button
                          variant="primary"
                          size="lg"
                          isLoading={verify.isLoading}
                          onClick={async () => {
                            await verify.mutateAsync();
                          }}
                        >
                          Verify Installation
                        </Button>
                      </Flex>
                    </Flex>
                  );
                })()}
              </Flex>
              <Box height="300px" />
            </Box>
          </Box>
        </Content>
      </>
    </DashboardLayout>
  );
};

export default withSuspense(InstallCLI);
