import {
  CLI_INTERACTIVE_SETUP_SCRIPT,
  VITE_APP_BASE_URL,
} from "../../lib/const";
import CodeSnippet from "../../ui/components/CLICommand";

export default function InstallCliCodeSnippet() {
  return (
    <CodeSnippet
      cmd={CLI_INTERACTIVE_SETUP_SCRIPT.replace(
        "[KUZCO_BASE_URL]",
        VITE_APP_BASE_URL,
      )}
      width="100%"
    />
  );
}
