import InstallCLI from "~/ui/components/onboarding/InstallCLI";
import VerifyTauriInstallation from "~/ui/components/onboarding/VerifyTauriInstallation";
import { withSuspense } from "~/ui/hoc/withSuspense";
import { useAppConfig } from "~/ui/hooks/useAppConfig.hook";

const VerifyInstallationPage = () => {
  const { isTauri } = useAppConfig();

  return isTauri ? <VerifyTauriInstallation /> : <InstallCLI />;
};

export default withSuspense(VerifyInstallationPage);
