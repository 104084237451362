import type { BoxProps } from "@chakra-ui/react";
import React from "react";
import { Box, Spinner, Text } from "@chakra-ui/react";

type CardProps = {
  children?: React.ReactNode;
  isSelected?: boolean;
  title?: string;
  isLoading?: boolean;
} & BoxProps;

const Card: React.FC<CardProps> = ({
  children,
  title,
  isSelected,
  isLoading,
  ...rest
}) => {
  return (
    <Box
      position="relative"
      backgroundColor={isSelected ? "gray.900" : "gray.1100"}
      _hover={
        rest.onClick
          ? {
              background: isSelected ? "gray.900" : "gray.1000",
              borderColor: isSelected ? "blue.500" : "gray.700",
              cursor: "pointer",
            }
          : {}
      }
      borderRadius="8px"
      border="1px solid"
      borderColor={isSelected ? "blue.500" : "gray.800"}
      padding="24px"
      overflow="hidden"
      {...rest}
    >
      {isLoading ? (
        <Box
          position="absolute" // wtf, can't get this box to be centered without this
          height="calc(100% - 48px)" // wtf, can't get this box to be centered without this
          width="calc(100% - 48px)" // wtf, can't get this box to be centered without this
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner color="blue.500" size="xl" />
        </Box>
      ) : (
        <Box display="flex" flexDirection="column">
          {title ? (
            <>
              <Text variant="16-semi">{title}</Text>
              <Box height="8px" />
              <Box height="1px" backgroundColor="gray.800" width="100%" />
              <Box height="24px" />
            </>
          ) : null}
          {children}
        </Box>
      )}
    </Box>
  );
};

export default Card;
