import React from "react";
import { Text } from "@chakra-ui/react";

import type { RouterOutputs } from "@kuzco/api";
import { BarChart } from "@kuzco/metrics";
import { numbers } from "@kuzco/utils";

import Card from "~/ui/elements/Card";
import DisabledChart from "./Disabled.chart";

type AverageTPSMetric =
  // @ts-expect-error - need better types here.
  RouterOutputs["metrics"]["tokensDelivered"]["last30Days"]["earnings"];

type TokenEarningsChartProps = {
  title?: string;
  metric?: AverageTPSMetric;
  isDisabled?: boolean;
};

const TokenEarningsChart: React.FC<TokenEarningsChartProps> = ({
  title,
  metric,
  isDisabled,
}) => {
  if (isDisabled) {
    return <DisabledChart />;
  }

  if (!metric) {
    return <Card isLoading minHeight="386px" />;
  }

  return (
    <Card width="100%">
      <Text variant="18-semi" color="gray.400">
        {title ?? "Earning Statistics"}
      </Text>
      <BarChart
        {...metric}
        colors={["green", "blue"]}
        // showLegend={false}
        valueFormatter={(number) => numbers.shorten(number)}
      />
    </Card>
  );
};

export default TokenEarningsChart;
