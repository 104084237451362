import { z } from "zod";

export const GpuTypeSchema = z.enum(["NVIDIA", "AMD", "Apple", "Unknown"]);

export type GpuType = z.infer<typeof GpuTypeSchema>;

export const GpuSchema = z.object({
  product_name: z.array(z.string()),
  product_brand: z.array(z.string()),
  product_architecture: z.array(z.string()),
  display_mode: z.array(z.string()),
  display_active: z.array(z.string()),
  uuid: z.array(z.string()),
  vbios_version: z.array(z.string()),
  multigpu_board: z.array(z.string()),
  board_id: z.array(z.string()),
  pci: z.array(
    z.object({
      pci_bus: z.array(z.string()),
      pci_device: z.array(z.string()),
      pci_domain: z.array(z.string()),
      pci_device_id: z.array(z.string()),
      pci_bus_id: z.array(z.string()),
      pci_sub_system_id: z.array(z.string()),
      pci_gpu_link_info: z.array(
        z.object({
          pcie_gen: z.array(
            z.object({
              max_link_gen: z.array(z.string()),
              current_link_gen: z.array(z.string()),
            }),
          ),
          link_widths: z.array(
            z.object({
              max_link_width: z.array(z.string()),
              current_link_width: z.array(z.string()),
            }),
          ),
        }),
      ),
      tx_util: z.array(z.string()),
      rx_util: z.array(z.string()),
    }),
  ),
  fan_speed: z.array(z.string()),
  performance_state: z.array(z.string()),
  clocks_event_reasons: z.array(
    z.object({
      clocks_event_reason_gpu_idle: z.array(z.string()),
      clocks_event_reason_applications_clocks_setting: z.array(z.string()),
      clocks_event_reason_sw_power_cap: z.array(z.string()),
      clocks_event_reason_hw_slowdown: z.array(z.string()),
      clocks_event_reason_hw_thermal_slowdown: z.array(z.string()),
      clocks_event_reason_hw_power_brake_slowdown: z.array(z.string()),
      clocks_event_reason_sync_boost: z.array(z.string()),
      clocks_event_reason_sw_thermal_slowdown: z.array(z.string()),
      clocks_event_reason_display_clocks_setting: z.array(z.string()),
    }),
  ),
  fb_memory_usage: z.array(
    z.object({
      total: z.array(z.string()),
      reserved: z.array(z.string()),
      used: z.array(z.string()),
      free: z.array(z.string()),
    }),
  ),
  bar1_memory_usage: z.array(
    z.object({
      total: z.array(z.string()),
      used: z.array(z.string()),
      free: z.array(z.string()),
    }),
  ),
  compute_mode: z.array(z.string()),
  utilization: z.array(
    z.object({
      gpu_util: z.array(z.string()),
      memory_util: z.array(z.string()),
      encoder_util: z.array(z.string()).optional(),
      decoder_util: z.array(z.string()).optional(),
    }),
  ),
  encoder_stats: z.array(z.object({})).optional(),
  fbc_stats: z.array(z.object({})).optional(),
  ecc_mode: z.array(z.object({})).optional(),
  temperature: z.array(
    z.object({
      gpu_temp: z.array(z.string()),
      gpu_temp_max_threshold: z.array(z.string()).optional(),
      gpu_temp_slow_threshold: z.array(z.string()).optional(),
      gpu_temp_max_gpu_threshold: z.array(z.string()).optional(),
      gpu_target_temperature: z.array(z.string()).optional(),
      memory_temp: z.array(z.string()).optional(),
      gpu_temp_max_mem_threshold: z.array(z.string()).optional(),
    }),
  ),
  supported_gpu_target_temp: z.array(
    z.object({
      gpu_target_temp_min: z.array(z.string()).optional(),
      gpu_target_temp_max: z.array(z.string()).optional(),
    }),
  ),
  gpu_power_readings: z.array(
    z.object({
      power_state: z.array(z.string()),
      power_draw: z.array(z.string()),
      current_power_limit: z.array(z.string()),
      default_power_limit: z.array(z.string()).optional(),
      requested_power_limit: z.array(z.string()).optional(),
      min_power_limit: z.array(z.string()).optional(),
      max_power_limit: z.array(z.string()).optional(),
    }),
  ),
  clocks: z.array(
    z.object({
      graphics_clock: z.array(z.string()),
      sm_clock: z.array(z.string()),
      mem_clock: z.array(z.string()),
      video_clock: z.array(z.string()),
    }),
  ),
  max_clocks: z.array(
    z.object({
      graphics_clock: z.array(z.string()).optional(),
      sm_clock: z.array(z.string()).optional(),
      mem_clock: z.array(z.string()).optional(),
      video_clock: z.array(z.string()).optional(),
    }),
  ),
  voltage: z.array(
    z.object({
      graphics_volt: z.array(z.string()),
    }),
  ),
});

export const NvidiaSmiSchema = z.object({
  timestamp: z.array(z.string()),
  driver_version: z.array(z.string()),
  cuda_version: z.array(z.string()),
  attached_gpus: z.array(z.string()),
  gpu: z.array(GpuSchema),
});

export const AmdGpuInfoSchema = z.object({
  gpuModel: z.string(),
  gpuDriverVersion: z.string().nullable(),
  gpuVram: z.string().nullish(),
});

export const MacSmiSchema = z.object({
  chip: z.string(),
  gpu_cores: z.string(),
  vram: z.number().nullish(),
});

export const amdGpuVramMap: Record<string, string> = {
  // AMD 7000 series
  "AMD Radeon RX 7900 XTX": "24GB",
  "AMD Radeon RX 7900 XT": "20GB",
  "AMD Radeon RX 7900 GRE": "16GB",
  "AMD Radeon RX 7800 XT": "16GB",
  "AMD Radeon RX 7700 XT": "12GB",
  "AMD Radeon RX 7600 XT": "16GB",
  "AMD Radeon RX 7600": "8GB",
  // AMD 6000 series
  "AMD Radeon RX 6950 XT": "16GB",
  "AMD Radeon RX 6900 XT": "16GB",
  "AMD Radeon RX 6800 XT Midnight Black": "16GB",
  "AMD Radeon RX 6800 XT": "16GB",
  "AMD Radeon RX 6800": "16GB",
  "AMD Radeon RX 6750 XT": "12GB",
  "AMD Radeon RX 6700 XT": "12GB",
  "AMD Radeon RX 6700": "10GB",
  "AMD Radeon RX 6650 XT": "8GB",
  "AMD Radeon RX 6600 XT": "8GB",
  "AMD Radeon RX 6600": "8GB",
  "AMD Radeon RX 6500 XT": "8GB",
  // AMD 5000 series
  "AMD Radeon RX 5700 XT": "8GB",
  "AMD Radeon RX 5700": "8GB",
  "AMD Radeon RX 5600 XT": "6GB",
  "AMD Radeon RX 5600": "6GB",
  // AMD Pro Series
  "AMD Radeon PRO W7900 Dual Slot": "48GB",
  "AMD Radeon PRO W7900": "48GB",
  "AMD Radeon PRO W7800": "32GB",
  "AMD Radeon PRO W7700": "16GB",
  "AMD Radeon PRO W7600": "8GB",
  "AMD Radeon PRO W7500": "8GB",
  "AMD Radeon PRO W6800": "32GB",
};

export type NvidiaSmi = z.infer<typeof NvidiaSmiSchema>;
export type AmdGpuInfo = z.infer<typeof AmdGpuInfoSchema>;
export type MacSmi = z.infer<typeof MacSmiSchema>;
