import { z } from "zod";

export const MetricsIntervalSchema = z.enum([
  "Hour",
  "Day",
  "Week",
  "Month",
  "Year",
]);

export const MetricsDurationSchema = z.enum([
  "All time",
  "Today",
  "One week",
  "One month",
  "Month to date",
  "Year to date",
  "One year",
  "Custom dates",
]);

export const MetricsTypeSchema = z.enum(["TPS", "Total Tokens", "Generations"]);

export const MetricsValueTypeSchema = z.enum(["Quantity"]);

export const MetricsQueryParamsSchema = z.object({
  userId: z.string().optional(),
  workerId: z.string().optional(),
  startsAt: z.number().optional(),
  endsAt: z.number().optional(),
  interval: MetricsIntervalSchema.optional(),
  types: z.array(MetricsTypeSchema),
});

export const PointSchema = z.object({
  x: z.number(),
  y: z.number(),
});

export const MetricsSchema = z.object({
  label: z.string(),
  interval: MetricsIntervalSchema.optional(),
  intervalOptions: z.array(MetricsIntervalSchema).optional(),
  coordinates: z.array(PointSchema).optional(),
  type: MetricsValueTypeSchema,
});

export const DurationSchema = z.object({
  startsAt: z.number(),
  endsAt: z.number(),
});

export type MetricsInterval = z.infer<typeof MetricsIntervalSchema>;
export type MetricsDuration = z.infer<typeof MetricsDurationSchema>;
export type MetricsType = z.infer<typeof MetricsTypeSchema>;
export type MetricsValueType = z.infer<typeof MetricsValueTypeSchema>;
export type MetricsQueryParams = z.infer<typeof MetricsQueryParamsSchema>;
export type Point = z.infer<typeof PointSchema>;
export type Metrics = z.infer<typeof MetricsSchema>;
export type Duration = z.infer<typeof DurationSchema>;
