import { JSONCodec } from "nats.ws";
import { z } from "zod";

/** ******************************************************************************
 *  Log Message
 ******************************************************************************* */

export const workerLogTopic = (workerId: string, instanceId?: string) => {
  if (instanceId) {
    return `worker.${workerId}.${instanceId}.info.log`;
  }

  return `worker.${workerId}.info.log`;
};

export const LogMessageSchema = z.object({
  header: z.string(),
  timestamp: z.string(),
  level: z.number(),
  message: z.array(z.string()),
  hideDetails: z.boolean().optional(),
  properties: z.record(z.unknown()).optional(),
});

export type LogMessage = z.infer<typeof LogMessageSchema>;

export const LogMessageCodec = JSONCodec<LogMessage>();
