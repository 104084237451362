import type { TableCellProps } from "@chakra-ui/react";
import { Suspense, useCallback } from "react";
import {
  Box,
  Button,
  Code,
  Flex,
  Icon,
  Image,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { FiCopy, FiPlus, FiTrash } from "react-icons/fi";
import ReactTooltip from "react-tooltip";
import { useCopyToClipboard } from "usehooks-ts";

import { StatusCodes } from "@kuzco/models";
import { time } from "@kuzco/utils";

import KuzcoLogo from "~/assets/kuzco-white.svg";
import SolanaLogo from "~/assets/solana.svg";
import useIsMobile from "~/ui/hooks/useIsMobile.hook";
import useModal from "~/ui/hooks/useModal.hook";
import { Content, Header, Title } from "~/ui/layouts/Page.layout";
import PosthogEvent from "../constants/PosthogEvent.constants";
import { api } from "../lib/trpc";
import { ConfirmModalTypes, ModalTypes } from "../main";
import SentryConsoleEvent from "../types/SentryConsoleEvent";
import ConnectWalletButton from "../ui/components/ConnectWalletButton";
import JoinSocials from "../ui/components/onboarding/JoinSocials";
import SystemTime from "../ui/components/SystemTime";
import Card from "../ui/elements/Card";
import { useAppConfig } from "../ui/hooks/useAppConfig.hook";
import useFlagEnabled from "../ui/hooks/useFlagEnabled.hook";
import useSolanaWallet from "../ui/hooks/useSolanaWallet.hook";
import useTracking from "../ui/hooks/useTracking.hook";
import useUser from "../ui/hooks/useUser.hook";
import useUserWallets from "../ui/hooks/useUserWallets.hook";
import GenericTableSuspense from "../ui/suspense/GenericTable.suspense";
import PageLayoutSuspense from "../ui/suspense/PageLayout.suspense";
import abbreviateSolanaAddress from "../utils/abbreviateSolanaAddress";
import { captureConsoleException } from "../utils/captureConsoleException";
import encodeSolanaSignature from "../utils/encodeSolanaSignature";

function InnerTable({ children }: { children: React.ReactNode }) {
  return (
    <Table variant="simple">
      <Thead
        borderTop="1px solid"
        borderBottom="1px solid"
        borderColor="gray.800"
      >
        <Tr backgroundColor="gray.1000" borderColor="gray.800">
          <Th borderColor="gray.800" color="white">
            Wallet Address
          </Th>
          <Th borderColor="gray.800" color="white">
            Connected On
          </Th>
          <Th borderColor="gray.800" color="white" width="100px" />
        </Tr>
      </Thead>
      {children}
    </Table>
  );
}

type WalletPageTableColumnProps = {
  children: React.ReactNode;
} & TableCellProps;

function WalletPageTableColumn({
  children,
  ...props
}: WalletPageTableColumnProps) {
  return (
    <Td
      fontSize="14px"
      lineHeight="20px"
      fontWeight="400"
      height="60px"
      padding="0 24px"
      maxWidth="300px"
      whiteSpace="nowrap"
      borderColor="gray.800"
      color="white"
      {...props}
    >
      {children}
    </Td>
  );
}

function WalletPageComponent() {
  const solanaWalletEnabledFlag = useFlagEnabled("solanaWalletEnabled", false);
  const { isTauri } = useAppConfig();
  const { push, pop } = useModal();
  const [, copy] = useCopyToClipboard();
  const toast = useToast();
  const { track } = useTracking();
  const isMobile = useIsMobile();
  const { user, refreshUser } = useUser();
  const { wallets: userWallets } = useUserWallets();
  const { publicKey, signMessage, isConnected } = useSolanaWallet();
  const verifySolanaWalletSignatureMutation =
    api.user.verifySolanaWalletSignature.useMutation();
  const unlinkSolanaWalletMutation = api.user.unlinkSolanaWallet.useMutation();

  const copyWalletPubkey = useCallback(
    (pubkey: string) => {
      ReactTooltip.hide();
      void copy(pubkey);
      toast({
        title: "Address Copied!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    },
    [copy, toast],
  );

  const handleSignMessage = useCallback(async () => {
    if (publicKey == null || signMessage == null) {
      return;
    }

    const message = "Kuzco Solana wallet verification message.";
    const encodedMessage = new TextEncoder().encode(message);
    try {
      const signedMessage = await signMessage(encodedMessage);
      const signature = encodeSolanaSignature(signedMessage);
      const response = await verifySolanaWalletSignatureMutation.mutateAsync({
        publicKey: publicKey.toString(),
        signature,
        message,
      });
      if (response.status !== StatusCodes.OK) {
        throw new Error(
          response.error?.message ?? "Signature verification failed.",
        );
      }

      await refreshUser();
      track({ event: PosthogEvent.SolanaWalletLinkingSuccess });
      toast({
        title: "Success",
        description: "Solana wallet successfully verified.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error: unknown) {
      track({ event: PosthogEvent.SolanaWalletLinkingFailure });
      captureConsoleException(
        SentryConsoleEvent.SolanaWalletLinkingError,
        error as Error,
        { user, publicKey },
      );
      toast({
        title: "Failed to verify wallet signature.",
        description: (error as Error).message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [
    publicKey,
    refreshUser,
    signMessage,
    toast,
    track,
    user,
    verifySolanaWalletSignatureMutation,
  ]);

  const handleUnlinkWallet = useCallback(
    async (walletPubkey: string) => {
      try {
        await unlinkSolanaWalletMutation.mutateAsync({
          publicKey: walletPubkey,
        });
        await refreshUser();
        toast({
          title: "Success",
          description: "Wallet unlinked successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        console.error("Error unlinking wallet:", error);
        captureConsoleException(
          SentryConsoleEvent.SolanaWalletUnlinkedError,
          error as Error,
          { user, publicKey },
        );
        const description = (error as Error).message;
        toast({
          title: "Failed to verify wallet signature.",
          description,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }

      pop();
    },
    [pop, refreshUser, toast, unlinkSolanaWalletMutation, publicKey, user],
  );

  return (
    <>
      <Header>
        <Flex width="100%" alignItems="center" justifyContent="space-between">
          <Title>Manage Wallet</Title>
          {solanaWalletEnabledFlag ? (
            isTauri ? (
              <SystemTime />
            ) : (
              <ConnectWalletButton />
            )
          ) : (
            <Tag size="sm" bg="gray.800" color="gray.500" marginLeft="12px">
              coming soon
            </Tag>
          )}
        </Flex>
      </Header>
      <Content>
        <Box height="64px" />
        <Flex
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          padding={isMobile ? "0 16px" : "0 24px"}
        >
          <Flex
            align="center"
            flexDirection="row"
            textAlign={isMobile ? "center" : "left"}
          >
            <Image
              src={SolanaLogo}
              alt="Solana Logo"
              height={isMobile ? "60px" : "120px"}
              width="auto"
            />
            <Box width={isMobile ? "16px" : "24px"} />
            <FiPlus
              size={isMobile ? "24px" : "48px"}
              color="white"
              className="mb-2 mt-2"
            />
            <Box width={isMobile ? "16px" : "24px"} />
            <Image
              src={KuzcoLogo}
              alt="Kuzco Logo"
              height={isMobile ? "60px" : "150px"}
              width="auto"
            />
          </Flex>
          <Box height="32px" />
          {solanaWalletEnabledFlag ? (
            userWallets.length > 0 ? (
              <TableContainer overflow="scroll" borderColor="gray.800">
                <InnerTable>
                  <Tbody overflow="hidden">
                    {userWallets.map(({ pubkey: walletPubkey, verifiedAt }) => {
                      return (
                        <Tr
                          key={walletPubkey}
                          height="80px"
                          borderColor="gray.800"
                          border="0px"
                        >
                          <WalletPageTableColumn maxWidth="500px">
                            <Flex
                              data-tip="Copy API Key"
                              alignItems="center"
                              justifyContent="space-between"
                              width="100%"
                              onClick={() => copyWalletPubkey(walletPubkey)}
                              _hover={{ cursor: "pointer" }}
                            >
                              <Code
                                color="purple.400"
                                padding="3px"
                                fontWeight="semibold"
                                borderRadius="md"
                              >
                                {walletPubkey}
                              </Code>
                            </Flex>
                          </WalletPageTableColumn>
                          <WalletPageTableColumn>
                            {time.format(
                              verifiedAt.getTime(),
                              "f",
                              time.currentBrowserTimezone(),
                            )}
                          </WalletPageTableColumn>
                          <WalletPageTableColumn>
                            <Flex alignItems="center" justifyContent="center">
                              <Button
                                data-tip="Copy API Key"
                                variant="secondary"
                                size="sm"
                                onClick={() => {
                                  copyWalletPubkey(walletPubkey);
                                }}
                              >
                                <Icon as={FiCopy} />
                              </Button>
                              <Box width="8px" />
                              <Button
                                data-tip="Delete API Key"
                                variant="secondary"
                                size="sm"
                                onClick={() => {
                                  ReactTooltip.hide();
                                  push({
                                    type: ModalTypes.Confirm,
                                    props: {
                                      title: "Unlink Solana Wallet",
                                      type: ConfirmModalTypes.Danger,
                                      message: (
                                        <Box>
                                          <Text>
                                            You are about to unlink your wallet
                                            address:{" "}
                                            <Code
                                              color="purple.400"
                                              padding="1px"
                                              fontWeight="semibold"
                                              borderRadius="md"
                                            >
                                              {abbreviateSolanaAddress(
                                                walletPubkey,
                                              )}
                                            </Code>
                                            Are you sure? You can always connect
                                            this wallet again later.
                                          </Text>
                                        </Box>
                                      ),
                                      confirm: () =>
                                        handleUnlinkWallet(walletPubkey),
                                      cancel: () => {
                                        pop();
                                      },
                                      mutation: unlinkSolanaWalletMutation,
                                    },
                                  });
                                }}
                              >
                                <Icon as={FiTrash} />
                              </Button>
                            </Flex>
                          </WalletPageTableColumn>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </InnerTable>
              </TableContainer>
            ) : (
              <Card
                marginTop="32px"
                textAlign="center"
                width={isMobile ? "100%" : "600px"}
                padding={isMobile ? "16px" : "24px"}
              >
                {isConnected ? (
                  <Flex flexDir="column">
                    <Text>Link your Solana wallet to your Kuzco account.</Text>
                    <Text marginTop="18px">
                      This is currently not supported for Ledger wallets.
                    </Text>
                    <Button
                      margin="auto"
                      marginTop="32px"
                      marginBottom="16px"
                      width="150px"
                      isDisabled={!isConnected}
                      variant="primary"
                      onClick={handleSignMessage}
                    >
                      Link Wallet
                    </Button>
                  </Flex>
                ) : isTauri ? (
                  <Flex flexDir="column">
                    <Text>
                      Solana wallet integration is currently only available in
                      the web console.
                    </Text>
                  </Flex>
                ) : (
                  <Flex flexDir="column">
                    <Text>Connect your wallet to continue.</Text>
                    <Box marginTop="32px" marginBottom="16px">
                      <ConnectWalletButton />
                    </Box>
                  </Flex>
                )}
              </Card>
            )
          ) : (
            <JoinSocials />
          )}
        </Flex>
      </Content>
    </>
  );
}

function WalletPageSuspense() {
  return (
    <PageLayoutSuspense pageTitle="Manage Wallet">
      <GenericTableSuspense />
    </PageLayoutSuspense>
  );
}

export default function WalletPage() {
  return (
    <Suspense fallback={<WalletPageSuspense />}>
      <WalletPageComponent />
    </Suspense>
  );
}
