// NOTE: Keep this in sync with GenerationsTable.drizzle.ts.
export const POOLS = [
  { name: "Llama3.1 8b", model: "llama3.1:latest", allocation: 0 },
  { name: "Llama3 8b", model: "llama3:latest", allocation: 1 },
  { name: "llama2", model: "llama2", allocation: 0 },
  { name: "Phi-3 Mini", model: "phi3:latest", allocation: 0.0 },
  { name: "Mistral 7b", model: "mistral", allocation: 0.0 },
] as const;

export const MODELS = POOLS.map((pool) => pool.model);

export type MODEL_NAME = (typeof MODELS)[number];
