import "./index.css";

import React from "react";
import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom/client";
import z from "zod";

import { App } from "@kuzco/console";

const env = import.meta.env;

export const SENTRY_DSN = z
  .string({
    required_error: "VITE_SENTRY_DSN environment variable is required",
  })
  .parse(env.VITE_SENTRY_DSN);

export const NODE_ENV = z
  .string({
    required_error: "VITE_NODE_ENV environment variable is required",
  })
  .parse(env.VITE_NODE_ENV);

export const SENTRY_SAMPLING_RATE = z
  .number()
  .default(0.01)
  .parse(env.VITE_SENTRY_SAMPLING_RATE);

Sentry.init({
  dsn: SENTRY_DSN,
  environment: NODE_ENV,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: SENTRY_SAMPLING_RATE, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/*\.kuzco\.xyz/],
  // Session Replay
  replaysSessionSampleRate: SENTRY_SAMPLING_RATE, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App
      appConfig={{
        isTauri: false,
        runtimeType: () => Promise.resolve("web"),
      }}
    />
  </React.StrictMode>,
);
