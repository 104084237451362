import { useEffect } from "react";
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Flex,
} from "@chakra-ui/react";
import { useRouteError } from "react-router-dom";

import { workerStatus, WorkerStatusSchema } from "@kuzco/models";

import type RouteErrorType from "../types/RouteErrorType";
import SideNavigation from "~/ui/components/dashboard/SideNavigation";
import { useAppConfig } from "~/ui/hooks/useAppConfig.hook";
import useWorker from "~/ui/hooks/useWorker.hook";
import { Page } from "~/ui/layouts/Main.layout";
import SentryConsoleEvent from "../types/SentryConsoleEvent";
import DebugButton from "../ui/components/DebugButton";
import ErrorPageContent from "../ui/components/ErrorPageContent";
import { captureConsoleException } from "../utils/captureConsoleException";
import getMessageFromRouteError from "../utils/getMessageFromRouteError";

export default function ErrorPage() {
  const error = useRouteError() as RouteErrorType;
  const { isTauri } = useAppConfig();
  const { refreshWorkerList } = useWorker();

  useEffect(() => {
    console.error("[ErrorPage] Uncaught error:", error);
    captureConsoleException(SentryConsoleEvent.ErrorPageHit, error);
  }, [error]);

  useEffect(() => {
    if (isTauri) {
      const intervalId = setInterval(async () => {
        const { workers } = await refreshWorkerList.mutateAsync();
        const onlineWorker = workers?.find(
          (w) => workerStatus(w) === WorkerStatusSchema.Enum.Online,
        );
        if (onlineWorker) {
          window.location.reload();
        }
      }, 15000); // Check every 15 seconds

      return () => clearInterval(intervalId);
    }
  }, [isTauri, refreshWorkerList]);

  return (
    <Page>
      <Flex height="100%" width="100%">
        <SideNavigation />
        <Box
          position="relative"
          height="100vh"
          width="fill-available"
          overflow="scroll"
          backgroundColor="gray.1100"
          p={4}
        >
          <Flex
            height="100%"
            width="100%"
            alignItems="center"
            justifyContent={{
              base: "unset",
              md: "center",
            }}
            mt={{ base: "0", md: "-96px" }}
            flexDir={"column"}
            gridGap={"64px"}
          >
            <ErrorPageContent />
            <Flex flexDir="column" w="100%" maxW="900px">
              <Alert status="error">
                <Flex flexDir={"column"} w="100%" flex={1} gridGap={"18px"}>
                  <AlertTitle>Debug Information</AlertTitle>
                  <DebugButton
                    error={error}
                    buttonText="Click here to copy debug info that can be shared with support."
                  />
                  <AlertDescription>
                    <code>{getMessageFromRouteError(error)}</code>
                  </AlertDescription>
                </Flex>
              </Alert>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </Page>
  );
}
