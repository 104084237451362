import { Suspense } from "react";
import { Box, Button } from "@chakra-ui/react";
import { useAtomValue } from "jotai";

import KeysTable from "~/ui/components/keys/KeysTable";
import { ModalTypes } from "~/ui/components/modal/Modal";
import useIsMobile from "~/ui/hooks/useIsMobile.hook";
import useModal from "~/ui/hooks/useModal.hook";
import { KeyState } from "~/ui/jotai/key.jotai";
import SettingsLayout from "~/ui/layouts/Settings.layout";
import GenericTableSuspense from "~/ui/suspense/GenericTable.suspense";
import PageLayoutSuspense from "~/ui/suspense/PageLayout.suspense";

function APIKeysPageSuspense() {
  return (
    <PageLayoutSuspense pageTitle="Manage API Keys">
      <GenericTableSuspense />
    </PageLayoutSuspense>
  );
}

function APIKeysPageComponent() {
  const { push } = useModal();
  const keyList = useAtomValue(KeyState.keyList);
  const isMobile = useIsMobile();

  return (
    <SettingsLayout
      title="API Keys"
      description="Create & manage API keys for your account."
      Action={() => (
        <Button
          variant="primary"
          size="sm"
          onClick={() =>
            push({
              type: ModalTypes.CreateAPIKey,
              props: {},
            })
          }
        >
          Create Key
        </Button>
      )}
    >
      <Box
        padding={isMobile ? "0px 16px" : "0px 24px"}
        overflowX={isMobile ? "scroll" : "auto"}
      >
        <KeysTable keyList={keyList} />
      </Box>
    </SettingsLayout>
  );
}

export default function APIKeysPage() {
  return (
    <Suspense fallback={<APIKeysPageSuspense />}>
      <APIKeysPageComponent />
    </Suspense>
  );
}
