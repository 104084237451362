import React from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Link,
  Text,
  useTheme,
} from "@chakra-ui/react";
import { FiAlertTriangle, FiArrowLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import { MAX_WORKERS } from "@kuzco/models";

import { DISCORD_INVITE_LINK, TWITTER_LINK } from "~/lib/const";
import Card from "~/ui/elements/Card";
import useUser from "~/ui/hooks/useUser.hook";
import useWorker from "~/ui/hooks/useWorker.hook";
import SaveWorkerMenu from "./SaveWorkerMenu";
import WorkerInstallTypeSelect from "./WorkerInstallTypeSelect";
import WorkerName from "./WorkerName";

const CreateWorker: React.FC = () => {
  const { isSuper } = useUser();
  const { workerList, createWorkerParams, setCreateWorkerParams } =
    useWorker("");
  const [errors] = React.useState<Record<string, object>>({});
  const navigate = useNavigate();
  const theme = useTheme();

  if (workerList.length >= MAX_WORKERS && !isSuper) {
    return (
      <Card width="100%" backgroundColor="gray.1000">
        <Flex alignItems="flex-start" justifyContent="space-between">
          <Box alignItems="center">
            <Flex alignItems="center">
              <FiAlertTriangle
                color={theme.colors.yellow[500]}
                fontSize="20px"
              />
              <Text variant="16-med" color="white" marginLeft="8px">
                Worker Creation Unavailable
              </Text>
            </Flex>
            <Text variant="14-reg" color="gray.300" marginTop="4px">
              Each account is limited to {MAX_WORKERS} workers during the alpha
              phase of our rollout. Follow us on{" "}
              <Link href={TWITTER_LINK} target="_blank" textDecor="underline">
                Twitter
              </Link>{" "}
              or join our{" "}
              <Link
                href={DISCORD_INVITE_LINK}
                target="_blank"
                textDecor="underline"
              >
                Discord
              </Link>{" "}
              to stay updated on our progress.
              <br />
              <br />
              <b>
                You can archive a worker in it's setting tab to make room for
                new workers.
              </b>
            </Text>
          </Box>
        </Flex>
        <div>
          <Button
            variant="primary"
            size="sm"
            marginTop="16px"
            onClick={() => navigate("/dashboard/workers")}
          >
            <FiArrowLeft />
            <Box width="8px" />
            Got it 🫡
          </Button>
        </div>
      </Card>
    );
  }

  /** Render */
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Text variant="24-reg" color="white">
            Create a worker
          </Text>
          <Box height="4px" />
          <Text variant="14-reg" color="gray.400">
            Fill out the fields below to get started
          </Text>
        </Box>
      </Box>
      <Box margin="24px 0px 32px">
        <Divider height="1px" backgroundColor="gray.800" />
      </Box>
      <WorkerName
        params={createWorkerParams}
        setParams={setCreateWorkerParams}
        errors={errors}
      />
      <Flex height="24px" />
      <WorkerInstallTypeSelect
        params={createWorkerParams}
        setParams={setCreateWorkerParams}
      />
      <Box margin="24px 0px 32px">
        <Divider height="1px" backgroundColor="gray.800" />
      </Box>
      <SaveWorkerMenu />
      <Box height="300px" />
    </>
  );
};

export default CreateWorker;
