import React from "react";
import {
  Box,
  Flex,
  Icon,
  Image,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";

import type { InstanceHardwareRow } from "@kuzco/models";

import { api } from "../../../lib/trpc";
import { useGpuTypeLogo } from "../../hooks/useGpuTypeLogo.hook";
import useIsMobile from "../../hooks/useIsMobile.hook";

const InnerTable = ({ children }: { children?: React.ReactNode }) => {
  const isMobile = useIsMobile();

  const DesktopHeader = React.useMemo(
    () => (
      <Tr backgroundColor="gray.1000" borderColor="gray.800">
        <Th
          borderColor="gray.800"
          color="gray.200"
          textTransform="capitalize"
          letterSpacing="0px"
        >
          Name
        </Th>
        <Th
          borderColor="gray.800"
          color="gray.200"
          textTransform="capitalize"
          letterSpacing="0px"
        >
          Count
        </Th>
        <Th
          borderColor="gray.800"
          color="gray.200"
          textTransform="capitalize"
          letterSpacing="0px"
        >
          Percentage of Total
        </Th>
        <Th
          borderColor="gray.800"
          color="gray.200"
          textTransform="capitalize"
          letterSpacing="0px"
        >
          VRAM
        </Th>
      </Tr>
    ),
    [],
  );

  const MobileHeader = React.useMemo(
    () => (
      <Tr backgroundColor="gray.1000" borderColor="gray.800">
        <Th
          borderColor="gray.800"
          color="gray.200"
          textTransform="capitalize"
          letterSpacing="0px"
        >
          GPU
        </Th>
      </Tr>
    ),
    [],
  );

  return (
    <Table variant="simple">
      <Thead
        borderTop="1px solid"
        borderBottom="1px solid"
        borderColor="gray.800"
        height="44px"
      >
        {isMobile ? MobileHeader : DesktopHeader}
      </Thead>
      {children ? children : null}
    </Table>
  );
};

const MockTable = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box overflow="hidden" height="100%" width="100%">
      <InnerTable />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="calc(100% - 150px)"
        width="100%"
      >
        {children}
      </Box>
    </Box>
  );
};

const Column = ({ children }: { children: React.ReactNode }) => {
  return (
    <Td height="72px" borderColor="gray.800">
      <Text
        overflow="hidden"
        color="gray.200"
        fontSize="14px"
        lineHeight="20px"
        fontWeight="400"
        maxWidth="500px"
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {children}
      </Text>
    </Td>
  );
};

const Row = ({
  gpu,
  count,
  percentage,
  totalVRAM,
  type,
}: InstanceHardwareRow) => {
  const logo = useGpuTypeLogo(type);
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <Tr>
        <Column>
          {logo && (
            <>
              <Image
                alt="Logo"
                src={logo}
                style={{
                  maxWidth: "24px",
                  height: "24px",
                  borderRadius: "6px",
                  marginBottom: "8px",
                }}
              />
              <Box width="8px" />
            </>
          )}
          <Tooltip label={gpu}>
            <Flex alignItems="center">
              <Box
                fontWeight="500"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {gpu}
              </Box>
            </Flex>
          </Tooltip>
          <Flex>
            <Box fontWeight="500">Count:&nbsp;</Box>
            <Box>{count}</Box>
          </Flex>
          <Flex>
            <Box fontWeight="500">Percentage of Total:&nbsp;</Box>
            <Box>{(percentage * 100).toFixed(2)}%</Box>
          </Flex>
          <Flex>
            <Box fontWeight="500">VRAM:&nbsp;</Box>
            <Box>{totalVRAM}</Box>
          </Flex>
        </Column>
      </Tr>
    );
  }

  return (
    <Tr>
      <Column>
        <Tooltip label={gpu}>
          <Flex alignItems="center">
            {logo && (
              <>
                <Image
                  alt="Logo"
                  src={logo}
                  style={{
                    maxWidth: "24px",
                    height: "24px",
                    borderRadius: "6px",
                  }}
                />
                <Box width="8px" />
              </>
            )}
            <Box
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {gpu}
            </Box>
          </Flex>
        </Tooltip>
      </Column>
      <Column>{count}</Column>
      <Column>{(percentage * 100).toFixed(2)}%</Column>
      <Column>{totalVRAM}</Column>
    </Tr>
  );
};

const HardwareTable: React.FC = () => {
  const { data, isLoading } = api.instance.hardware.useQuery();

  if (isLoading) {
    return (
      <Flex height="80vh" alignItems="center" justifyContent="center">
        <Spinner size="lg" />
      </Flex>
    );
  }

  return (
    <TableContainer
      overflow="auto"
      border="1px solid"
      borderColor="gray.800"
      borderRadius="8px"
      backgroundColor="gray.1100"
      maxWidth="100%"
      minWidth="350px"
    >
      <Box
        padding="24px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <Text color="white" variant="20-semi">
            GPUs ({data?.totalCount})
          </Text>
        </Box>
        <Box display="flex" alignItems="center">
          <Text color="white" variant="16-reg">
            Total VRAM: {data?.totalVRAM}
          </Text>
        </Box>
      </Box>
      {(() => {
        if (!data?.gpus?.length) {
          return (
            <MockTable>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="300px"
              >
                <Icon as={FiSearch} color="gray.400" fontSize="40px" />
                <Flex height="16px" />
                <Text color="gray.500" fontSize="18px" fontWeight="500">
                  No GPUs Online :(
                </Text>
              </Box>
            </MockTable>
          );
        }

        return (
          <InnerTable>
            <Tbody overflow="hidden">
              {data.gpus.map((row, index) => (
                <Row key={index} {...row} />
              ))}
            </Tbody>
          </InnerTable>
        );
      })()}
    </TableContainer>
  );
};

export default HardwareTable;
