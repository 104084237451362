import { useEffect } from "react";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import MaintenanceModeLayout from "~/ui/layouts/MaintenanceMode.layout";
import { withSuspense } from "../ui/hoc/withSuspense";
import useUser from "../ui/hooks/useUser.hook";

const DisabledUserPage = () => {
  const navigate = useNavigate();
  const { user, logout } = useUser();

  useEffect(() => {
    if (user?.isDisabled === false) {
      navigate("/");
    }
  }, [user, navigate]);

  return (
    <MaintenanceModeLayout>
      <Box
        backgroundColor="gray.1000"
        height="100vh"
        width="100vw"
        display="flex"
        justifyContent="center"
      >
        <Flex
          position="relative"
          top={{ base: "0", md: "96px" }}
          width={{ base: "100%", md: "420px" }}
          height="fit-content"
          flexDirection="column"
          alignItems="start"
          borderRadius="8px"
          boxSizing="border-box"
        >
          <Box height="24px" />
          <Text variant="30-bold" color="white">
            We&apos;ve temporarily paused your account.
          </Text>
          <Box height="12px" />
          <Text variant="16-reg" color="gray.400">
            We identified irregular behavior with some of your workers, which
            suggests you may have a misconfigured Kuzco setup.
          </Text>
          <Box height="12px" />
          <Text variant="16-reg" color="gray.400">
            We are working to ensure all Kuzco workers can run successfully and
            want to reach out to you directly to help debug your setup.
          </Text>
          <Box height="12px" />
          <Text variant="16-reg" color="gray.400">
            Please reach out to us to re-enable your account.
          </Text>
          <Box height="24px" />
          <Button
            marginTop="24px"
            variant="primary"
            size="lg"
            width="100%"
            onClick={() => window.open("https://t.me/samhogan", "_blank")}
          >
            Contact us on Telegram
          </Button>
          <Button
            marginTop="24px"
            variant="primary"
            size="lg"
            width="100%"
            onClick={() =>
              window.open("https://cal.com/samhogan/15min", "_blank")
            }
          >
            Book a call
          </Button>
          <Button
            marginTop="24px"
            variant="outline"
            size="lg"
            width="100%"
            onClick={logout}
          >
            Logout
          </Button>
        </Flex>
      </Box>
    </MaintenanceModeLayout>
  );
};

export default withSuspense(DisabledUserPage);
