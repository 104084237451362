import { z } from "zod";

import {
  authedServiceRequest,
  serviceRequest,
  serviceResponse,
} from "./BaseService";

export const KeySchema = z.object({
  _id: z.string(),
  userId: z.string(),
  teamId: z.string(),
  name: z.string(),
  key: z.string(),
  archived: z.boolean(),
  lastUsedAt: z.number().nullable(),
  createdAt: z.number(),
});

export const KeyMongoSchema = {
  _id: { type: String, required: true },
  userId: { type: String, required: true },
  teamId: { type: String, required: true },
  name: { type: String, required: true },
  key: { type: String, required: true },
  archived: { type: Boolean, required: true },
  lastUsedAt: { type: Number, required: false },
  createdAt: { type: Number, required: true },
};

export type Key = z.infer<typeof KeySchema>;

export type KeyService = {
  create(request: CreateKeyRequest): Promise<CreateKeyResponse>;
  archive(request: ArchiveKeyRequest): Promise<ArchiveKeyResponse>;
  get(request: GetKeyRequest): Promise<GetKeyResponse>;
  list(request: ListKeysRequest): Promise<ListKeysResponse>;
};

/** ******************************************************************************
 *  Constants
 ******************************************************************************* */

// NOTE: This is disabled temporarily to support the generations backfill.
export const SHOULD_UPDATE_KEY_LAST_USED_AT = false as boolean;

/** ******************************************************************************
 *  Create Key
 ******************************************************************************* */

export const createKeyParams = z.object({
  name: z.string().min(4).max(64),
});

export const createKeyRequest = authedServiceRequest.merge(
  z.object({
    params: createKeyParams,
  }),
);

export const createKeyResponse = serviceResponse.merge(
  z.object({
    key: KeySchema.optional(),
  }),
);

export type CreateKeyParams = z.infer<typeof createKeyParams>;
export type CreateKeyRequest = z.infer<typeof createKeyRequest>;
export type CreateKeyResponse = z.infer<typeof createKeyResponse>;

/** ******************************************************************************
 *  Archive Key
 ******************************************************************************* */

export const archiveKeyParams = z.object({
  _id: z.string(),
});

export const archiveKeyRequest = authedServiceRequest.merge(
  z.object({
    params: archiveKeyParams,
  }),
);

export const archiveKeyResponse = serviceResponse.merge(
  z.object({
    key: KeySchema.nullable().optional(),
  }),
);

export type ArchiveKeyParams = z.infer<typeof archiveKeyParams>;
export type ArchiveKeyRequest = z.infer<typeof archiveKeyRequest>;
export type ArchiveKeyResponse = z.infer<typeof archiveKeyResponse>;

/** ******************************************************************************
 *  Get Key
 ******************************************************************************* */

export const getKeyParams = z.object({
  key: z.string(),
});

export const getKeyRequest = serviceRequest.merge(
  z.object({
    params: getKeyParams,
  }),
);

export const getKeyResponse = serviceResponse.merge(
  z.object({
    key: KeySchema.nullable().optional(),
  }),
);

export type GetKeyParams = z.infer<typeof getKeyParams>;
export type GetKeyRequest = z.infer<typeof getKeyRequest>;
export type GetKeyResponse = z.infer<typeof getKeyResponse>;

/** ******************************************************************************
 *  List Keys
 ******************************************************************************* */

export const listKeysParams = z.undefined();

export const listKeysRequest = authedServiceRequest.merge(
  z.object({
    params: listKeysParams,
  }),
);

export const listKeysResponse = serviceResponse.merge(
  z.object({
    keys: z.array(KeySchema).optional(),
  }),
);

export type ListKeysParams = z.infer<typeof listKeysParams>;
export type ListKeysRequest = z.infer<typeof listKeysRequest>;
export type ListKeysResponse = z.infer<typeof listKeysResponse>;
