import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import KuzcoLogo from "~/assets/kuzco-white.svg";
import JoinSocials from "~/ui/components/onboarding/JoinSocials";
import { withSuspense } from "~/ui/hoc/withSuspense";
import DashboardLayout from "~/ui/layouts/Dashboard.layout";
import { Content, Header, Title } from "~/ui/layouts/Page.layout";

const InstallOllama = () => {
  const navigate = useNavigate();
  return (
    <DashboardLayout hideNavigation>
      <>
        <Header>
          <Title>
            <Flex alignItems="center">
              <Image
                alt="Kuzco Logo"
                src={KuzcoLogo}
                width="24px"
                height="24px"
                maxWidth="32px"
                borderRadius="6px"
              />
              <Box width="12px" />
              <Title>Kuzco Console</Title>
            </Flex>
          </Title>
          <Button
            variant="secondary"
            size="sm"
            onClick={() => {
              navigate("/dashboard/overview");
            }}
          >
            Next
          </Button>
        </Header>
        <Content>
          <Box display="flex" paddingTop="40px" justifyContent="center">
            <Box width="600px">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Text variant="24-reg" color="white">
                    Welcome to Kuzco
                  </Text>
                  <Box height="4px" />
                  <Text variant="16-reg" color="gray.400">
                    Check out the links below to connect with other network
                    particpants.
                  </Text>
                </Box>
              </Box>
              <Box height="40px" />
              <JoinSocials />
              <Box height="40px" />
              <Flex justifyContent="center">
                <Button
                  variant="primary"
                  onClick={() => navigate("/dashboard/overview")}
                  margin="0"
                >
                  Continue to Dashboard
                </Button>
              </Flex>
              <Box height="300px" />
            </Box>
          </Box>
        </Content>
      </>
    </DashboardLayout>
  );
};

export default withSuspense(InstallOllama);
