import { Box, Skeleton } from "@chakra-ui/react";

import { Content } from "../layouts/Page.layout";
import NetworkSummarySuspense from "./NetworkSummary.suspense";

type StatsSuspenseProps = {
  renderTopBar?: boolean;
};

export default function StatsSuspense({
  renderTopBar = false,
}: StatsSuspenseProps) {
  return (
    <Content>
      <Box height="24px" />
      {renderTopBar && (
        <>
          <Skeleton height="82px" maxWidth="988px" borderRadius="lg" />
          <Box height="24px" />
        </>
      )}
      <NetworkSummarySuspense />
    </Content>
  );
}
