const KUZCO_JWT = "kuzco-jwt";

export const setToken = (token: string): void => {
  localStorage.setItem(KUZCO_JWT, token);
};

export const getToken = (): string | null => {
  return localStorage.getItem(KUZCO_JWT) ?? null;
};

export const clearToken = (): void => {
  localStorage.removeItem(KUZCO_JWT);
};

export const isAuthenticated = (): boolean => {
  return Boolean(getToken());
};

const AuthUtil = {
  setToken,
  getToken,
  isAuthenticated,
  clearToken,
};

export default AuthUtil;
