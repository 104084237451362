import { z } from "zod";

/**
 * These helper are for the UI code (web and tauri) which throw hard to
 * read Zod errors if environment variables fail to parse correctly or are
 * not provided.
 *
 * For the NodeJS/Bun environments the error messages are more clear and these
 * utils don't need to be used.
 */

type Environment = Record<string, string>;

export function getEnvStringFn(env: Environment) {
  return function (name: string, defaultValue?: string): string {
    const value = env[name];
    try {
      return z
        .string()
        .min(1)
        .parse(value ?? defaultValue);
    } catch (e) {
      throw new Error(
        `"${name}" is a required environment variable but was invalid or missing, received: '${value}'.`,
      );
    }
  };
}

export function getEnvStringAllowEmptyFn(env: Environment) {
  return function (name: string, defaultValue?: string): string {
    const value = env[name];
    try {
      return z.string().parse(value ?? defaultValue);
    } catch (e) {
      throw new Error(
        `"${name}" is a required environment variable but was invalid or missing, received: '${value}'.`,
      );
    }
  };
}

export function getEnvNumberFn(env: Environment) {
  return function (name: string, defaultValue?: number): number {
    const value = env[name];
    try {
      return z.number().parse(value ?? defaultValue);
    } catch (e) {
      throw new Error(
        `"${name}" is a required environment variable but was invalid or missing, received: '${value}'.`,
      );
    }
  };
}

export function getEnvBooleanFn(env: Environment) {
  return function (name: string, defaultValue?: boolean): boolean {
    const value = env[name];
    try {
      return z.boolean().parse(value ?? defaultValue);
    } catch (e) {
      throw new Error(
        `"${name}" is a required environment variable but was invalid or missing, received: '${value}'.`,
      );
    }
  };
}
