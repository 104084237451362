import type { Queue } from "bullmq";
import { z } from "zod";

import {
  authedServiceRequest,
  serviceRequest,
  serviceResponse,
} from "./BaseService";

export const BenchmarkSchema = z.object({
  _id: z.string(),
  workerId: z.string(),
  workerUserId: z.string().nullable(),
  instanceId: z.string(),
  model: z.string(),
  tokensPerSecond: z.number(),
  /**
   * Created at timestamp in milliseconds
   */
  createdAt: z.number(),
});

export const BenchmarkMongoSchema = {
  _id: { type: String, required: true },
  workerId: { type: String, required: true },
  workerUserId: { type: String, required: false, default: null },
  instanceId: { type: String, required: true },
  model: { type: String, required: true },
  tokensPerSecond: { type: Number, required: true },
  createdAt: { type: Number, required: true },
};

export type Benchmark = z.infer<typeof BenchmarkSchema>;

export type BenchmarkJobParams = {
  workerId: string;
  instanceId: string;
  isFirstBenchmark: boolean;
};

export type BenchmarkService = {
  benchmarkQueue: Queue<BenchmarkJobParams>;
  schedulerQueue: Queue;
  queue(request: QueueBenchmarkRequest): Promise<QueueBenchmarkResponse>;
  create(request: CreateBenchmarkRequest): Promise<CreateBenchmarkResponse>;
  latest(request: LatestBenchmarkRequest): Promise<LatestBenchmarkResponse>;
  listByWorker(
    request: ListBenchmarksByWorkerRequest,
  ): Promise<ListBenchmarksByWorkerResponse>;
};

/** ******************************************************************************
 *  Queue Benchmark
 ******************************************************************************* */

export const queueBenchmarkParams = z.object({
  workerId: z.string(),
  instanceId: z.string(),
  isFirstBenchmark: z.boolean().optional(),
  delayMs: z.number().optional(),
});

export const queueBenchmarkRequest = serviceRequest.merge(
  z.object({
    params: queueBenchmarkParams,
  }),
);

export const queueBenchmarkResponse = serviceResponse.merge(
  z.object({
    success: z.boolean(),
  }),
);

export type QueueBenchmarkParams = z.infer<typeof queueBenchmarkParams>;
export type QueueBenchmarkRequest = z.infer<typeof queueBenchmarkRequest>;
export type QueueBenchmarkResponse = z.infer<typeof queueBenchmarkResponse>;

/** ******************************************************************************
 *  Create Benchmark
 ******************************************************************************* */

export const createBenchmarkParams = z.object({
  workerId: z.string(),
  instanceId: z.string(),
  isFirstBenchmark: z.boolean().optional(),
});

export const createBenchmarkRequest = serviceRequest.merge(
  z.object({
    params: createBenchmarkParams,
  }),
);

export const createBenchmarkResponse = serviceResponse.merge(
  z.object({
    benchmark: BenchmarkSchema.nullable(),
  }),
);

export type CreateBenchmarkParams = z.infer<typeof createBenchmarkParams>;
export type CreateBenchmarkRequest = z.infer<typeof createBenchmarkRequest>;
export type CreateBenchmarkResponse = z.infer<typeof createBenchmarkResponse>;

/** ******************************************************************************
 *  Get Latest Benchmark
 ******************************************************************************* */

export const latestBenchmarkParams = z.object({
  workerId: z.string(),
  instanceId: z.string(),
  model: z.string(),
});

export const latestBenchmarkRequest = serviceRequest.merge(
  z.object({
    params: latestBenchmarkParams,
  }),
);

export const latestBenchmarkResponse = serviceResponse.merge(
  z.object({
    benchmark: BenchmarkSchema.nullable(),
  }),
);

export type LatestBenchmarkParams = z.infer<typeof latestBenchmarkParams>;
export type LatestBenchmarkRequest = z.infer<typeof latestBenchmarkRequest>;
export type LatestBenchmarkResponse = z.infer<typeof latestBenchmarkResponse>;

/** ******************************************************************************
 *  List Benchmarks By Worker
 ******************************************************************************* */

export const listBenchmarksByWorkerParams = z.object({
  workerId: z.string().optional(),
  cursor: z
    .object({
      pageSize: z.number(),
      offset: z.number(),
    })
    .nullish(),
});

export const listBenchmarksByWorkerRequest = authedServiceRequest.merge(
  z.object({
    params: listBenchmarksByWorkerParams,
  }),
);

export const listBenchmarksByWorkerResponse = serviceResponse.merge(
  z.object({
    benchmarks: z.array(BenchmarkSchema).optional(),
    total: z.number().optional(),
  }),
);

export type ListBenchmarksByWorkerParams = z.infer<
  typeof listBenchmarksByWorkerParams
>;
export type ListBenchmarksByWorkerRequest = z.infer<
  typeof listBenchmarksByWorkerRequest
>;
export type ListBenchmarksByWorkerResponse = z.infer<
  typeof listBenchmarksByWorkerResponse
>;
