import { Box, Button, Flex, Link, Text } from "@chakra-ui/react";

import { LEARN_MORE_LINK } from "~/lib/const";
import Card from "~/ui/elements/Card";

export const EpochOneText = () => {
  return (
    <Text variant="14-reg">
      Kuzco Testnet is live!&nbsp;
      <Text as="span" color="green.400" variant="14-med">
        Epoch One
      </Text>
      &nbsp;has started.
    </Text>
  );
};

export const EpochOneCard = () => {
  return (
    <Flex justifyContent="center">
      <Card
        padding="12px"
        width={{ base: "100%", md: "560px" }}
        margin={{ base: "0 24px", md: "0" }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Flex alignItems="center">
            <Box
              backgroundColor="green.300"
              height="8px"
              width="8px"
              borderRadius="50%"
            />
            <Box width="8px" />
            <EpochOneText />
          </Flex>
          <Link href={LEARN_MORE_LINK}>
            <Button variant="secondary" size="xs" marginLeft="8px">
              Learn more
            </Button>
          </Link>
        </Box>
      </Card>
    </Flex>
  );
};
