import { Box, Skeleton } from "@chakra-ui/react";

import { arrays } from "@kuzco/utils";

import { Content } from "../layouts/Page.layout";

export default function GenericTableSuspense() {
  return (
    <Content maxWidth="900px">
      <Box>
        <Skeleton
          marginTop="32px"
          width="20%"
          height="32px"
          borderRadius="lg"
        />
        <Skeleton
          marginTop="12px"
          width="40%"
          height="20px"
          borderRadius="lg"
        />
        <Box height="32px" />
        {arrays.range(5).map((i) => {
          return (
            <Skeleton
              marginTop="12px"
              key={i}
              height="42px"
              width="100%"
              borderRadius="lg"
            />
          );
        })}
      </Box>
    </Content>
  );
}
