import React from "react";
import { Button, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import parseMutationError from "~/lib/parseMutationError";
import useWorker from "~/ui/hooks/useWorker.hook";

type SaveWorkerMenuProps = {
  workerId?: string;
};

const SaveWorkerMenu: React.FC<SaveWorkerMenuProps> = ({ workerId }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const { createWorker, createWorkerParams, updateWorker, updateWorkerParams } =
    useWorker(workerId ?? "");
  const [_errors, setErrors] = React.useState<Record<string, unknown>>({});

  const create = async () => {
    try {
      setLoading(true);
      const { worker, error } = await createWorker.mutateAsync({
        ...createWorkerParams,
      });

      console.log(worker);

      if (!worker) {
        throw new Error(`Failed to create worker: ${JSON.stringify(error)}`);
      }

      navigate(`/dashboard/workers/details/${worker._id}/`);
    } catch (e) {
      setLoading(false);
      console.error(e);
      try {
        const parsedError = parseMutationError(e);
        setErrors(parsedError);
        toast({
          title: "Invalid Fields",
          description:
            "Some of the fields above are invalid. Please correct the errors and try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } catch (e) {
        toast({
          title: "Error Creating Worker",
          description:
            (e as Error | undefined)?.message ??
            "Something went wrong. Please try again later or contact support.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const update = async () => {
    try {
      setLoading(true);
      const { worker, error } =
        await updateWorker.mutateAsync(updateWorkerParams);

      if (!worker) {
        throw new Error(`Failed to update worker: ${error?.message}`);
      }

      navigate(`/dashboard/workers/details/${worker._id}/`);
    } catch (e) {
      setLoading(false);
      try {
        const parsedError = parseMutationError(e);
        setErrors(parsedError);
        toast({
          title: "Invalid Fields",
          description:
            "Some of the fields above are invalid. Please correct the errors and try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } catch (e) {
        toast({
          title: "Error Saving Worker",
          description:
            "Something went wrong. Please try again later or contact support.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Button
      variant="primary"
      onClick={() => (workerId ? update() : create())}
      isLoading={loading}
      isDisabled={loading}
      size={"sm"}
    >
      {workerId ? "Save worker" : "Create worker"}
    </Button>
  );
};

export default SaveWorkerMenu;
