import type { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import type { WalletContextState } from "@solana/wallet-adapter-react";
import { useWallet } from "@solana/wallet-adapter-react";

import { NODE_ENV } from "../../lib/const";
import getSolanaClusterFromNodeEnv from "../../utils/getSolanaClusterFromNodeEnv";

type SolanaWalletContext = WalletContextState & {
  isConnected: boolean;
  network: WalletAdapterNetwork;
};

export default function useSolanaWallet() {
  const wallet = useWallet();
  const network = getSolanaClusterFromNodeEnv(NODE_ENV);
  const isConnected = wallet.publicKey != null;
  const context: SolanaWalletContext = {
    ...wallet,
    isConnected,
    network,
  };
  return context;
}
