import React from "react";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
  Tooltip,
} from "@chakra-ui/react";

import { numbers } from "@kuzco/utils";

import KuzcoLogo from "~/assets/kuzco-white.svg";
import Card from "~/ui/elements/Card";

type CardsChartProps = {
  values: {
    title: string;
    value: string | number;
    subtitle: string;
    isKuzcoValue?: boolean;
  }[];
  templateColumns?: string;
};

const CardsChart: React.FC<CardsChartProps> = ({
  values,
  templateColumns = "repeat(3, 1fr)",
}) => {
  if (!values.length) {
    return <Card isLoading minHeight="386px" />;
  }

  return (
    <Grid
      templateColumns={{ base: "repeat(1, 1fr)", md: templateColumns }}
      gap={6}
    >
      {values.map(({ title, value, subtitle, isKuzcoValue }, index) => {
        return (
          <GridItem rowSpan={2} key={index}>
            <Card height="100%">
              <Text variant="18-semi" color="gray.400">
                {title}
              </Text>
              <Box height="24px" />
              <Tooltip
                placement="top"
                label={`Total: ${typeof value === "number" ? new Intl.NumberFormat().format(value) : "Unknown"}`}
              >
                {isKuzcoValue ? (
                  <Flex alignItems="center">
                    <Image
                      alt="Logo"
                      src={KuzcoLogo}
                      width="40px"
                      height="40px"
                    />
                    <Text
                      fontSize="56px"
                      lineHeight="56px"
                      fontWeight="500"
                      marginLeft="8px"
                    >
                      {value ? numbers.shorten(value) : value}
                    </Text>
                  </Flex>
                ) : (
                  <Text fontSize="56px" lineHeight="56px" fontWeight="500">
                    {value ? numbers.shorten(value) : value}
                  </Text>
                )}
              </Tooltip>
              <Box height="24px" />
              <Text variant="14-reg" color="gray.600">
                {subtitle}
              </Text>
            </Card>
          </GridItem>
        );
      })}
    </Grid>
  );
};

export default CardsChart;
