import z from "zod";

export const zAnalyticsEvents = z.enum([
  // Worker
  "CreateWorker",
  "RegisterWorker",
  "DeregisterWorker",
  "UpdateWorker",
  // Generation
  "CreateGeneration",
  "FinishGeneration",
  // Download
  "DownloadCLI",
  "DownloadConsole",
]);

export type AnalyticsEvents = z.infer<typeof zAnalyticsEvents>;
