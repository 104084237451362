import React from "react";
import { Box } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";

import { Content, Header } from "~/ui/layouts/Page.layout";
import SubNavigationButton from "../elements/SubNavigationButton";

// eslint-disable-next-line @typescript-eslint/ban-types
type SuperLayoutProps = {};

const SuperLayout: React.FC<SuperLayoutProps> = () => {
  return (
    <>
      <Header>
        <Box display="flex">
          <SubNavigationButton label="Users" link="/dashboard/super/users" />
          <SubNavigationButton
            label="Workers"
            link="/dashboard/super/workers"
          />
          <SubNavigationButton
            label="Generations"
            link="/dashboard/super/queries"
          />
        </Box>
      </Header>
      <Content maxWidth="100%">
        <Box height="24px" />
        <Outlet />
        <Box height="200px" />
      </Content>
    </>
  );
};

export default SuperLayout;
