import React from "react";
import { Flex } from "@chakra-ui/react";

import type { CreateWorkerParams, UpdateWorkerParams } from "@kuzco/models";

import type useWorker from "~/ui/hooks/useWorker.hook";
import Input from "../../../elements/Input";

type WorkerNameProps = {
  params: CreateWorkerParams | UpdateWorkerParams;
  setParams:
    | ReturnType<typeof useWorker>["setCreateWorkerParams"]
    | ReturnType<typeof useWorker>["setUpdateWorkerParams"];
  errors: { name?: string };
};

const WorkerName: React.FC<WorkerNameProps> = ({
  params,
  setParams,
  errors,
}) => {
  return (
    <Flex direction="column">
      <Input
        autoFocus
        type="text"
        label="Name"
        value={params.name}
        isInvalid={errors.name ? true : false}
        placeholder="Worker Name"
        onChange={(e) => {
          const name = e.currentTarget.value;
          // @ts-expect-error - need better types here.
          void setParams((params) => {
            return {
              ...params,
              name: name,
            };
          });
        }}
      />
    </Flex>
  );
};

export default WorkerName;
