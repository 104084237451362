import type { ButtonProps } from "@chakra-ui/react";
import { Box, Button } from "@chakra-ui/react";
import { FiPauseCircle, FiPlayCircle } from "react-icons/fi";

import { WorkerStatusSchema } from "@kuzco/models";

import { ConfirmModalTypes } from "~/ui/components/modal/Confirm.modal";
import { ModalTypes } from "~/ui/components/modal/Modal";
import { useAppConfig } from "~/ui/hooks/useAppConfig.hook";
import useModal from "~/ui/hooks/useModal.hook";
import useWorker from "~/ui/hooks/useWorker.hook";

export const LaunchInstanceButton = ({
  workerId,
  ...rest
}: { workerId: string } & ButtonProps) => {
  const { worker, startWorker, stopWorker, workerStatus } = useWorker(workerId);
  const { push, pop } = useModal();
  const { isTauri } = useAppConfig();

  if (!worker) {
    return null;
  }

  if (worker.installType === "CLI" || worker.installType === "Docker") {
    return (
      <Button
        variant="primary"
        size="sm"
        isLoading={startWorker.isLoading}
        onClick={() => {
          push({
            type: ModalTypes.RegisterWorker,
            props: {
              worker,
            },
          });
        }}
        {...rest}
      >
        <FiPlayCircle />
        <Box width="8px" />
        Launch Worker
      </Button>
    );
  }

  if (isTauri) {
    if (
      workerStatus === WorkerStatusSchema.Enum.Initializing ||
      workerStatus === WorkerStatusSchema.Enum.Online
    ) {
      return (
        <Button
          variant="ghost"
          size="sm"
          isLoading={stopWorker.isLoading}
          onClick={() => {
            push({
              type: ModalTypes.Confirm,
              props: {
                title: `Pause Worker`,
                type: ConfirmModalTypes.Success,
                confirmText: "Pause Worker",
                cancelText: "Cancel",
                message: `Are you sure you want to pause this worker? You will be able to resume it later from this page. Your existing points will not be affected. Join Discord for more information.`,
                confirm: async () => {
                  await stopWorker.mutateAsync();
                  pop();
                },
                cancel: () => {
                  pop();
                },
              },
            });
          }}
        >
          <FiPauseCircle />
          <Box width="8px" />
          Pause Worker
        </Button>
      );
    }

    return (
      <Button
        variant="ghost"
        size="sm"
        isLoading={startWorker.isLoading}
        onClick={async () => {
          await startWorker.mutateAsync();
        }}
        {...rest}
      >
        <FiPlayCircle />
        <Box width="8px" />
        Start Instance
      </Button>
    );
  }

  return null;
};
