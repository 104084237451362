import { Box, Flex, Grid, GridItem, Skeleton } from "@chakra-ui/react";

import { arrays } from "@kuzco/utils";

export default function WorkerInstancesSuspense() {
  return (
    <>
      <Grid
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(6, 1fr)" }}
        gap={6}
      >
        {arrays.range(6).map((i) => {
          return (
            <GridItem rowSpan={2} key={i}>
              <Skeleton height="74px" width="100%" borderRadius="lg" />
            </GridItem>
          );
        })}
      </Grid>
      <Box height="24px" />
      <Flex flexDirection="row" justifyContent="space-between">
        <Skeleton height="32px" width="128px" borderRadius="lg" />
        <Skeleton height="32px" width="128px" borderRadius="lg" />
      </Flex>
      <Box height="24px" />
      <Skeleton height="394px" maxWidth="988px" borderRadius="lg" />
    </>
  );
}
