function shorten(value: number | string): string {
  value = typeof value === "string" ? parseFloat(value) : value;

  let newValue = value;
  if (value >= 1000 && value < 1000000) {
    newValue = parseFloat((value / 1000).toFixed(1));
    return newValue + "K";
  } else if (value >= 1000000 && value < 1000000000) {
    newValue = parseFloat((value / 1000000).toFixed(1));
    return newValue + "M";
  } else if (value >= 1000000000) {
    newValue = parseFloat((value / 1000000000).toFixed(1));
    return newValue + "B";
  }
  return value.toString(); // Return the original number if it's < 1000
}

export const numbers = {
  shorten,
};
