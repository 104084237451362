import { z } from "zod";

export type TokenMetricsInputData = {
  totalUsage: number;
  date: string;
};

const TokenMetricsOutputDataSchema = z.object({
  type: z.string(),
  results: z.object({
    "$KZO Points": z.object({
      data: z.array(z.number()),
      days: z.array(z.string()),
      labels: z.array(z.string()),
      label: z.string(),
      aggregated_value: z.number(),
    }),
  }),
});

export type TokenMetricsOutputData = {
  type: string;
  results: {
    "$KZO Points": {
      data: number[];
      days: string[];
      labels: string[];
      label: string;
      aggregated_value: number;
    };
  };
};

const GenerationsInputDataSchema = z.object({
  count: z.number(),
  hour: z.number(),
  model: z.string(),
});
export type GenerationsInputData = z.infer<typeof GenerationsInputDataSchema>;

const GenerationsResultDataSchema = z.object({
  data: z.array(z.number()),
  days: z.array(z.string()),
  labels: z.array(z.string()),
  label: z.string(),
  aggregated_value: z.number(),
});
export type GenerationsResultData = z.infer<typeof GenerationsResultDataSchema>;

const GenerationsOutputDataSchema = z.object({
  type: z.string(),
  results: z.record(GenerationsResultDataSchema),
});

export type GenerationsOutputData = z.infer<typeof GenerationsOutputDataSchema>;

const GlobalMetricsResponseSchema = z
  .object({
    tokensLast24Hours: z.number(),
    tokensLast30DaysBarChart: TokenMetricsOutputDataSchema,
    generationsLast24HoursLineChart: GenerationsOutputDataSchema,
  })
  .nullable();
export type GlobalMetricsResponse = z.infer<typeof GlobalMetricsResponseSchema>;

const UserMetricsResponseSchema = z
  .object({
    generationsLast24Hours: z.number(),
    tokensLast24Hours: z.number(),
    tokensAllTime: z.number(),
    tokensLast30DaysBarChart: TokenMetricsOutputDataSchema,
    generationsLast24HoursLineChart: GenerationsOutputDataSchema,
  })
  .nullable();
export type UserMetricsResponse = z.infer<typeof UserMetricsResponseSchema>;

const WorkerMetricsResponseSchema = z
  .object({
    generationsLast24Hours: z.number(),
    tokensLast24Hours: z.number(),
    tokensAllTime: z.number(),
    tokensLast30DaysBarChart: TokenMetricsOutputDataSchema,
    generationsLast24HoursLineChart: GenerationsOutputDataSchema,
  })
  .nullable();
export type WorkerMetricsResponse = z.infer<typeof WorkerMetricsResponseSchema>;

const MetricsParamsSchema = z.object({
  userId: z.string().optional(),
  includeUserMetrics: z.boolean().optional(),
  workerId: z.string().optional(),
  startMillis: z.number().optional(),
});
export type MetricsParams = z.infer<typeof MetricsParamsSchema>;

export type MetricsService = {
  rpm(): Promise<number>;
  globalMetrics(request: MetricsParams): Promise<GlobalMetricsResponse>;
  userMetrics(request: MetricsParams): Promise<UserMetricsResponse>;
  workerMetrics(request: MetricsParams): Promise<WorkerMetricsResponse>;
};

export type MetricsStorageInterface = {
  rpm(): Promise<number>;
  tokensCount(params: MetricsParams): Promise<number>;
  tokensBarChart(params: MetricsParams): Promise<TokenMetricsOutputData>;
  generationsLineChart(params: MetricsParams): Promise<GenerationsOutputData>;
  generationsCount(params: MetricsParams): Promise<number>;
};
