import React from "react";

export const withSuspense = (
  WrappedComponent: React.FC,
  FallbackComponent: React.ReactNode | null = null,
) => {
  return () => {
    return (
      <React.Suspense fallback={FallbackComponent}>
        <WrappedComponent />
      </React.Suspense>
    );
  };
};
