import { z } from "zod";

/** ******************************************************************************
 *  Chat Completion Request System Message
 ******************************************************************************* */

export const ChatCompletionRequestSystemMessageSchema = z.object({
  content: z.string(),
  role: z.literal("system"),
  name: z.string().optional(),
});

const ChatCompletionRequestSystemMessageMongoSchema = {
  content: String,
  role: { type: String, default: "system" },
  name: String,
};

export type ChatCompletionRequestSystemMessage = z.infer<
  typeof ChatCompletionRequestSystemMessageSchema
>;

/** ******************************************************************************
 *  Chat Completion Request Message Content Part Text
 ******************************************************************************* */

export const ChatCompletionRequestMessageContentPartTextSchema = z.object({
  type: z.literal("text"),
  text: z.string(),
});

const ChatCompletionRequestMessageContentPartTextMongoSchema = {
  type: { type: String, default: "text" },
  text: String,
};

export type ChatCompletionRequestMessageContentPartText = z.infer<
  typeof ChatCompletionRequestMessageContentPartTextSchema
>;

/** ******************************************************************************
 *  Chat Completion Request Message Content Part Image
 ******************************************************************************* */

export const ChatCompletionRequestMessageContentPartImageSchema = z.object({
  type: z.literal("image_url"),
  image_url: z.object({
    url: z.string().url(),
    detail: z.enum(["auto", "low", "high"]).optional().default("auto"),
  }),
});

const ChatCompletionRequestMessageContentPartImageMongoSchema = {
  type: { type: String, default: "image_url" },
  image_url: {
    url: String,
    detail: { type: String, default: "auto" },
  },
};

export type ChatCompletionRequestMessageContentPartImage = z.infer<
  typeof ChatCompletionRequestMessageContentPartImageSchema
>;

/** ******************************************************************************
 *  Chat Completion Request Message Content Part
 ******************************************************************************* */

export const ChatCompletionRequestMessageContentPartSchema = z.union([
  ChatCompletionRequestMessageContentPartTextSchema,
  ChatCompletionRequestMessageContentPartImageSchema,
]);

export type ChatCompletionRequestMessageContentPart = z.infer<
  typeof ChatCompletionRequestMessageContentPartSchema
>;

/** ******************************************************************************
 *  Chat Completion Request User Message
 ******************************************************************************* */

export const ChatCompletionRequestUserMessageSchema = z.object({
  content: z.union([
    z.string(),
    z.array(ChatCompletionRequestMessageContentPartSchema),
  ]),
  role: z.literal("user"),
  name: z.string().optional(),
});

const ChatCompletionRequestUserMessageMongoSchema = {
  content: [
    ChatCompletionRequestMessageContentPartTextMongoSchema,
    ChatCompletionRequestMessageContentPartImageMongoSchema,
  ],
  role: { type: String, default: "user" },
  name: String,
};

export type ChatCompletionRequestUserMessage = z.infer<
  typeof ChatCompletionRequestUserMessageSchema
>;

/** ******************************************************************************
 *  Chat Completion Message Tool Call
 ******************************************************************************* */

export const ChatCompletionMessageToolCallSchema = z.object({
  id: z.string(),
  type: z.literal("function"),
  function: z.object({ name: z.string(), arguments: z.string() }),
});

const ChatCompletionMessageToolCallMongoSchema = {
  id: String,
  type: { type: String, default: "function" },
  function: {
    name: String,
    arguments: String,
  },
};

export type ChatCompletionMessageToolCall = z.infer<
  typeof ChatCompletionMessageToolCallSchema
>;

/** ******************************************************************************
 *  Chat Completion Message Tool Calls
 ******************************************************************************* */

export const ChatCompletionMessageToolCallsSchema = z.array(
  ChatCompletionMessageToolCallSchema,
);

export type ChatCompletionMessageToolCalls = z.infer<
  typeof ChatCompletionMessageToolCallsSchema
>;

/** ******************************************************************************
 *  Chat Completion Request Assistant Message
 ******************************************************************************* */

export const ChatCompletionRequestAssistantMessageSchema = z.object({
  content: z.string().nullish(),
  role: z.literal("assistant"),
  name: z.string().optional(),
  tool_calls: ChatCompletionMessageToolCallsSchema.optional(),
  function_call: z
    .object({ arguments: z.string(), name: z.string() })
    .optional(),
});

const ChatCompletionRequestAssistantMessageMongoSchema = {
  content: String,
  role: { type: String, default: "assistant" },
  name: String,
  tool_calls: [ChatCompletionMessageToolCallMongoSchema],
  function_call: {
    arguments: String,
    name: String,
  },
};

export type ChatCompletionRequestAssistantMessage = z.infer<
  typeof ChatCompletionRequestAssistantMessageSchema
>;

/** ******************************************************************************
 *  Chat Completion Request Tool Message
 ******************************************************************************* */

export const ChatCompletionRequestToolMessageSchema = z.object({
  role: z.literal("tool"),
  content: z.string(),
  tool_call_id: z.string(),
});

const ChatCompletionRequestToolMessageMongoSchema = {
  role: { type: String, default: "tool" },
  content: String,
  tool_call_id: String,
};

export type ChatCompletionRequestToolMessage = z.infer<
  typeof ChatCompletionRequestToolMessageSchema
>;

/** ******************************************************************************
 *  Chat Completion Request Function Message
 ******************************************************************************* */

export const ChatCompletionRequestFunctionMessageSchema = z.object({
  role: z.literal("function"),
  content: z.string().nullable(),
  name: z.string(),
});

const ChatCompletionRequestFunctionMessageMongoSchema = {
  role: { type: String, default: "function" },
  content: String,
  name: String,
};

export type ChatCompletionRequestFunctionMessage = z.infer<
  typeof ChatCompletionRequestFunctionMessageSchema
>;

/** ******************************************************************************
 *  Chat Completion Request
 ******************************************************************************* */

export const ChatCompletionRequestMessageSchema = z.union([
  ChatCompletionRequestSystemMessageSchema,
  ChatCompletionRequestUserMessageSchema,
  ChatCompletionRequestAssistantMessageSchema,
  ChatCompletionRequestToolMessageSchema,
  ChatCompletionRequestFunctionMessageSchema,
]);

export type ChatCompletionRequestMessage = z.infer<
  typeof ChatCompletionRequestMessageSchema
>;

/** ******************************************************************************
 *  Function Parameters
 ******************************************************************************* */

export const FunctionParametersSchema = z.object({}).partial();

const FunctionParametersMongoSchema = Object;

export type FunctionParameters = z.infer<typeof FunctionParametersSchema>;

/** ******************************************************************************
 *  Function Object
 ******************************************************************************* */

export const FunctionObjectSchema = z.object({
  description: z.string().optional(),
  name: z.string(),
  parameters: FunctionParametersSchema.optional(),
});

const FunctionObjectMongoSchema = {
  description: String,
  name: String,
  parameters: FunctionParametersMongoSchema,
};

export type FunctionObject = z.infer<typeof FunctionObjectSchema>;

/** ******************************************************************************
 *  Chat Completion Tool
 ******************************************************************************* */

export const ChatCompletionToolSchema = z.object({
  type: z.literal("function"),
  function: FunctionObjectSchema,
});

const ChatCompletionToolMongoSchema = {
  type: { type: String, default: "function" },
  function: FunctionObjectMongoSchema,
};

export type ChatCompletionTool = z.infer<typeof ChatCompletionToolSchema>;

/** ******************************************************************************
 *  Chat Completion Named Tool Choice
 ******************************************************************************* */

export const ChatCompletionNamedToolChoiceSchema = z.object({
  type: z.literal("function"),
  function: z.object({ name: z.string() }),
});

const ChatCompletionNamedToolChoiceMongoSchema = {
  type: { type: String, default: "function" },
  function: { name: String },
};

export type ChatCompletionNamedToolChoice = z.infer<
  typeof ChatCompletionNamedToolChoiceSchema
>;

/** ******************************************************************************
 *  Chat Completion Tool Choice Option
 ******************************************************************************* */

export const ChatCompletionToolChoiceOptionSchema = z.union([
  z.enum(["none", "auto"]),
  ChatCompletionNamedToolChoiceSchema,
]);

const ChatCompletionFunctionCallOptionMongoSchema = {
  name: String,
};

export type ChatCompletionToolChoiceOption = z.infer<
  typeof ChatCompletionToolChoiceOptionSchema
>;

/** ******************************************************************************
 *  Chat Completion Function Call Option
 ******************************************************************************* */

export const ChatCompletionFunctionCallOption = z.object({ name: z.string() });

const ChatCompletionFunctionsMongoSchema = {
  description: String,
  name: String,
  parameters: FunctionParametersMongoSchema,
};

export type ChatCompletionFunctionCall = z.infer<
  typeof ChatCompletionFunctionCallOption
>;

/** ******************************************************************************
 *  Chat Completion Functions
 ******************************************************************************* */

export const ChatCompletionFunctionsSchema = z.object({
  description: z.string().optional(),
  name: z.string(),
  parameters: FunctionParametersSchema.optional(),
});

export type ChatCompletionFunctions = z.infer<
  typeof ChatCompletionFunctionsSchema
>;

/** ******************************************************************************
 *  Chat Completion Request
 ******************************************************************************* */

export const CreateChatCompletionRequestSchema = z.object({
  messages: z.array(ChatCompletionRequestMessageSchema).min(1),
  model: z.string(),
  frequency_penalty: z.number().gte(-2).lte(2).nullish(),
  instance_id: z.string().nullish(),
  logit_bias: z.record(z.number().int()).nullish(),
  logprobs: z.boolean().nullish(),
  top_logprobs: z.number().int().gte(0).lte(5).nullish(),
  max_tokens: z.number().int().nullish(),
  n: z.number().int().gte(1).lte(128).nullish().default(1),
  presence_penalty: z.number().gte(-2).lte(2).nullish(),
  response_format: z
    .object({ type: z.enum(["text", "json_object"]).default("text") })
    .partial()
    .optional(),
  seed: z
    .number()
    .int()
    .gte(-9223372036854776000)
    .lte(9223372036854776000)
    .nullish(),
  stop: z.union([z.string(), z.array(z.string())]).optional(),
  stream: z.boolean().nullish(),
  temperature: z.number().gte(0).lte(2).nullish().default(1),
  top_p: z.number().gte(0).lte(1).nullish().default(1),
  tools: z.array(ChatCompletionToolSchema).optional(),
  tool_choice: ChatCompletionToolChoiceOptionSchema.optional(),
  user: z.string().optional(),
  function_call: z
    .union([z.enum(["none", "auto"]), ChatCompletionFunctionCallOption])
    .optional(),
  functions: z.array(ChatCompletionFunctionsSchema).min(1).max(128).optional(),
});

export const CreateChatCompletionRequestMongoSchema = {
  messages: [
    ChatCompletionRequestSystemMessageMongoSchema,
    ChatCompletionRequestUserMessageMongoSchema,
    ChatCompletionRequestAssistantMessageMongoSchema,
    ChatCompletionRequestToolMessageMongoSchema,
    ChatCompletionRequestFunctionMessageMongoSchema,
  ],
  model: String,
  frequency_penalty: Number,
  instance_id: String,
  logit_bias: { type: Map, of: Number },
  logprobs: Boolean,
  top_logprobs: Number,
  max_tokens: Number,
  n: { type: Number, default: 1 },
  presence_penalty: Number,
  response_format: {
    type: { type: String, default: "text" },
  },
  seed: Number,
  stop: [String],
  stream: Boolean,
  temperature: { type: Number, default: 1 },
  top_p: { type: Number, default: 1 },
  tools: [ChatCompletionToolMongoSchema],
  tool_choice: [ChatCompletionNamedToolChoiceMongoSchema],
  user: String,
  function_call: [ChatCompletionFunctionCallOptionMongoSchema],
  functions: [ChatCompletionFunctionsMongoSchema],
};

export type CreateChatCompletionRequest = z.infer<
  typeof CreateChatCompletionRequestSchema
>;

/** ******************************************************************************
 *  Chat Completion Response Message
 ******************************************************************************* */

export const ChatCompletionResponseMessageSchema = z.object({
  content: z.string().nullable(),
  tool_calls: ChatCompletionMessageToolCallsSchema.optional(),
  role: z.literal("assistant"),
  function_call: z
    .object({ arguments: z.string(), name: z.string() })
    .optional(),
});

const ChatCompletionResponseMessageMongoSchema = {
  content: String,
  tool_calls: [ChatCompletionMessageToolCallMongoSchema],
  role: { type: String, default: "assistant" },
  function_call: {
    arguments: String,
    name: String,
  },
};

export type ChatCompletionResponseMessage = z.infer<
  typeof ChatCompletionResponseMessageSchema
>;

/** ******************************************************************************
 *  Chat Completion Token Logprob
 ******************************************************************************* */

export const ChatCompletionTokenLogprobSchema = z.object({
  token: z.string(),
  logprob: z.number(),
  bytes: z.array(z.number()).nullable(),
  top_logprobs: z.array(
    z.object({
      token: z.string(),
      logprob: z.number(),
      bytes: z.array(z.number()).nullable(),
    }),
  ),
});

const ChatCompletionTokenLogprobMongoSchema = {
  token: String,
  logprob: Number,
  bytes: [Number],
  top_logprobs: [
    {
      token: String,
      logprob: Number,
      bytes: [Number],
    },
  ],
};

export type ChatCompletionTokenLogprob = z.infer<
  typeof ChatCompletionTokenLogprobSchema
>;

/** ******************************************************************************
 *  Completion Usage
 ******************************************************************************* */

export const CompletionUsageSchema = z.object({
  completion_tokens: z.number().int(),
  prompt_tokens: z.number().int(),
  total_tokens: z.number().int(),
});

export const CompletionUsageMongoSchema = {
  completion_tokens: Number,
  prompt_tokens: Number,
  total_tokens: Number,
};

export type CompletionUsage = z.infer<typeof CompletionUsageSchema>;

/** ******************************************************************************
 *  Create Chat Completion Response
 ******************************************************************************* */

export const CreateChatCompletionResponseSchema = z.object({
  id: z.string(),
  choices: z.array(
    z.object({
      finish_reason: z.enum([
        "stop",
        "length",
        "tool_calls",
        "content_filter",
        "function_call",
      ]),
      index: z.number().int(),
      message: ChatCompletionResponseMessageSchema,
      logprobs: z
        .object({
          content: z.array(ChatCompletionTokenLogprobSchema).nullable(),
        })
        .nullish(),
    }),
  ),
  created: z.number().int(),
  model: z.string(),
  system_fingerprint: z.string().optional(),
  object: z.literal("chat.completion"),
  usage: CompletionUsageSchema.optional(),
});

export const CreateChatCompletionResponseMongoSchema = {
  id: String,
  choices: [
    {
      finish_reason: String,
      index: Number,
      message: ChatCompletionResponseMessageMongoSchema,
      logprobs: {
        content: [ChatCompletionTokenLogprobMongoSchema],
      },
    },
  ],
  created: Number,
  model: String,
  system_fingerprint: String,
  object: { type: String, default: "chat.completion" },
  usage: CompletionUsageMongoSchema,
};

export type CreateChatCompletionResponse = z.infer<
  typeof CreateChatCompletionResponseSchema
>;

/** ******************************************************************************
 *  Create Chat Completion Chunk Response
 ******************************************************************************* */

export const ChatCompletionChunkFunctionCallSchema = z.object({
  arguments: z.string().optional(),
  name: z.string().optional(),
});

export type ChatCompletionChunkFunctionCall = z.infer<
  typeof ChatCompletionChunkFunctionCallSchema
>;

export const ChatCompletionChunkFunctionSchema = z.object({
  arguments: z.string().optional(),
  name: z.string().optional(),
});

export type ChatCompletionChunkFunction = z.infer<
  typeof ChatCompletionChunkFunctionSchema
>;

export const ChatCompletionChunkLogprobsSchema = z.object({
  content: z.array(ChatCompletionTokenLogprobSchema).nullable(),
});

export type ChatCompletionChunkLogprobs = z.infer<
  typeof ChatCompletionChunkLogprobsSchema
>;

export const ChatCompletionChunkToolCallSchema = z.object({
  index: z.number().int(),
  id: z.string().optional(),
  function: ChatCompletionChunkFunctionSchema.optional(),
  type: z.literal("function").optional(),
});

export type ChatCompletionChunkToolCall = z.infer<
  typeof ChatCompletionChunkToolCallSchema
>;

export const ChatCompletionChunkDeltaSchema = z.object({
  content: z.string().nullable(),
  function_call: ChatCompletionChunkFunctionCallSchema.optional(),
  role: z
    .union([
      z.literal("system"),
      z.literal("user"),
      z.literal("assistant"),
      z.literal("tool"),
    ])
    .optional(),
  tool_calls: z.array(ChatCompletionChunkToolCallSchema).optional(),
});

export type ChatCompletionChunkDelta = z.infer<
  typeof ChatCompletionChunkDeltaSchema
>;

export const ChatCompletionChunkChoiceSchema = z.object({
  delta: ChatCompletionChunkDeltaSchema,
  finish_reason: z.union([
    z.literal("stop"),
    z.literal("length"),
    z.literal("tool_calls"),
    z.literal("content_filter"),
    z.literal("function_call"),
    z.null(),
  ]),
  index: z.number().int(),
  logprobs: ChatCompletionChunkLogprobsSchema.optional(),
});

export type ChatCompletionChunkChoice = z.infer<
  typeof ChatCompletionChunkChoiceSchema
>;

export const ChatCompletionChunkSchema = z.object({
  id: z.string(),
  choices: z.array(ChatCompletionChunkChoiceSchema),
  created: z.number().int(),
  model: z.string(),
  object: z.literal("chat.completion.chunk"),
  system_fingerprint: z.string().optional(),
});

export type ChatCompletionChunk = z.infer<typeof ChatCompletionChunkSchema>;
