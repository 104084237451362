import type { ButtonProps } from "@chakra-ui/react";
import { Button, Text } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";

type SubNavigationButtonProps = {
  label: string;
  link: string;
} & ButtonProps;

export const SubNavigationButton = (props: SubNavigationButtonProps) => {
  const location = useLocation();
  const { label, link, ...buttonProps } = props;

  const active = link ? location.pathname.includes(link) : false;
  const color = active ? "gray.100" : "gray.500";

  return (
    <Link to={link} style={{ width: "100%", marginRight: "16px" }}>
      <Button
        width="100%"
        justifyContent="start"
        backgroundColor="transparent"
        size="sm"
        padding="0 4px"
        color={color}
        _hover={{
          cursor: "pointer",
          color: "gray.100",
          backgroundColor: "transparent",
        }}
        {...buttonProps}
      >
        <Text variant="14-semi">{label}</Text>
      </Button>
    </Link>
  );
};

export default SubNavigationButton;
