import { Suspense } from "react";
import { Box, Button, Flex, Grid, Text } from "@chakra-ui/react";
import { FiAperture } from "react-icons/fi";
import { useParams } from "react-router-dom";

import { api } from "~/lib/trpc";
import WorkerInfo from "~/ui/components/worker/WorkerInfo";
import useIsMobile from "~/ui/hooks/useIsMobile.hook";
import useWorker from "~/ui/hooks/useWorker.hook";
import InstanceCard from "../../../ui/components/instance/InstanceCard";
import NoContentCard from "../../../ui/components/NoContentCard";
import WorkerInstancesSuspense from "../../../ui/suspense/WorkerInstances.suspense";

const WorkerInstancesComponent: React.FC = () => {
  const isMobile = useIsMobile();
  const { workerId = "" } = useParams();
  const { worker, refreshWorkerList } = useWorker(workerId);
  const [data] = api.metrics.worker.useSuspenseQuery({
    workerId,
  });

  return (
    <>
      <Box height="24px" />
      {data == null ? null : (
        <>
          {worker != null && (
            <WorkerInfo worker={worker} columns={isMobile ? 1 : 6} cards />
          )}
          <Box height="24px" />
          <Flex justifyContent="space-between" alignItems="center">
            <Text fontSize="xl" fontWeight="bold">
              Instances ({worker?.instances.length ?? 0})
            </Text>
            <Button
              variant="secondary"
              onClick={() => refreshWorkerList.mutate()}
              size="md"
              isLoading={refreshWorkerList.isLoading}
            >
              Refresh List
            </Button>
          </Flex>
          <Box height="16px" />
          {(worker?.instances.length ?? 0) > 0 ? (
            <Grid templateColumns="repeat(1, 1fr)" gap={6}>
              {worker?.instances.map((instance) => {
                return <InstanceCard key={instance._id} instance={instance} />;
              })}
            </Grid>
          ) : (
            <NoContentCard
              Icon={FiAperture}
              Text={() => (
                <Text>
                  No running instances found :( <br />
                  Launch an instance to start earning rewards.
                </Text>
              )}
              height="400px"
              /**
               * Todo(sam): Figure out why this causes a crash
               */
              Button={() =>
                // <LaunchInstanceButton
                //   variant="primary"
                //   width="300px"
                //   workerId={workerId}
                //   size="md"
                // />
                null
              }
            />
          )}
        </>
      )}
    </>
  );
};

export default function WorkerInstancesPage() {
  return (
    <Suspense
      fallback={
        <div>
          <Box height="24px" />
          <WorkerInstancesSuspense />
        </div>
      }
    >
      <WorkerInstancesComponent />
    </Suspense>
  );
}
