import { z } from "zod";

import { WorkerInstallTypeSchema, WorkerSchema } from "../schema/WorkerSchema";
import { authedServiceRequest, serviceResponse } from "./BaseService";
import { InstanceSchema, InstanceStatusSchema } from "./instance.models";

export const WorkerStatusSchema = z.enum(["Online", "Initializing", "Offline"]);

/**
 * TODO (sam): make a util file to store worker specific logic
 */
export const workerStatus = ({
  instances,
}: {
  instances: WorkerWithInstances["instances"];
}): WorkerStatus => {
  /**
   * If all instances are initializing, worker status is Initializing
   */
  const isInitializing =
    instances.length &&
    instances.every(({ status }) => {
      return status === InstanceStatusSchema.Enum.Initializing;
    });

  if (isInitializing) return WorkerStatusSchema.Enum.Initializing;

  /**
   * If any instance is running, worker status is Online
   */
  const isOnline = instances.some(
    ({ status }) => status === InstanceStatusSchema.Enum.Running,
  );

  if (isOnline) return WorkerStatusSchema.Enum.Online;

  /**
   * If no instances are running or initializing, worker status is Offline
   */
  return WorkerStatusSchema.Enum.Offline;
};

const WorkerWithInstancesSchema = WorkerSchema.merge(
  z.object({
    instances: z.array(InstanceSchema),
  }),
);

export const WorkerMongoSchema = {
  _id: { type: String, required: true },
  userId: { type: String, required: true },
  teamId: { type: String, required: true },
  name: { type: String, required: true },
  installType: { type: String, required: true },
  registrationCode: { type: String, required: false },
  createdAt: { type: Number, required: true },
  updatedAt: { type: Number, required: true },
  isArchived: { type: Boolean, required: false },
  isDisabled: { type: Boolean, required: false },
  shouldAutoStart: { type: Boolean, required: false },
};

export const MAX_WORKERS = 30;

export type Worker = z.infer<typeof WorkerSchema>;
export type WorkerWithInstances = z.infer<typeof WorkerWithInstancesSchema>;
export type WorkerStatus = z.infer<typeof WorkerStatusSchema>;
export type WorkerInstallType = z.infer<typeof WorkerInstallTypeSchema>;

/** ******************************************************************************
 *  Worker Service
 ******************************************************************************* */
export type WorkerService = {
  create(request: CreateWorkerRequest): Promise<CreateWorkerResponse>;
  update(request: UpdateWorkerRequest): Promise<UpdateWorkerResponse>;
  setAutoStart(
    request: SetWorkerAutoStartRequest,
  ): Promise<SetWorkerAutoStartResponse>;
  get(request: GetWorkerRequest): Promise<GetWorkerResponse>;
  list(request: ListWorkersRequest): Promise<ListWorkersResponse>;
  count(request: CountWorkersRequest): Promise<CountWorkersResponse>;
  archive(request: ArchiveWorkerRequest): Promise<ArchiveWorkerResponse>;
};

/** ******************************************************************************
 *  Create Worker
 ******************************************************************************* */

export const createWorkerParams = z.object({
  name: z.string(),
  installType: WorkerInstallTypeSchema,
});

export const createWorkerRequest = authedServiceRequest.merge(
  z.object({
    params: createWorkerParams,
  }),
);

export const createWorkerResponse = serviceResponse.merge(
  z.object({
    worker: WorkerWithInstancesSchema.optional(),
  }),
);

export type CreateWorkerParams = z.infer<typeof createWorkerParams>;
export type CreateWorkerRequest = z.infer<typeof createWorkerRequest>;
export type CreateWorkerResponse = z.infer<typeof createWorkerResponse>;

/** ******************************************************************************
 *  Update Worker
 ******************************************************************************* */

export const updateWorkerParams = z.object({
  workerId: z.string(),
  name: z.string(),
});

export const updateWorkerRequest = authedServiceRequest.merge(
  z.object({
    params: updateWorkerParams,
  }),
);

export const updateWorkerResponse = serviceResponse.merge(
  z.object({
    worker: WorkerWithInstancesSchema.nullable().optional(),
  }),
);

export type UpdateWorkerParams = z.infer<typeof updateWorkerParams>;
export type UpdateWorkerRequest = z.infer<typeof updateWorkerRequest>;
export type UpdateWorkerResponse = z.infer<typeof updateWorkerResponse>;

/** ******************************************************************************
 *  Auto Start
 ******************************************************************************* */

export const setWorkerAutoStartParams = z.object({
  workerId: z.string(),
  autoStart: z.boolean(),
});

export const setWorkerAutoStartRequest = authedServiceRequest.merge(
  z.object({
    params: setWorkerAutoStartParams,
  }),
);

export const setWorkerAutoStartResponse = serviceResponse.merge(
  z.object({
    worker: WorkerWithInstancesSchema.nullable().optional(),
  }),
);

export type SetWorkerAutoStartParams = z.infer<typeof setWorkerAutoStartParams>;
export type SetWorkerAutoStartRequest = z.infer<
  typeof setWorkerAutoStartRequest
>;
export type SetWorkerAutoStartResponse = z.infer<
  typeof setWorkerAutoStartResponse
>;

/** ******************************************************************************
 *  Get Worker
 ******************************************************************************* */

export const getWorkerParams = z.object({
  workerId: z.string(),
});

export const getWorkerRequest = authedServiceRequest.merge(
  z.object({
    params: getWorkerParams,
  }),
);

export const getWorkerResponse = serviceResponse.merge(
  z.object({
    worker: WorkerWithInstancesSchema.nullable().optional(),
  }),
);

export type GetWorkerParams = z.infer<typeof getWorkerParams>;
export type GetWorkerRequest = z.infer<typeof getWorkerRequest>;
export type GetWorkerResponse = z.infer<typeof getWorkerResponse>;

/** ******************************************************************************
 *  List Workers
 ******************************************************************************* */

export const listWorkersParams = z.undefined();

export const listWorkersRequest = authedServiceRequest.merge(
  z.object({
    params: listWorkersParams,
  }),
);

export const listWorkersResponse = serviceResponse.merge(
  z.object({
    workers: z.array(WorkerWithInstancesSchema).optional(),
  }),
);

export type ListWorkersParams = z.infer<typeof listWorkersParams>;
export type ListWorkersRequest = z.infer<typeof listWorkersRequest>;
export type ListWorkersResponse = z.infer<typeof listWorkersResponse>;

/** ******************************************************************************
 *  Count Workers
 ******************************************************************************* */

export const countWorkersParams = z.undefined();

export const countWorkersRequest = authedServiceRequest.merge(
  z.object({
    params: countWorkersParams,
  }),
);

export const countWorkersResponse = serviceResponse.merge(
  z.object({
    count: z.number().optional(),
  }),
);

export type CountWorkersParams = z.infer<typeof countWorkersParams>;
export type CountWorkersRequest = z.infer<typeof countWorkersRequest>;
export type CountWorkersResponse = z.infer<typeof countWorkersResponse>;

/** ******************************************************************************
 *  Archive Worker
 ******************************************************************************* */

export const archiveWorkerParams = z.object({
  workerId: z.string(),
});

export const archiveWorkerRequest = authedServiceRequest.merge(
  z.object({
    params: archiveWorkerParams,
  }),
);

export const archiveWorkerResponse = serviceResponse.merge(
  z.object({
    worker: WorkerWithInstancesSchema.optional(),
  }),
);

export type ArchiveWorkerParams = z.infer<typeof archiveWorkerParams>;
export type ArchiveWorkerRequest = z.infer<typeof archiveWorkerRequest>;
export type ArchiveWorkerResponse = z.infer<typeof archiveWorkerResponse>;
