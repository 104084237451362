import { Box, Grid, GridItem, Skeleton } from "@chakra-ui/react";

import { arrays } from "@kuzco/utils";

export default function NetworkSummarySuspense() {
  return (
    <>
      <Grid
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
        gap={6}
      >
        {arrays.range(3).map((i) => {
          return (
            <GridItem rowSpan={2} key={i}>
              <Skeleton height="200px" width="100%" borderRadius="lg" />
            </GridItem>
          );
        })}
      </Grid>
      <Box height="24px" />
      <Skeleton height="394px" maxWidth="1000px" borderRadius="lg" />
      <Box height="24px" />
      <Skeleton height="394px" maxWidth="1000px" borderRadius="lg" />
    </>
  );
}
