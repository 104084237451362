import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";

import useIsMobile from "~/ui/hooks/useIsMobile.hook";
import { Content, Header, Title } from "~/ui/layouts/Page.layout";

type SettingsLayoutProps = {
  children: React.ReactNode;
  title?: string;
  description?: React.ReactNode | string;
  Action?: React.FC;
  After?: React.FC;
  container?: boolean;
};

const SettingsLayout: React.FC<SettingsLayoutProps> = ({
  children,
  title,
  description,
  Action,
  After,
  container = true,
}) => {
  const isMobile = useIsMobile();

  return (
    <>
      <Header>
        <Flex alignItems="center">
          <Title>{title}</Title>
        </Flex>
      </Header>
      {container ? (
        <Content width={isMobile ? "100%" : "900px"}>
          <Box height="24px" />
          <Box
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            justifyContent="space-between"
            alignItems={isMobile ? "flex-start" : "center"}
            marginBottom="24px"
            padding={isMobile ? "16px 16px 0" : "24px 24px 0"}
          >
            <Box>
              <Text variant="24-reg" color="white">
                {title}
              </Text>
              <Box height="4px" />
              <Text variant="14-reg" color="gray.400" className="mb-2 mt-2">
                {description}
              </Text>
            </Box>
            {Action ? <Action /> : null}
          </Box>
          {children}
          {After ? <After /> : null}
        </Content>
      ) : (
        children
      )}
    </>
  );
};

export default SettingsLayout;
