import type { InputProps as ChakraInputProps } from "@chakra-ui/react";
import React from "react";
import { Input as ChakraInput, FormControl } from "@chakra-ui/react";

import Label from "./Label";

export type InputProps = ChakraInputProps & {
  label?: string;
  raw?: boolean;
  optional?: boolean;
};

const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { label, raw, optional = false, ...rest } = props;
  const input = (
    <ChakraInput
      ref={ref}
      backgroundColor="gray.1100"
      border="1px solid"
      borderColor="gray.700"
      color="white"
      _placeholder={{
        color: "gray.600",
      }}
      _hover={{
        borderColor: "gray.600",
      }}
      _focusVisible={{
        borderColor: "gray.500",
      }}
      {...rest}
    />
  );

  if (raw) {
    return <>{input}</>;
  }

  return (
    <FormControl width={props.width}>
      {label ? <Label optional={optional}>{label}</Label> : null}
      {input}
    </FormControl>
  );
});

Input.displayName = "Input";

export default Input;
