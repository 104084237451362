import type { RouterOutput } from "~/lib/trpc";
import { api } from "~/lib/trpc";

export enum VersionStatus {
  Current = "Current",
  PartiallyOutdated = "Partially Outdated",
  FullyOutdated = "Fully Outdated",
  NoVersion = "No Version",
}

const isCurrentVersion = (
  version: string,
  latestVersions: RouterOutput["system"]["bucketVersions"],
) => {
  const isCLI = version.includes("cli");
  const isConsole = version.includes("console");

  if (version.includes("dev")) {
    return true;
  }

  if (isCLI) {
    return version.includes(latestVersions.cliVersion);
  }

  if (isConsole) {
    return version.includes(latestVersions.tauriVersion);
  }

  return false;
};

export function useVersionStatus(version: string): VersionStatus {
  const [latestVersions] = api.system.bucketVersions.useSuspenseQuery();

  const isCurrent = isCurrentVersion(version, latestVersions);

  if (isCurrent) {
    return VersionStatus.Current;
  }

  return VersionStatus.FullyOutdated;
}

export function useWorkerVersionStatus(versions: string[]): {
  version: string;
  versionStatus: VersionStatus;
} {
  const [latestVersions] = api.system.bucketVersions.useSuspenseQuery();

  if (versions.length === 0) {
    return {
      version: "--",
      versionStatus: VersionStatus.NoVersion,
    };
  }

  const values = versions.map((version) => ({
    version,
    isCurrent: isCurrentVersion(version, latestVersions),
  }));

  const someTrue = values.some((value) => value.isCurrent);
  const someFalse = values.some((value) => !value.isCurrent);

  if (someTrue && someFalse) {
    return {
      version: values.find((value) => value.isCurrent)?.version ?? "--",
      versionStatus: VersionStatus.PartiallyOutdated,
    };
  }

  if (someFalse) {
    return {
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      version: values.find((value) => !value.isCurrent)?.version || "--",
      versionStatus: VersionStatus.FullyOutdated,
    };
  }

  return {
    version: values.find((value) => value.isCurrent)?.version ?? "--",
    versionStatus: VersionStatus.Current,
  };
}
