import type { BoxProps } from "@chakra-ui/react";
import React from "react";
import { Text } from "@chakra-ui/react";

import type { RouterOutputs } from "@kuzco/api";
import { AreaChart } from "@kuzco/metrics";
import { numbers } from "@kuzco/utils";

import Card from "~/ui/elements/Card";
import DisabledChart from "./Disabled.chart";

type GenerationCountMetric = RouterOutputs["metrics"]["global"];

type GenerationCountChartProps = {
  title?: string;
  metric?: GenerationCountMetric;
  isDisabled?: boolean;
} & BoxProps;

const GenerationCountChart: React.FC<GenerationCountChartProps> = ({
  metric,
  title,
  isDisabled,
  ...rest
}) => {
  if (isDisabled) {
    return <DisabledChart {...rest} />;
  }

  if (!metric) {
    return <Card isLoading minHeight="386px" {...rest} />;
  }

  return (
    <Card {...rest}>
      <Text variant="18-semi" color="gray.400">
        {title ?? "Inference Requests"}
      </Text>
      {/* @ts-expect-error - need better types here */}
      <AreaChart
        {...metric}
        colors={["green", "blue", "yellow", "purple"]}
        // showLegend={false}
        valueFormatter={(number) => numbers.shorten(number)}
      />
    </Card>
  );
};

export default GenerationCountChart;
