const DEFAULT_LOG_LEVEL = "info";

const LOG_LEVEL =
  typeof process !== "undefined"
    ? process.env.LOG_LEVEL ?? DEFAULT_LOG_LEVEL
    : DEFAULT_LOG_LEVEL;

export enum LogLevel {
  Log = 0,
  Info = 1,
  Profile = 2,
  Warn = 3,
  Error = 4,
  Counter = 5,
}

export type LogManagerParams = {
  name: string;
  level?: LogLevel;
  hideDetails?: boolean;
  defaultProperties?: Record<string, unknown>;
};

export type LogManager = {
  /**
   * Allows setting a default property that will be added to every log message.
   * If the value is undefined, the property will be removed.
   *
   * @param key - The property name
   * @param value - The property value
   */
  setDefaultProperty(key: string, value?: unknown): void;

  setLogLevel(level: LogLevel): void;

  profile(message: string, properties?: Record<string, unknown>): void;
  log(message: string, properties?: Record<string, unknown>): void;
  info(message: string, properties?: Record<string, unknown>): void;
  warn(message: string, properties?: Record<string, unknown>): void;
  error(
    message: string,
    error?: unknown,
    properties?: Record<string, unknown>,
  ): void;
  counter(message: string, properties?: Record<string, unknown>): void;
  getSubLogger(args: LogManagerParams): LogManager;
};

export const combineLoggers = (loggers: LogManager[]): LogManager => {
  return {
    log: (message: string, properties?: Record<string, unknown>) =>
      loggers.forEach((logger) => logger.log(message, properties)),
    profile: (message: string, properties?: Record<string, unknown>) =>
      loggers.forEach((logger) => logger.profile(message, properties)),
    info: (message: string, properties?: Record<string, unknown>) =>
      loggers.forEach((logger) => logger.info(message, properties)),
    warn: (message: string, properties?: Record<string, unknown>) =>
      loggers.forEach((logger) => logger.warn(message, properties)),
    error: (
      message: string,
      error?: unknown,
      properties?: Record<string, unknown>,
    ) => loggers.forEach((logger) => logger.error(message, error, properties)),
    counter: (message: string, properties?: Record<string, unknown>) =>
      loggers.forEach((logger) => logger.counter(message, properties)),
    getSubLogger: ({ name }: LogManagerParams) => {
      const subLoggers = loggers.map((logger) => logger.getSubLogger({ name }));
      return combineLoggers(subLoggers);
    },
    setDefaultProperty: (key: string, value?: unknown) =>
      loggers.forEach((logger) => logger.setDefaultProperty(key, value)),
    setLogLevel: (level: LogLevel) =>
      loggers.forEach((logger) => logger.setLogLevel(level)),
  };
};

export function getLogLevel(level: string): LogLevel {
  switch (level.toLowerCase()) {
    case "log":
      return LogLevel.Log;
    case "profile":
      return LogLevel.Profile;
    case "info":
      return LogLevel.Info;
    case "warn":
      return LogLevel.Warn;
    case "error":
      return LogLevel.Error;
    case "counter":
      return LogLevel.Counter;
    default:
      throw new Error(`Unknown log level: ${level}`);
  }
}

export class ConsoleLogManager implements LogManager {
  public static shouldLog(
    selectedLevel: LogLevel,
    messageLevel: LogLevel,
  ): boolean {
    return messageLevel >= selectedLevel;
  }

  public static getTimeStamp() {
    return new Date().toISOString();
  }

  public name: string;
  public level: LogLevel;
  private hideDetails: boolean;
  private defaultProperties: Record<string, unknown>;

  constructor(params: LogManagerParams) {
    this.name = params.name;
    this.level = params.level ?? getLogLevel(LOG_LEVEL);
    this.hideDetails = params.hideDetails ?? false;
    this.defaultProperties = {
      ...params.defaultProperties,
      name: params.name,
    };
  }

  public setDefaultProperty(key: string, value?: unknown): void {
    if (value) {
      this.defaultProperties[key] = value;
    } else {
      if (this.defaultProperties[key]) {
        delete this.defaultProperties[key];
      }
    }
  }

  public setLogLevel(level: LogLevel): void {
    this.level = level;
  }

  private header() {
    return `[${this.name}]:`;
  }

  private print({
    message,
    level,
    error,
    properties,
  }: {
    message: string;
    level: LogLevel;
    error?: unknown;
    properties?: Record<string, unknown>;
  }) {
    if (this.hideDetails) {
      console.log(message);
    } else {
      console.log(
        `${this.header()}`,
        ConsoleLogManager.getTimeStamp(),
        level,
        message,
        error,
        {
          ...this.defaultProperties,
          ...(properties ?? {}),
        },
      );
    }
  }

  log(message: string, properties?: Record<string, unknown>): void {
    if (!ConsoleLogManager.shouldLog(this.level, LogLevel.Log)) {
      return;
    }
    this.print({ message, level: LogLevel.Log, properties });
  }

  profile(message: string, properties?: Record<string, unknown>): void {
    if (!ConsoleLogManager.shouldLog(this.level, LogLevel.Profile)) {
      return;
    }
    this.print({ message, level: LogLevel.Profile, properties });
  }

  info(message: string, properties?: Record<string, unknown>): void {
    if (!ConsoleLogManager.shouldLog(this.level, LogLevel.Info)) {
      return;
    }
    this.print({ message, level: LogLevel.Info, properties });
  }

  warn(message: string, properties?: Record<string, unknown>): void {
    if (!ConsoleLogManager.shouldLog(this.level, LogLevel.Warn)) {
      return;
    }
    this.print({ message, level: LogLevel.Warn, properties });
  }

  error(
    message: string,
    error?: unknown,
    properties?: Record<string, unknown>,
  ): void {
    if (!ConsoleLogManager.shouldLog(this.level, LogLevel.Error)) {
      return;
    }
    this.print({ message, level: LogLevel.Error, error, properties });
  }

  counter(message: string, properties?: Record<string, unknown>): void {
    this.print({ message, level: LogLevel.Counter, properties });
  }

  public getSubLogger({ name }: { name: string }): LogManager {
    return new ConsoleLogManager({
      name: `${this.name}|${name}`,
      level: this.level,
      hideDetails: this.hideDetails,
      defaultProperties: this.defaultProperties,
    });
  }
}
