import type { PublicKey } from "@solana/web3.js";

function abbreviate(address: string): string {
  const start = address.slice(0, 6);
  const end = address.slice(-6);
  return `${start}...${end}`;
}

export default function abbreviateSolanaAddress(
  address: string | PublicKey,
): string {
  return typeof address === "string"
    ? abbreviate(address)
    : abbreviate(address.toBase58());
}
