import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import type { WorkerWithInstances } from "@kuzco/models";

import Card from "~/ui/elements/Card";
import WorkerInfo from "./WorkerInfo";

type WorkerCardProps = {
  worker: WorkerWithInstances;
  onClick?: () => void;
  columns?: number;
};

const WorkerCard: React.FC<WorkerCardProps> = ({
  worker,
  onClick,
  columns = 2,
}) => {
  /**
   * This is used for the super admin worker list page,
   * where we don't want to link to the worker details page.
   */
  const linkProps = onClick
    ? { onClick, to: "" }
    : { to: `/dashboard/workers/details/${worker._id}` };

  return (
    <Link {...linkProps}>
      <Card onClick={() => null}>
        <Flex alignItems="center">
          {/* <Image
            alt="Worker Logo"
            src={imageFile?.url ?? '/images/context-mark.svg'}
            width="24"
            height="24"
            style={{
              maxWidth: '24px',
              height: '24px',
              borderRadius: '6px',
            }}
          /> */}
          {/* <Box width="8px" /> */}
          <Text
            maxWidth="220px"
            variant="18-bold"
            // style={{
            //   whiteSpace: 'nowrap',
            //   overflow: 'hidden',
            //   textOverflow: 'ellipsis',
            // }}
          >
            {worker.name}
          </Text>
        </Flex>
        <Box height="16px" />
        <WorkerInfo worker={worker} columns={columns} />
      </Card>
    </Link>
  );
};

export default WorkerCard;
