import { useEffect, useState } from "react";
import { Flex, Text } from "@chakra-ui/react";

import { time } from "@kuzco/utils";

import useIsMobile from "../hooks/useIsMobile.hook";

export default function SystemTime() {
  const isMobile = useIsMobile();
  const [_tick, setTick] = useState(0);
  useEffect(() => {
    const timer = setInterval(() => {
      setTick((tick) => tick + 1);
    }, 1000);
    return () => clearInterval(timer);
  }, []);
  return (
    <Flex alignItems="center">
      <Text variant="12-reg">
        {!isMobile && (
          <Text
            as="span"
            color="gray.400"
            fontWeight="500"
            fontSize="12px"
            fontFamily="monospace"
          >
            System Time:&nbsp;
          </Text>
        )}
        <Text
          as="span"
          color="green.400"
          fontWeight="500"
          fontSize="12px"
          fontFamily="monospace"
        >
          {time.format(time.now(), "F")}
        </Text>
      </Text>
    </Flex>
  );
}
