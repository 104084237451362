import { Avatar, Box, HStack, Text } from "@chakra-ui/react";

type UserProfileProps = {
  name?: string;
  email?: string;
  image?: string;
};

const UserProfile = (props: UserProfileProps) => {
  const { name, image, email } = props;
  return (
    <HStack spacing="3" ps="2">
      <Avatar name={name} src={image} boxSize="10" />
      <Box>
        {name ? (
          <Text color="white" fontWeight="medium" fontSize="sm">
            {name}
          </Text>
        ) : null}
        {email ? (
          <Text color="white" fontSize="sm">
            {email}
          </Text>
        ) : null}
      </Box>
    </HStack>
  );
};

export default UserProfile;
