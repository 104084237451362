import type { BoxProps } from "@chakra-ui/react";
import type { IconBaseProps } from "react-icons/lib";
import React from "react";
import { Box, Spinner, Text, useTheme } from "@chakra-ui/react";

import Card from "~/ui/elements/Card";

type NoContentCardProps = {
  Icon?: React.FC<IconBaseProps>;
  Text?: React.FC;
  Button?: React.FC;
  isLoading?: boolean;
};

const NoContentCard: React.FC<NoContentCardProps & BoxProps> = ({
  Icon: CardIcon,
  Button: CardButton,
  Text: CardText,
  isLoading,
  ...rest
}) => {
  const theme = useTheme();
  return (
    <Card
      width="100%"
      height="600px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...rest}
    >
      {isLoading ? (
        <Spinner size="lg" />
      ) : (
        <Box
          marginTop="-60px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          width="100%"
        >
          {CardIcon ? (
            <CardIcon size="64px" color={theme.colors.gray["700"]} />
          ) : null}
          {CardText ? (
            <Text
              variant="16-reg"
              color="gray.100"
              marginTop="16px"
              maxWidth="500px"
              textAlign="center"
              lineHeight="28px"
            >
              <CardText />
            </Text>
          ) : null}
          <Box height="24px" />
          {CardButton ? <CardButton /> : null}
        </Box>
      )}
    </Card>
  );
};

export default NoContentCard;
