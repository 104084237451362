import type { PointFeature } from "supercluster";
import { startTransition, useRef, useState } from "react";
import { Layer, Map, Marker, Popup, Source } from "react-map-gl";
import Supercluster from "supercluster";

import { MAPBOX_TOKEN } from "~/lib/const";
import { api } from "~/lib/trpc";

import "mapbox-gl/dist/mapbox-gl.css";

type GeoWorker = {
  instanceid: string;
  device: string;
  location: Location;
};

type Location = {
  longitude: number | null | undefined;
  latitude: number | null | undefined;
  [key: string]: unknown; // Add other properties as needed
};

type ClusterType =
  | PointFeature<Supercluster.AnyProps>
  | Supercluster.ClusterFeature<Supercluster.AnyProps>;

// // Function to generate random coordinates
// const getRandomCoordinates = () => ({
//   longitude: Math.random() * 360 - 180,
//   latitude: Math.random() * 180 - 90,
// });

// // Function to generate dummy data
// const generateDummyData = (count: number): GeoWorker[] => {
//   const data: GeoWorker[] = [];
//   for (let i = 0; i < count; i++) {
//     const coordinates = getRandomCoordinates();
//     data.push({
//       instanceid: `instance-${i}`,
//       device: `device-${i}`,
//       location: coordinates,
//     });
//   }
//   return data;
// };

const OnlineInstancesMap = () => {
  const mapContainer = useRef(null);
  const [viewState, setViewState] = useState({
    longitude: -50,
    latitude: 30,
    zoom: 1.5,
  });
  const [selectedLocation, setSelectedLocation] = useState<GeoWorker | null>(
    null,
  );

  const [selectedCluster, setSelectedCluster] = useState<ClusterType | null>(
    null,
  );

  const [data] = api.instance.locations.useSuspenseQuery(undefined, {
    refetchInterval: 10000, // 10 seconds refresh
  });

  // // Generate dummy data
  // const [data, setData] = useState<{ locations: GeoWorker[] }>({
  //   locations: [],
  // });

  // useEffect(() => {
  //   const dummyData = generateDummyData(1000); // Generate 1000 dummy data points
  //   setData({ locations: dummyData });
  // }, []);

  const supercluster = new Supercluster({
    radius: 40,
    maxZoom: 16,
  });

  const points = [] as PointFeature<Supercluster.AnyProps>[];

  if (data.locations && data.locations.length > 0) {
    for (let i = 0; i < data.locations.length; i++) {
      const worker = data.locations[i];
      if (!worker?.location) {
        continue;
      }

      const { longitude, latitude } = worker.location;

      if (
        longitude !== null &&
        longitude !== undefined &&
        latitude !== null &&
        latitude !== undefined
      ) {
        points.push({
          type: "Feature",
          properties: {
            index: i,
            instanceid: worker.instanceid,
            device: worker.device,
          },
          geometry: {
            type: "Point",
            coordinates: [longitude, latitude],
          },
        } as PointFeature<Supercluster.AnyProps>);
      } else {
        console.warn("Invalid location:", worker.location);
      }
    }
  }

  supercluster.load(points);

  const clusters = supercluster.getClusters(
    [-180, -85, 180, 85],
    viewState.zoom,
  );

  const handleMarkerClick = (worker: GeoWorker) => {
    startTransition(() => {
      setSelectedLocation(worker);
      setSelectedCluster(null);
    });
  };

  const handleClusterClick = (cluster: ClusterType) => {
    startTransition(() => {
      setSelectedCluster(cluster);
      setSelectedLocation(null);
    });
  };

  // // Add this state to manage the view mode
  const [viewMode, _] = useState<"markers" | "heatmap">("markers");

  // // Add this function to toggle the view mode
  // const toggleViewMode = () => {
  //   startTransition(() => {
  //     setViewMode((prevMode) =>
  //       prevMode === "markers" ? "heatmap" : "markers",
  //     );
  //   });
  // };

  return (
    <div
      ref={mapContainer}
      style={{
        width: "100%",
        height: "600px",
        border: "1px solid #26272B",
        borderRadius: "8px",
        overflow: "hidden",
        padding: "0px",
      }}
    >
      {/* <div>
        <button onClick={toggleViewMode}>
          Switch to {viewMode === "markers" ? "Heatmap" : "Markers"}
        </button>
      </div> */}

      <Map
        {...viewState}
        onMove={(evt) => startTransition(() => setViewState(evt.viewState))}
        style={{ width: "100%", height: "100%" }}
        mapStyle="mapbox://styles/mapbox/dark-v10"
        mapboxAccessToken={MAPBOX_TOKEN}
        attributionControl={false}
        projection={{ name: "globe" }}
      >
        {viewMode === "markers" ? (
          clusters.map((cluster) => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const { cluster: isCluster, point_count: pointCount } =
              cluster.properties;

            if (isCluster) {
              return (
                <Marker
                  key={`cluster-${cluster.id}`}
                  longitude={longitude ?? 0}
                  latitude={latitude ?? 0}
                >
                  <div
                    className="custom-marker"
                    style={{
                      width: `${30 + Math.min((pointCount / points.length) * 40, 40)}px`,
                      height: `${30 + Math.min((pointCount / points.length) * 40, 40)}px`,
                      backgroundColor: "rgba(55, 161, 105, 0.6)",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "white",
                      fontSize: "12px",
                    }}
                    onClick={() => handleClusterClick(cluster)}
                  >
                    {pointCount}
                  </div>
                </Marker>
              );
            }

            return (
              <Marker
                key={`marker-${cluster.properties.index}`}
                longitude={longitude ?? 0}
                latitude={latitude ?? 0}
                onClick={() =>
                  handleMarkerClick({
                    instanceid: cluster.properties.instanceid,
                    device: cluster.properties.device,
                    location: { longitude, latitude },
                  })
                }
              >
                <div className="custom-marker"></div>
              </Marker>
            );
          })
        ) : (
          <Source
            id="heatmap"
            type="geojson"
            data={{
              type: "FeatureCollection",
              features: points,
            }}
          >
            <Layer
              id="heatmap-layer"
              type="heatmap"
              paint={{
                "heatmap-weight": [
                  "interpolate",
                  ["linear"],
                  ["get", "point_count"],
                  0,
                  0,
                  6,
                  1,
                ],
                "heatmap-intensity": [
                  "interpolate",
                  ["linear"],
                  ["zoom"],
                  0,
                  1,
                  9,
                  3,
                ],
                "heatmap-color": [
                  "interpolate",
                  ["linear"],
                  ["heatmap-density"],
                  0,
                  "rgba(33,102,172,0)",
                  0.2,
                  "rgb(103,169,207)",
                  0.4,
                  "rgb(209,229,240)",
                  0.6,
                  "rgb(253,219,199)",
                  0.8,
                  "rgb(239,138,98)",
                  1,
                  "rgb(178,24,43)",
                ],
                "heatmap-radius": [
                  "interpolate",
                  ["linear"],
                  ["zoom"],
                  0,
                  2,
                  9,
                  20,
                ],
                "heatmap-opacity": [
                  "interpolate",
                  ["linear"],
                  ["zoom"],
                  7,
                  1,
                  9,
                  0,
                ],
              }}
            />
          </Source>
        )}

        {selectedLocation &&
          selectedLocation.location.longitude !== null &&
          selectedLocation.location.latitude !== null && (
            <Popup
              longitude={selectedLocation.location.longitude ?? 0}
              latitude={selectedLocation.location.latitude ?? 0}
              onClose={() => setSelectedLocation(null)}
              closeOnClick={false}
            >
              <div>
                <h2>Instance {selectedLocation.instanceid.slice(0, 4)}..</h2>
                <p>{selectedLocation.device}</p>
              </div>
            </Popup>
          )}

        {selectedCluster && (
          <Popup
            longitude={selectedCluster.geometry.coordinates[0] ?? 0}
            latitude={selectedCluster.geometry.coordinates[1] ?? 0}
            onClose={() => setSelectedCluster(null)}
            closeOnClick={false}
          >
            <div style={{ padding: "10px" }}>
              <h3>
                <strong>{selectedCluster.properties.point_count}</strong>{" "}
                Instances
              </h3>
              <ul>
                {supercluster
                  .getLeaves(selectedCluster.id as number, Infinity)
                  .map((leaf) => (
                    <li key={leaf.properties.index}>
                      {leaf.properties.device}
                    </li>
                  ))}
              </ul>
            </div>
          </Popup>
        )}
      </Map>
    </div>
  );
};

export default OnlineInstancesMap;
