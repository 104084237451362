import { useQuery } from "@tanstack/react-query";

import { trpc } from "../../lib/trpc";

export default function useUserWallets() {
  const { data, isLoading, error, refetch } = useQuery(
    ["userWallets"],
    async () => {
      const response = await trpc.user.getUserWallets.query();
      return response.wallets;
    },
    {
      enabled: true, // This query won't run automatically
    },
  );

  return {
    wallets: data ?? [],
    isLoading,
    error,
    refetchWallets: refetch,
  };
}
