import React, { Suspense } from "react";
import { Box, Flex, Link, Spinner, useTheme } from "@chakra-ui/react";
import { FiTrendingUp } from "react-icons/fi";

import { CAREERS_LINK } from "~/lib/const";
import { EpochOneCard } from "~/ui/components/marketing/EpochOne";
import Footer from "~/ui/components/marketing/Footer";
import MarketingNavigation from "~/ui/components/marketing/MarketingNavigation";
import { Page } from "~/ui/layouts/Main.layout";
import { Content, Header } from "~/ui/layouts/Page.layout";

type MarketingLayoutProps = {
  children?: React.ReactNode;
  showEpoch?: boolean;
};

const MarketingLayout: React.FC<MarketingLayoutProps> = ({
  children,
  showEpoch = true,
}) => {
  const theme = useTheme();

  return (
    <Page>
      <Flex height="100%" width="100%">
        <Box
          position="relative"
          height="100vh"
          width="fill-available"
          overflow="scroll"
          backgroundColor="gray.1100"
        >
          <Suspense>
            <Header
              width={{ base: "100%", md: "1280px" }}
              minHeight="64px"
              height="auto"
            >
              <Flex alignItems="center" justifyContent="center" width="100%">
                <FiTrendingUp color={theme.colors.green[500]} fontSize="20px" />
                <Box width="8px" />
                <Box>
                  We&apos;re hiring engineers!&nbsp;
                  <Link
                    href={CAREERS_LINK}
                    style={{ textDecoration: "underline", display: "inline" }}
                  >
                    Join us.
                  </Link>
                </Box>
                {/* <Box display={{ base: "block", md: "none" }}>
                  High Volume. Follow for updates.
                </Box> */}
              </Flex>
            </Header>
            <MarketingNavigation />
            <Content
              suspenseFallback={
                <Flex
                  height="600px"
                  width="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Spinner />
                </Flex>
              }
              width={{ sm: "100%", md: "1000px" }}
              padding="0px"
            >
              <Box height="32px" />
              {showEpoch ? (
                <>
                  <EpochOneCard />
                  <Box height="48px" />
                </>
              ) : null}
              {children}
              <Box height="200px" />
            </Content>
            <Footer />
          </Suspense>
        </Box>
      </Flex>
    </Page>
  );
};

export default MarketingLayout;
