import React, { useCallback } from "react";
import { Box, Button, Divider, Flex, Text, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import useModal from "~/ui/hooks/useModal.hook";
import useWorker from "~/ui/hooks/useWorker.hook";
import { ConfirmModalTypes } from "../../modal/Confirm.modal";
import { ModalTypes } from "../../modal/Modal";
import SaveWorkerMenu from "../../worker/create/SaveWorkerMenu";
import WorkerName from "./WorkerName";

type EditWorkerProps = {
  workerId?: string;
};

const EditWorker: React.FC<EditWorkerProps> = ({ workerId }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const { push, pop } = useModal();
  const { updateWorkerParams, setUpdateWorkerParams, archiveWorker } =
    useWorker(workerId);
  const [errors] = React.useState<Record<string, object>>({});

  const archive = useCallback(async () => {
    try {
      await archiveWorker.mutateAsync({
        workerId: workerId ?? "",
      });

      navigate(`/dashboard/workers`);
    } catch (e) {
      console.error(e);
      toast({
        title: "Error Archiving Worker",
        description:
          "Something went wrong. Please try again later or contact support.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [archiveWorker, workerId, navigate, toast]);

  /** Render */
  return (
    <Box display="flex" paddingTop="24px" justifyContent="center">
      <Box width="600px">
        <WorkerName
          params={updateWorkerParams}
          setParams={setUpdateWorkerParams}
          errors={errors}
        />
        <Flex height="24px" />
        <SaveWorkerMenu workerId={workerId} />

        <Box margin="32px 0">
          <Divider height="1px" backgroundColor="gray.800" />
        </Box>
        <Text variant="18-reg" color="white">
          Archive Worker
        </Text>
        <Box height="8px" />
        <Text variant="14-reg" color="gray.400">
          If you wish to archive this worker, you can do so by clicking the
          button below. This action is not reversible.{" "}
          <b>
            Your points will not be affected and will still count towards your
            total.
          </b>{" "}
          This is mostly for cleaning up your dashboard.
        </Text>
        <Box height="16px" />
        <Button
          isLoading={archiveWorker.isLoading}
          variant="error"
          size="sm"
          width="fit-content"
          onClick={() => {
            push({
              type: ModalTypes.Confirm,
              props: {
                title: `Archive Worker`,
                type: ConfirmModalTypes.Warning,
                message: `You are about to archive this worker. Are you sure you want to continue?`,
                confirm: async () => {
                  await archive();
                  pop();
                },
                cancel: () => {
                  pop();
                },
              },
            });
          }}
        >
          Archive this worker
        </Button>

        {/* <Box margin="32px 0">
          <Divider height="1px" backgroundColor="gray.800" />
        </Box>
        <Text variant="18-reg" color="white">
          Deregister Worker
        </Text>
        <Box height="8px" />
        <Text variant="14-reg" color="gray.400">
          Deregistering will remove it from the worker pool. Returning the
          worker to the pool requires calling <code>kuzco worker register</code>{" "}
          using the CLI, or by re-registering the worker in the Console.
        </Text>
        <Box height="16px" />
        <Tooltip
          label={
            canDeregister
              ? null
              : "The worker must be in the 'Stopped' state in order to be deregistered"
          }
        >
          <Button
            isLoading={archiveWorker.isLoading}
            variant="error"
            size="sm"
            width="fit-content"
            isDisabled={!canDeregister || archiveWorker.isLoading}
            onClick={() => {
              push({
                type: ModalTypes.Confirm,
                props: {
                  title: `Deregister Worker`,
                  type: ConfirmModalTypes.Warning,
                  message: `You are about to deregister this worker. Are you sure you want to continue?`,
                  confirm: async () => {
                    await deregister();
                    pop();
                  },
                  cancel: () => {
                    pop();
                  },
                },
              });
            }}
          >
            Deregister
          </Button>
        </Tooltip> */}
        <Box height="300px" />
      </Box>
    </Box>
  );
};

export default EditWorker;
