import { SearchIcon } from "@chakra-ui/icons";
import { Box, InputGroup, InputLeftElement, Text } from "@chakra-ui/react";

import SuperUsersTable from "~/ui/components/super/SuperUsersTable";
import SearchInput from "~/ui/elements/SearchInput";
import useSuper from "~/ui/hooks/useSuper.hook";

const SuperUsersPage = () => {
  const { superListUsers, superListUsersQuery, setSuperListUsersQuery } =
    useSuper();

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="24px"
        padding="24px 24px 0"
      >
        <Box>
          <Text variant="24-reg" color="white">
            Users ({superListUsers.data?.pages[0]?.total ?? 0})
          </Text>
        </Box>

        <Box>
          <InputGroup>
            <InputLeftElement pointerEvents="none" height="36px">
              <SearchIcon color="gray.500" />
            </InputLeftElement>
            <SearchInput
              height="36px"
              borderRadius="6px"
              type="text"
              value={superListUsersQuery}
              placeholder="Search..."
              onChange={(e) => setSuperListUsersQuery(e.currentTarget.value)}
              size="md"
              width="300px"
              raw
            />
          </InputGroup>
        </Box>
      </Box>

      <Box padding="0px 24px">
        <SuperUsersTable />
      </Box>
    </>
  );
};

export default SuperUsersPage;
