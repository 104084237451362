import { Box, Flex, Skeleton } from "@chakra-ui/react";

import { arrays } from "@kuzco/utils";

import useIsMobile from "../hooks/useIsMobile.hook";

export default function SideNavigationSuspense() {
  const isMobile = useIsMobile();
  return (
    <Flex
      as="section"
      minH="100vh"
      bg="gray.1100"
      borderRight="1px solid"
      borderColor="gray.800"
      width={isMobile ? "50px" : "240px"}
      flexShrink={0}
      padding={isMobile ? "5px" : "16px"}
      transition="width 0.3s"
    >
      <Flex flex="1" direction="column" justifyContent="space-between">
        <Box width="fill-available" flex="1" position="relative">
          <Skeleton height="42px" width="fill-available" borderRadius="lg" />
          <Box height="24px" />
          <Box flex="1">
            {arrays.range(5).map((i) => {
              return (
                <Skeleton
                  marginTop="8px"
                  key={i}
                  height="32px"
                  width="80%"
                  borderRadius="lg"
                />
              );
            })}
            <Box height="32px" />
            {arrays.range(2).map((i) => {
              return (
                <Skeleton
                  marginTop="8px"
                  key={i}
                  height="32px"
                  width="80%"
                  borderRadius="lg"
                />
              );
            })}
            <Box
              bottom="0px"
              width="fill-available"
              position="absolute"
              padding="10px"
            >
              {arrays.range(2).map((i) => {
                return (
                  <Skeleton
                    marginTop="8px"
                    key={i}
                    height="28px"
                    width="80%"
                    borderRadius="lg"
                  />
                );
              })}
            </Box>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
}
