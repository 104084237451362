const emails = [
  "sam@kuzco.xyz",
  "abe@kuzco.xyz",
  "carsen@kuzco.xyz",
  "sean@kuzco.xyz",
];

export const isSuperUser = (email: string | null | undefined): boolean => {
  return email ? emails.includes(email) : false;
};
