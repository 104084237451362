import z from "zod";

import { serviceRequest, serviceResponse } from "..";

export type EmailService = {
  sendResetPasswordEmail(
    request: SendResetPasswordEmailRequest,
  ): Promise<SendResetPasswordEmailResponse>;
  sendEmailVerificationEmail(
    request: SendEmailVerificationEmailRequest,
  ): Promise<SendEmailVerificationEmailResponse>;
};

/** ******************************************************************************
 *  Send Reset Password Email
 ******************************************************************************* */

export const sendResetPasswordEmailParams = z.object({
  toAddress: z.string(),
  resetToken: z.string(),
});

export const sendResetPasswordEmailRequest = serviceRequest.merge(
  z.object({
    params: sendResetPasswordEmailParams,
  }),
);

export const sendResetPasswordEmailResponse = serviceResponse.merge(
  z.object({
    success: z.boolean().optional(),
  }),
);

export type SendResetPasswordEmailParams = z.infer<
  typeof sendResetPasswordEmailParams
>;
export type SendResetPasswordEmailRequest = z.infer<
  typeof sendResetPasswordEmailRequest
>;
export type SendResetPasswordEmailResponse = z.infer<
  typeof sendResetPasswordEmailResponse
>;

/** ******************************************************************************
 *  Send Email Verification Email
 ******************************************************************************* */

export const sendEmailVerificationEmailParams = z.object({
  toAddress: z.string(),
  userId: z.string(),
  verificationToken: z.string(),
});

export const sendEmailVerificationEmailRequest = serviceRequest.merge(
  z.object({
    params: sendEmailVerificationEmailParams,
  }),
);

export const sendEmailVerificationEmailResponse = serviceResponse.merge(
  z.object({
    success: z.boolean().optional(),
  }),
);

export type SendEmailVerificationEmailParams = z.infer<
  typeof sendEmailVerificationEmailParams
>;
export type SendEmailVerificationEmailRequest = z.infer<
  typeof sendEmailVerificationEmailRequest
>;
export type SendEmailVerificationEmailResponse = z.infer<
  typeof sendEmailVerificationEmailResponse
>;
